import {Access} from '@nextpage/np-sdk-data';
import {GraphicImageViewComponent} from '../../../cogedim-spe/graphic-image-view/graphic-image-view.component';

export class CogedimComponentResolver {
    private static _mapComponents = {
      'GraphicImageView': {
        [Access.ALL]: GraphicImageViewComponent
      },
    };

    public static getComponentRouting(componentName: string) {
        return CogedimComponentResolver._mapComponents[componentName];
    }
}
