import {NPElement} from './np-element';


export abstract class NPCaracValeur {
    ElementID: number;
    /**
     * L'élément ExtID sera souvent vide et non envoyé par le serveur
     * Il permet de faire de la création de valeurs sur d'autres objets sans connaître leur ID
     */
    ElementExtID: string;
    DicoCaracExtID: string;
    DicoCaracID: number;
    Element?: NPElement;
    LangID?: number;
    ContextID?: number;
    TypeValue: NPCaracValeurTypeValue;
    Access: NiveauAccesDonnee;
    StatusExtID?: any;
    Value?: any;

    public constructor(param) {
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('ElementID')) {
            this.ElementID = param.ElementID;
        }
        if (param.hasOwnProperty('ElementExtID')) {
            this.ElementExtID = param.ElementExtID;
        }
        if (param.hasOwnProperty('DicoCaracID')) {
          this.DicoCaracExtID = param.DicoCaracID;
        }
        if (param.hasOwnProperty('DicoCaracExtID')) {
            this.DicoCaracExtID = param.DicoCaracExtID;
        }
        if (param.hasOwnProperty('Element')) {
            this.Element = param.Element;
        }
        if (param.hasOwnProperty('LangID')) {
            this.LangID = param.LangID;
        }
        if (param.hasOwnProperty('ContextID')) {
            this.ContextID = param.ContextID;
        }
        if (param.hasOwnProperty('TypeValue')) {
            this.TypeValue = param.TypeValue;
        }
        if (param.hasOwnProperty('Access')) {
            this.Access = param.Access;
        }
        if (param.hasOwnProperty('StatusExtID')) {
            this.StatusExtID = param.StatusExtID;
        }
    }

    public static instanciate(param: NPCaracValeur): NPCaracValeur {

        if (param == null || param.TypeValue == null) {
            return null;
        }
        // note : si on rajoute autre chose, il faut penser à l'ajouter dans la vérification des liens
        switch (param.TypeValue) {
            case NPCaracValeurTypeValue.DTOSystem:
                return new NpCaracStatus(param);
            case NPCaracValeurTypeValue.DTOSystemCharTemplate:
                return new NPCaracCharTemplate(param);
            case NPCaracValeurTypeValue.DTOSystemStatus:
                return new NpCaracStatus(param);
            case NPCaracValeurTypeValue.Lien:
                return new NPCaracLien(param);
            case NPCaracValeurTypeValue.Multivalo:
                return new NPCaracListe(param);
            case NPCaracValeurTypeValue.Standard:
                return new NPCaracStd(param);
            case NPCaracValeurTypeValue.DTOSystemSearchRanking:
                return new NPCaracSearchRankLevel(param);
            default:
                return param;
        }

    }
}

export enum NPCaracValeurTypeValue {
    Standard = 0,
    Lien = 1,
    Multivalo = 2,
    DTOSystem = 100,
    DTOSystemStatus = 101,
    DTOSystemCharTemplate = 102,
    DTOSystemSearchRanking = 103,
}

export enum NiveauAccesDonnee {
    Herite = -1,
    Gestionnaire = 0,
    Redacteur = 1,
    LectureSeule = 2,
    AucunAccess = 3
}

export class NPCaracStd extends NPCaracValeur {
    Value: string = '';

    constructor(param = null) {
        super(param);
        if (param != null && param.hasOwnProperty('Value')) {
            this.Value = param.Value;
        }
        this.TypeValue = NPCaracValeurTypeValue.Standard;
    }
}

export class NPCaracLien extends NPCaracValeur {
    LinkedElements: NPCaracLienValue[];
    /**
     * Liens recréés lors de la reconstruction des données
     */
    RebuildLinkedElements: NPCaracLienRebuildValue[];

    constructor(param = null) {
        super(param);
        this.TypeValue = NPCaracValeurTypeValue.Lien;
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('LinkedElements')
            && param.LinkedElements != null) {
            this.LinkedElements = [];
            param.LinkedElements.forEach(d => {
                this.LinkedElements.push(new NPCaracLienValue(d));
            });
        }
        if (param.hasOwnProperty('RebuildLinkedElements')) {
            // je pense que ça suffit
            this.RebuildLinkedElements = param.RebuildLinkedElements;
        }
    }
}

export class NPCaracListe extends NPCaracValeur {
    Values: NPCaracListeValue[];

    constructor(param = null) {
        super(param);
        this.TypeValue = NPCaracValeurTypeValue.Multivalo;
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty(('Values'))) {
            this.Values = param.Values;
        }
    }
}

export class NPCaracListeValue {
    Order: number;
    ValueID: number;
}

export class NPCaracLienValue {


    Order: number;
    ElementID: number;
    //RebuildElement: NPElement;
    ElementExtID: string; // Attention, renseigné uniquement en cas de création et notamment de création de données structurées

    constructor(param = null) {
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('Order')) {
            this.Order = param.Order;
        }
        if (param.hasOwnProperty('ElementID')) {
            this.ElementID = param.ElementID;
        }
        // if (param.hasOwnProperty('RebuildElement')) {
        //     this.RebuildElement = new NPElement(param.RebuildElement);
        // }
        if (param.hasOwnProperty('ElementExtID')) {
            this.ElementExtID = param.ElementExtID;
        }

    }

}

export class NPCaracLienRebuildValue {
    Order: number;
    Element: NPElement;
}


export class NpCaracStatus extends NPCaracValeur {
    StatusID: number;
    StatusExtID: string;

    constructor(param = null) {
        super(param);
        this.TypeValue = NPCaracValeurTypeValue.DTOSystemStatus;
        if (param == null) {
            return;
        }

        if (param.hasOwnProperty('StatusExtID')) {
            this.StatusExtID = param.StatusExtID;
        }
        if (param.hasOwnProperty('StatusID')) {
            this.StatusID = param.StatusID;
        }
    }
}

export class NPCaracCharTemplate extends NPCaracValeur {
    CharTemplates: NPCaracCharTemplateValue[];

    constructor(param = null) {
        super(param);
        this.TypeValue = NPCaracValeurTypeValue.DTOSystemCharTemplate;
        this.CharTemplates = new Array<NPCaracCharTemplateValue>();

        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('CharTemplates')) {
            this.CharTemplates = param.CharTemplates;
        }
    }
}

export class NPCaracCharTemplateValue {
    CharTemplateID: number;
    CharTemplateExtID: string;
}

export class NPCaracSearchRankLevel extends NPCaracValeur {
    static NPCaracSearchRankLevelExtID = 'DTOSystemSearchRanking';
    public SearchRanking: number;

    constructor(param = null) {
        super(param);
        this.TypeValue = NPCaracValeurTypeValue.DTOSystemSearchRanking;
        this.DicoCaracExtID = NPCaracSearchRankLevel.NPCaracSearchRankLevelExtID;
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('SearchRanking')) {
            this.SearchRanking = param['SearchRanking'];
        }

    }
}

export class NPCaracDate extends NPCaracValeur {
    public LastModifiedDate: string;
}
