import {Injectable} from '@angular/core';
import {NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {BehaviorSubject} from 'rxjs';
import {NPUserRepository} from './auth/user-respository';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';

@Injectable({
    providedIn: 'root'
})
export class OperationService {

    private _currentOperation: NPElement;
    private _subCurrentOperation = new BehaviorSubject<NPElement>(null);
    private _regionalDir: NPListeValues[] = [];
    private _regionalDirProducts: NPListeValues[] = [];

    constructor(private _userRepository: NPUserRepository) {
    }

    getCurrentOperation() {
        return this._subCurrentOperation.asObservable();
    }

    setCurrentOperation(operation: NPElement) {
        this._currentOperation = operation;
        this._subCurrentOperation.next(this._currentOperation);
    }

    currentOperation() {
        return this._currentOperation;
    }

    setRegionalDir(values: NPListeValues[]) {
        this._regionalDir = values;
    }

  setRegionalDirProducts(values: NPListeValues[]) {
    this._regionalDirProducts = values;
  }

  hasRegionalDirProducts(kit: NPElement) {
    const _regionalDirProducts = kit.getValueListe(CogedimDicocarcs.DC_DIRECTION_REGIONALE_PRODUITS).Values;
    const _tmpRegionalDirProducts = _regionalDirProducts.map(currentValue => this._regionalDirProducts.find(value => value.ValeurID === currentValue.ValueID));
    return _tmpRegionalDirProducts
      .some(value => this.regionalDirEquals(this._currentOperation, CogedimDicocarcs.DC_DIRECTION_REGIONALE_OPERATION, value.Valeur));
  }

    hasRegionalDir(id: number, userRegionalDirName: string) {
        const tmpValue = this._regionalDir.find(value => value.ValeurID === id);
        return tmpValue && tmpValue.Valeur === userRegionalDirName;
    }

    public regionalDirEquals(element: NPElement, dicoCaracExtId: string, valueToCompare: string) {
        if (valueToCompare && valueToCompare !== '') {
            const tmpValue = element.getValueListe(dicoCaracExtId);
            const tmpValueId = tmpValue && tmpValue.Values.length > 0 ? tmpValue.Values[0].ValueID : undefined;
            return this.hasRegionalDir(tmpValueId, valueToCompare);
        }
        return false;
    }

    public getOperationRegionalDirections(element: NPElement): NPListeValues[] {
        if (element) {
            const productRegionalDirection = element.getValueListe(CogedimDicocarcs.DC_DIRECTION_REGIONALE_OPERATION);
            return productRegionalDirection ? productRegionalDirection.Values
                .map(productRegionalDirectionItem => this._regionalDir.find(c => c.ValeurID === productRegionalDirectionItem.ValueID)) : [];
        }
        return [];
    }

    getCollectionByExtId(_currentOperation: NPElement, _collectionExtId: any): NPElement {
        if (_currentOperation && _currentOperation) {
            const _elementLiked = _currentOperation.getValueLien(CogedimDicocarcs.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION);
            if (_elementLiked && _elementLiked.RebuildLinkedElements) {
                const _collectionValueLink = _elementLiked.RebuildLinkedElements.find(x => x.Element.ExtID === _collectionExtId);
                return _collectionValueLink && _collectionValueLink.Element ? _collectionValueLink.Element : null;
            }
        }
        return null;
    }
}
