import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-dialog-critical',
  templateUrl: './dialog-warning-ask.component.html',
  styleUrls: ['./dialog-warning-ask.component.scss']
})
export class DialogWarningAskComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogWarningAskComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
