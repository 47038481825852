import {Injectable} from '@angular/core';
import {Constantes} from '@nextpage/np-sdk-data';

@Injectable({
  providedIn: 'root'
})

export class ComponentGraphicsStyleConfig {

  public jssLoaded = [];

  constructor(private _constantes: Constantes) {
  }

  // [ngClass]="classes && classes['title'] ? classes['title']: ''"
  getCssOveride(component: string) {

    const primaryColor = this._constantes.primaryColor;

    const componentGraphicsStyleConfig: Object = {
      'NPValueViewComponent': {
        '@global': {
          'label': {
            'display': 'block',
            'width': '100%',
            'margin-bottom': '10px',
            'font-weight': 300,
            'font-size': '18px'
          }
          // '@media (min-width: 992px) and (max-width: 1199px)': {
          //     '#families-page h1 .value': {
          //         'font-size': '21px !important'
          //     }
          // }
        }
      },
      'NpValueTextWriteViewComponent': {
        '@global': {
          '.input': {
            'display': 'block !important',
            'margin-left': '0 !important'
          },
          '.prestation-label .input': {
            'width': '100%',
            'display': 'block',
            'margin': 0
          },
          'input, textarea': {
            'border': '1px solid' + primaryColor
          },
          '.prestation-nb label': {
            'display': 'inline-block',
            'width': 'auto',
            'vertical-align': 'middle',
            'margin': '0 5px 0 0',
            'font-size': '16px'
          },
          '.prestation-nb .input': {
            'display': 'inline-block !important',
            'margin': '0 !important',
            'width': 'auto !important'
          },
          '.prestation-nb input': {
            'width': '40px !important',
            'height': '30px !important'
          }
        }
      },
      'NpValueTextReadViewComponent': {
        '@global': {
          '.value': {
            'margin-bottom': '0 !important'
          },
          'input, textarea': {
            'border': '1px solid' + primaryColor
          }
        }
      },
      'VisualMediaComponent': {
        '@global': {
          '.photo-add-wrapper': {
            'border-color': primaryColor + '!important'
          }
        }
      },
      'NpMediasSelectionComponent': {
        '@global': {
          '.dropzone, .search-results': {
            'border-color': primaryColor + '!important'
          }
        }
      },
      'NpComboboxComponent': {
        '@global': {
          '.combobox-block select': {
            'width': 'auto !important',
            'height': '50px',
            'padding-left': '5px',
            'padding-right': '40px',
            'border-color': primaryColor + '!important',
            'background': 'url(assets/imgs/fleche-select.png) no-repeat right 10px center, #fff',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none'
          }
        }
      },
      'NpNumberComponent': {
        '@global': {
          '.prestation-nb label': {
            'display': 'inline-block',
            'width': 'auto',
            'vertical-align': 'middle',
            'margin': '0 5px 0 0',
            'font-size': '16px'
          },
          '.prestation-nb .input': {
            'display': 'inline-block !important',
            'margin': '0 !important',
            'width': 'auto !important'
          },
          '.prestation-nb input': {
            'width': '40px !important',
            'height': '30px !important'
          }
        }
      },
      'NpDatepickerComponent': {
        '@global': {
          '.date-value .input-date': {
            'display': 'block',
            'width': '100%',
            'margin': 0
          },
          '.date-value input': {
            'width': '100% !important',
            'color': '#999',
            'border': 'none !important'
          }
        }
      },
      'ImageOverlayComponent': {
        '@global' : {
          '.mat-dialog-container' : {
            'padding' : '0 !important',
            'overflow' : 'hidden !important'
          }
        }
      }
    };
    return componentGraphicsStyleConfig[component];
  }

  getComponentConfigStyle(componentname: string) {
    let override_css = '';
    if (!this.jssLoaded.find((cc) => cc === componentname)) {
      override_css = this.getCssOveride(componentname);
      this.jssLoaded.push(componentname);
    }
    return override_css;
  }
}
