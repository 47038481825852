import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CatalogueSummary} from '../Model/models';
import {of, throwError} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class GenCatalogueService {

    private _baseUrl = '/api/sdk/Catalogue/generate';

    constructor(private _http: HttpClient) {
    }

    genCatalogue(object: CatalogueSummary) {
        return this._http.post(this._baseUrl, object)
          .pipe(
            flatMap((response: any[]) => {
              return response.length > 0 ? throwError(response[0]) : of(true);
            })
          );
    }
}
