import {saveAs} from 'file-saver';

export class DownloadHelper {

    public static saveFileAsPdf(data: any, outputFileNameWithExtension: string) {
        const file = DownloadHelper.bytesToFile(data, 'application/pdf', outputFileNameWithExtension);
        if (file) {
            saveAs(file);
        } else {
            console.error('Error while saving file.');
        }
    }

    public static bytesToFile(bytes: Blob, fileType: string, fileName = 'unknown file name') {
        let result = null;
        if (bytes && fileType) {
            try {
                result = new File([bytes], fileName, {type: fileType});
            } catch (e) {
                // when File constructor is not supported
                result = new Blob([bytes], {type: fileType});
                result.name = fileName;
            }
        }
        return result;
    }
}
