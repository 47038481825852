import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {COGIDIM_ROUTES} from './sdk/data/constantes/routes';

const routes: Routes = [
    {
        path: '',
        redirectTo: COGIDIM_ROUTES.Login.route,
        pathMatch: 'full'
    },
    {
        path: COGIDIM_ROUTES.Login.route,
        loadChildren: './views/authentication-routing/authentication-routing.module#AuthenticationRoutingModule'
    },
    {
        path: COGIDIM_ROUTES.MainPage.route,
        loadChildren: './views/main-page-routing/main-page-routing.module#MainPageRoutingModule'
    },
    {
        path: COGIDIM_ROUTES.Operation.route,
        loadChildren: './views/operation-routing/operation-routing.module#OperationRoutingModule'
    },
    {
      path: COGIDIM_ROUTES.Edition.route,
      loadChildren: './views/edition-routing/edition-routing.module#EditionRoutingModule'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            onSameUrlNavigation: 'reload'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
