import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UploadNotice} from '../../sdk/data/Model/models';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {UploadNoticeService} from '../../sdk/data/services/upload-notice.service';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';

@Component({
    selector: 'app-existing-notice-item',
    templateUrl: './existing-notices-preview.component.html',
    styleUrls: ['./existing-notices-preview.component.scss']
})
export class ExistingNoticesPreviewComponent implements OnInit, OnChanges {

    @Input() existingNotices: UploadNotice[] = [];

    constructor(private _collectionService: CollectionService,
                private _modalService: ModalService,
                private _noticeService: UploadNoticeService) {
    }

    ngOnInit() {
    }

    ngOnChanges(): void {

    }

    getDate(noticeElement: UploadNotice) {
        return noticeElement
        && noticeElement.element
            ? noticeElement.element.getValueTextValue(CogedimDicocarcs.DC_DATE_UPLOAD_NOTICE) : 'Date inconnue';
    }

    downloadNotice(existingNotice: UploadNotice) {
        this._noticeService.downloadFileFromServer(existingNotice.element, CogedimDicocarcs.NOTICE_MEDIA_FILE, '')
            .subscribe(response => {
                if (response && response.Success && response.Messages) {
                    this._modalService.openSnackBar(response.Messages, CogedimConstantes.SNACK_CLASS);
                }
            }, err => {
                if (err.Errors && err.Errors.length > 0) {
                    this._modalService.openSnackBar(err.Errors[0], CogedimConstantes.SNACK_CLASS);
                }
            });
    }

}
