import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ImageObject} from '../../../data/Model/models';

@Component({
    selector: 'app-image-overlay',
    templateUrl: './image-overlay.component.html',
    styleUrls: ['./image-overlay.component.css']
})
export class ImageOverlayComponent implements OnInit {

    public photo: ImageObject;
    public legend: string;
    public fullImageUrl = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.photo = this.data && this.data.image ? this.data.image : undefined;
        this.legend = this.data && this.data.legend ? this.data.legend : '';
        this.fullImageUrl = this.photo.originalSrc;
    }

}
