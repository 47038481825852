import {DataMocker} from './data-mocker';
import {Observable} from 'rxjs/Observable';
import {HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Constantes} from '../../../const/constantes';
import {NpApiResult} from '../../../model';


@Injectable({providedIn: 'root'})
/**
 * Ce dataMocker a pour objectif de rediriger les requetes envoyées vers "sdk" vers un sdk-debug en ajoutant le token
 */
export class DataMockerSdkdebugBiocoop extends DataMocker {


    constructor(private _constantes: Constantes) {
      super();
      this._baseUrl = _constantes.baseUrl;
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiToken': this._constantes.token
      });
      this.fileHeaders = new HttpHeaders({
        'ApiToken': this._constantes.token,
      });
    }


  protected getToken(): string {
    return this._constantes.token;
  }
    /**
     * Url de base du site web=> à modifier en fonction des besoins du développeur
     * ATTENTION, il doit y avoir un / à la fin
     */
    private _baseUrl = 'https://projet-biocoop.nextpage.fr/';

    /**
     * Header contenant le tocken =>à modifier en fonction des besoins du développeur
     */
    private readonly headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiToken': 'fakeTokenForDebug-user:tmontanier-mdp:ailier',
    });

    private readonly fileHeaders = new HttpHeaders({
        'ApiToken': 'fakeTokenForDebug-user:tmontanier-mdp:ailier',
    });

    /**
     * Redirige les requetes vers les urls de debug
     * Pour utiliser cette classe, il faut spécifier l'url du projet (racine) ainsi que le tocken à envoyer
     */
    mokeResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.startsWith('/filehandler') && request.url.indexOf('BiocoopScreen') > -1) {
            let newRequest: HttpRequest<any>;
            newRequest = request.clone({
                url: './assets/BiocoopScreen.json'
            });
            return next.handle(newRequest);
        }
        if (request.url.startsWith('/filehandler') && request.url.indexOf('ConfigSearch') > -1) {
            let newRequest: HttpRequest<any>;
            newRequest = request.clone({
                url: './assets/ConfigSearch.json'
            });
            return next.handle(newRequest);
        }
        if (request.url.indexOf('uitranslation') > -1) {
            let newRequest: HttpRequest<any>;
            newRequest = request.clone({
                url: './assets/UiTranslations.json'
            });
            return next.handle(newRequest);
        }
        if (request.url.indexOf('filehandler.ashx') > -1) {
          let newRequest: HttpRequest<any>;
          newRequest = request.clone({
            url: (this._baseUrl + request.url).replace('//', '/')
              .replace('/filehandler', '/filehandlerapi') + '&authtoken=' + this.getToken(),
          });
          return next.handle(newRequest);
        }
        // si la requête de base commence par "api/"
        if (request.url.startsWith('/api/')) {
            let url = request.url;
            if (request.url.indexOf('sdk/') > -1) {
                url = url.replace('/sdk/', '/sdk-debug/');
            }
            // on crée une nouvelle requete en changeant les options et en ajoutant la base url
            let newRequest: HttpRequest<any>;
            if (request.url.indexOf('UpdateMedia') > -1) {
                newRequest = request.clone({
                    url: (this._baseUrl + url).replace('//api', '/api'),
                    headers: this.fileHeaders
                });
            } else {
                newRequest = request.clone({
                    url: (this._baseUrl + url).replace('//api', '/api'),
                    headers: this.headers
                });
            }

            return next.handle(newRequest);
        } else {
            return next.handle(request);
        }

    }

    matchUrl(request: HttpRequest<any>): boolean {
        return true;
    }

    getData(): NpApiResult {
        return null;
    }

    getUrlPattern(): string {
        return '';
    }

}
