import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {COGIDIM_ROUTES} from '../../sdk/data/constantes/routes';
import {Router} from '@angular/router';
import {CollectionSummary} from '../../sdk/data/Model/models';
import {CogedimConstantes, Messages} from '../../sdk/data/constantes/cogedim-constantes';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {filter, flatMap} from 'rxjs/operators';
import {DialogWarningComponent} from '../../sdk/graphics/components/dialog-warning/dialog-warning.component';
import {MatDialog} from '@angular/material';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {of} from 'rxjs';
import {CogedimBlockHelper} from '../../sdk/data/helpers/cogedim-block-helper';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {ErrorService} from '../../sdk/data/services/error.service';
import {NavBarService} from '../../sdk/data/services/nav-bar.service';

@Component({
    selector: 'app-prestation-table-view',
    templateUrl: './prestation-table-view.component.html',
    styleUrls: ['./prestation-table-view.component.scss']
})
export class PrestationTableViewComponent implements OnInit {

    @Input() config: CaracConfig;
    @Input() element: NPElement;

    public collections: NPElement[] = [];
    public collectionSummary = () => new CollectionSummary(this.config ? this.config.ConfigurationObject : []);
    public tableFields = () => this.getTableFields();

    constructor(private _collectionService: CollectionService, private _route: Router,
                private _elementWriter: CogedimElementWriterService,
                private _caracConfigRepository: CaracConfigRepositoryService,
                private _dialog: MatDialog,
                private _modalService: ModalService,
                private _errorService: ErrorService,
                private _navBarService: NavBarService) {
    }

    ngOnInit() {

        this._updateCollections();

        this._collectionService.getCollections()
            .subscribe(data => {
                this.collections = data;
                // console.log('this.collections ::: ', this.collections);
                this._initErrorsData();
            });
        this._initErrorsData();
    }

    /**
     * On met à jour la liste des caracs qui seront vérifiées (Champs obligatoires ou données incorrectes) avant l'enregistrement
     */
    private _initErrorsData() {
        if (this.config && this.config.ConfigurationObject && this.collections && this.collections.length > 0) {
            this.collections.forEach(collection => {
                this._errorService.addCaracs(this.config.ConfigurationObject, collection, {name: 'Prestation'});
            });

        }
    }

    delete(selectedCollection: NPElement) {
        this._openDialog(CogedimConstantes.DELETE_MODE, Messages.DELETE_COLLECTION)
            .afterClosed()
            .pipe(
                filter(response => response),
                flatMap(() => {
                    this._elementWriter.removeValueFromLinks(this.element, CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION, selectedCollection, true);
                    this._updateCollections(); // On met à jour la liste des colections
                    return of(true);
                })
            )
            .subscribe(() => {
            });
    }

    private _updateCollections() {
        const tmpCollection = this.element.getValueLien(CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION);
        this.collections = tmpCollection && tmpCollection.RebuildLinkedElements ? tmpCollection.RebuildLinkedElements.map(rElement => rElement.Element) : [];
    }

    hasCollections(currCollection: NPElement, collectionID: number) {
        return currCollection ? currCollection.getValueListe(CogedimConstantes.DC_NOM_COLLECTION_CHOISIE_COLLECTION).Values.some(value => {
            return value.ValueID === collectionID;
        }) : undefined;
    }

    getCollectionID(currCollection: NPElement) {
        return currCollection.getValueListe(CogedimConstantes.DC_NOM_COLLECTION_CHOISIE_COLLECTION).Values[0].ValueID;
    }

    private _hasConfig() {
        return this.config && this.config.ConfigurationObject;
    }

    /**
     * Liste des champs du tableau (DC_TB_1.....N)
     */
    getTableFields() {
        return this._hasConfig() ?
            this.config.ConfigurationObject.filter(cc => cc.DicoCaracExtID.toUpperCase().startsWith(CogedimConstantes.DC_NB_T)) : [];
    }

    getCollectionName(prestation: NPElement) {
        const tmpValueId = prestation.getValueListe(CogedimConstantes.DC_NOM_COLLECTION_CHOISIE_COLLECTION);
        return tmpValueId && tmpValueId.Values[0] ? this._collectionService.getCollectionTypeByID(Number(tmpValueId.Values[0].ValueID)).Valeur : '';
    }

    /**
     * Enregistrement des modificartions apportées à l'opération en cours, s'il y en a aumoins une
     * Puis charge la page "Edition"
     */
    gotoPrestations(collection: NPElement) {
        if (this._elementWriter.hasModifications()) {
            this._save(collection);
        } else {
            this._gotoPrestations(collection);
        }
    }

    private _gotoPrestations(collection: NPElement) {
        this._route.navigate([`${COGIDIM_ROUTES.Edition.route}`], {
            queryParams: {prestationExtID: collection.ExtID, collection: this.getCollectionID(collection)},
            queryParamsHandling: 'merge'
        });
    }

    private _save(collection: NPElement) {
        this._elementWriter.canSaveData()
            .pipe(
                filter(canSave => canSave),
                flatMap(() => {
                    return this._modalService.openConfirmModal(CogedimConstantes.SAVE_MODE, Messages.SAVE_QUESTION);
                }),
                filter(response => response),
                flatMap(() => {
                    return this._elementWriter.publishToNP();
                })
            )
            .subscribe(() => {
                this._modalService.openSnackBar(Messages.SAVE_SUCCES, CogedimConstantes.SNACK_CLASS);
                this._gotoPrestations(collection);
            }, err => {
                if (err === -1) { // Erreurs ! données invalides
                    this._modalService.openErrorModal();
                }
            });
    }

    private _openDialog(mode: string, message?: string) {
        return this._dialog.open(DialogWarningComponent, {
            width: '500px',
            data: {mode: mode, message: message}
        });
    }

    getCISV(caracConfig: CaracConfig): CaracIdStateValue {
        return CogedimBlockHelper.getValue(caracConfig, this.element);
    }
}
