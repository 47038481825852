import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogErrorsComponent} from './components/dialog-errors/dialog-errors.component';
import {DialogWarningComponent} from './components/dialog-warning/dialog-warning.component';
import {SharedModule} from '../shared/shared.module';
import {DialogWarningAskComponent} from './components/dialog-warning-ask/dialog-warning-ask.component';
import {DownloadingComponent} from './components/downloading/downloading.component';
import {DialogGenericComponent} from './components/dialog-generic/dialog-generic.component';
import {ImageOverlayComponent} from './components/image-overlay/image-overlay.component';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {DialogCatalogueNameComponent} from './components/dialog-catalogue-name/dialog-catalogue-name.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogPreloadComponent } from './components/dialog-preload/dialog-preload.component';

@NgModule({
    declarations: [
        DialogErrorsComponent,
        DialogWarningComponent,
        DialogWarningAskComponent,
        DownloadingComponent,
        DialogGenericComponent,
        ImageOverlayComponent,
        DialogCatalogueNameComponent,
        DialogOverlayComponent,
        DialogPreloadComponent
    ],
  imports: [
    CommonModule,
    SharedModule,
    NgxImageZoomModule.forRoot(),
  ],
    entryComponents: [
        DialogErrorsComponent,
        DialogWarningComponent,
        DialogWarningAskComponent,
        DownloadingComponent,
        DialogGenericComponent,
        ImageOverlayComponent,
        DialogCatalogueNameComponent,
        DialogOverlayComponent,
        DialogPreloadComponent
    ]
})
export class CogedimSdkGraphicsModule {
}
