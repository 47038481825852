import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {NPElement} from '@nextpage/np-sdk-data';
import {ElementRepositoryService} from './element-repository.service';
import {FileReaderService} from '@nextpage/np-sdk-graphics';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SynopticReaderService {

  private _synoptics = new BehaviorSubject<NPElement[]>([]);

  constructor(private _elementRepository: ElementRepositoryService) { }

  /**
   * Retourne la liste des Synoptiques stockés dans NP
   */
  private _fetchSynoptics(folderExtID: string) {
    return this._elementRepository.getDescendants(folderExtID, [[]])
      .pipe(
        tap(synoptics => {
          this._synoptics.next(synoptics);
        })
      );
  }

  public initialize(folderExtID: string) {
    return this._fetchSynoptics(folderExtID);
  }

  public getSynoptics() {
    return this._synoptics.asObservable();
  }
}
