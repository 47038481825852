import {Injectable} from '@angular/core';
import {Breadcrumb} from '../Model/models';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private breadcrumb: Breadcrumb[] = [];
    private fromBreadcrumb: boolean;
    private _subBreadcrumb$ = new Subject<Breadcrumb[]>();

    constructor() {
    }

    getItems(): Observable<Breadcrumb[]> {
        return this._subBreadcrumb$.asObservable();
    }

    getFromBreadcrumb() {
        return this.fromBreadcrumb;
    }

    setFromBreadcrumb(status) {
        this.fromBreadcrumb = status;
    }

    add(itemToAdd: Breadcrumb) {
        if (itemToAdd) {
            this.breadcrumb.push(itemToAdd);
            this._update();
        }
    }

    init(items: Breadcrumb[]) {
        this.breadcrumb = items;
        this._update();
    }

    private _update() {
        this._subBreadcrumb$.next(this.breadcrumb);
    }
}
