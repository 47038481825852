import {NPFillRateTypeDicoCarac} from './np-fill-rate-type-dico-carac';
import {Access} from './access';

/**
 * Classe NPDicoCarac correspondant à un Dicocarac dans nextPage
 */
export class NPDicoCarac {

    public constructor(param) {
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('Access')) {
            this.Access = param.Access;
        }
        if (param.hasOwnProperty('ExtID')) {
            this.ExtID = param.ExtID;
        }
        if (param.hasOwnProperty('Rank')) {
            this.Rank = param.Rank;
        }
        if (param.hasOwnProperty('ID')) {
            this.ID = param.ID;
        }
        if (param.hasOwnProperty('TypeCarac')) {
            this.TypeCarac = param.TypeCarac;
        }
        if (param.hasOwnProperty('TypeCode')) {
            this.TypeCode = param.TypeCode;
        }
        if (param.hasOwnProperty('LangCode')) {
            this.LangCode = param.LangCode;
        }
        if (param.hasOwnProperty('DossierDicoCaracID')) {
            this.DossierDicoCaracID = param.DossierDicoCaracID;
        }
      if (param.hasOwnProperty('DossierDicoCaracOrder')) {
        this.DossierDicoCaracOrder = param.DossierDicoCaracOrder;
      }
        if (param.hasOwnProperty('LibelleDossier')) {
            this.LibelleDossier = param.LibelleDossier;
        }
        if (param.hasOwnProperty('Name')) {
            this.Name = param.Name;
        }
        if (param.hasOwnProperty('SecondaryName')) {
            this.SecondaryName = param.SecondaryName;
        }
        if (param.hasOwnProperty('PublicationName')) {
            this.PublicationName = param.PublicationName;
        }
        if (param.hasOwnProperty('Unite')) {
            this.Unite = param.Unite;
        }
        if (param.hasOwnProperty('Required')) {
            this.Required = param.Required;
        }
        if (param.hasOwnProperty('Editable')) {
            this.Editable = param.Editable;
        }
        if (param.hasOwnProperty('Translatable')) {
            this.Translatable = param.Translatable;
        }
        if (param.hasOwnProperty('Order')) {
            this.Order = param.Order;
        }
        if (param.hasOwnProperty('MinValue')) {
            this.MinValue = param.MinValue;
        }
        if (param.hasOwnProperty('MaxValue')) {
            this.MaxValue = param.MaxValue;
        }
        if (param.hasOwnProperty('MaxLength')) {
            this.MaxLength = param.MaxLength;
        }
        if (param.hasOwnProperty('Value')) {
            this.Value = param.Value;
        }
        if (param.hasOwnProperty('Description')) {
            this.Description = param.Description;
        }
        if (param.hasOwnProperty('Destination')) {
            this.Destination = param.Destination;
        }
        if (param.hasOwnProperty('CalculationFormula')) {
            this.CalculationFormula = param.CalculationFormula;
        }
        if (param.hasOwnProperty('FillRates')) {
            this.FillRates = param.FillRates;
        }
        if (param.hasOwnProperty('Values')) {
            const vls2: NPListeValues[] = [];
            param.Values.forEach(npv => vls2.push(new NPListeValues(npv)));
            this.Values = vls2;
        }
        if (param.hasOwnProperty('Authorization')) {
            this.Authorization = param.Authorization;
        }
        if (param.hasOwnProperty('ElementLinkExtID')) {
            this.ElementLinkExtID = param.ElementLinkExtID;
        }
        if (param.hasOwnProperty('ReflexiveCharID')) {
            this.ReflexiveCharID = param.ReflexiveCharID;
        }
        if (param.hasOwnProperty('ReflexiveCharExtID')) {
            this.ReflexiveCharExtID = param.ReflexiveCharExtID;
        }
        if (param.hasOwnProperty('TargetCharID')) {
            this.TargetCharID = param.TargetCharID;
        }
        if (param.hasOwnProperty('TargetCharExtID')) {
            this.TargetCharExtID = param.TargetCharExtID;
        }
        if (param.hasOwnProperty('PathFileSystem')) {
            this.PathFileSystem = param.PathFileSystem;
        }
        if (param.hasOwnProperty('Cardinality')) {
            this.Cardinality = param.Cardinality;
        }
    }

    Access?: Access;
    ExtID?: string;
    Rank?: number;
    ID?: number;
    TypeCarac?: string;
    TypeCode?: TypeCode;
    LangCode?: string;
    DossierDicoCaracID?: number;
    DossierDicoCaracOrder?: number;
    LibelleDossier?: string;
    Name?: string;
    SecondaryName?: string;
    PublicationName?: string;
    Unite?: string;
    Required?: string;
    Editable?: string;
    Translatable?: string;
    Order?: number;
    MinValue?: number;
    MaxValue?: number;
    MaxLength?: number;
    Value?: string;
    Description?: string;
    Destination?: string;
    CalculationFormula?: string;
    FillRates?: Array<NPFillRateTypeDicoCarac>;
    Values?: NPListeValues[]; // les map ne sont pas reconstruites automatiquement... domamge
    Authorization?: string;
    ElementLinkExtID: string;
    ReflexiveCharID: number;
    ReflexiveCharExtID: string;
    TargetCharID: number;
    TargetCharExtID: string;
    PathFileSystem: string;
    Cardinality: number;
}

export class NPListeValues {
    public constructor(param) {
        if (param == null) {
            return;
        }
        if (param.hasOwnProperty('ValeurID')) {
            this.ValeurID = param.ValeurID;
        }
        if (param.hasOwnProperty('Ordre')) {
            this.Ordre = param.Ordre;
        }
        if (param.hasOwnProperty('Valeur')) {
            this.Valeur = param.Valeur;
        }
    }

    ValeurID: number;
    Ordre: number;
    Valeur: string;
}

export enum DicoCarac {
    PRODUCT_LABEL_CARAC = '##ProductLabelCaracExtID',
    DC_NP_ADC_LIEN_ARTICLE_DETAIL = 'DC_NP_ADC_LIEN_ARTICLE_DETAIL',
    TP_A_DECOMPOSITION = 'TP_A_DECOMPOSITION',
    FRT_GLOBAL = 'FRT_GLOBAL',
    DTO_SYSTEM_SEARCH_RANKING = 'DTOSystemSearchRanking',
    DTO_SYSTEM_STATUS = 'DTOSystemStatus',
    DTO_SYSTEM_LAST_MODIFIED_DATE = 'DTOSystemLastModifiedDate',
    DTO_SYSTEM_CHAR_TEMPLATE = 'DTOSystemCharTemplate',
    ANY_LABEL = '##ANYLABEL',
    FAMILY_LABEL = '##FamilyLabel',
    MEDIADIRECTORY_LABEL = '##MediaDirectoryLabel',
    MEDIA_FILE = '##MediaFile',
    MEDIA_LABEL = '##MediaLabel',
    PRODUCT_LABEL = '##ProductLabel',
    NOTIFICATION_LABEL = '##NotificationLabel',
    REFERENCE_LABEL = '##ReferenceLabel',
    PRODUCTFILLRATE_GLOBAL = 'ProductFillRate_GLOBAL'
}

export enum TypeCode {
    TXT = 'TXT',
    TXTLONG = 'TXTLONG',
    TXTHTML = 'text',
    NUM = 'text',
    IMG = 'image',
    FICH = 'text',
    FICHPDF = 'text',
    DATE = 'text',
    PUR = 'text',
    LISTE = 'list',
    LISTSPE = 'LISTE',
    LIENPP = 'link',
    LIENPR = 'link',
    LIENRR = 'link',
    LIENRP = 'link',
    CHOIX = 'link',
    TAB = 'text',
    TABCOMP = 'text',
    TABCONF = 'text',
    ELEM = 'text',
    STRUCTUREDDATA = 'STRUCTUREDDATA',
    LIENREFLEXIF = 'LIENREFLEXIF',
    SYSLABEL = 'text',
    SYSLABELSPE = 'SYSLABEL',
    MXLGTHGRP = 'text',
    LIEN = 'LIEN',
    NUMERIC = 'NUM',
    DATETYPE = 'DATE',
    LIENPPTYPE = 'LIENPP',
    TAB_TYPE = 'TAB',
    TXTHTML_HTML = 'TXTHTML'
}
