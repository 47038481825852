import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {NPElement} from '../model/np-element';
import {NPCaracLien, NPCaracLienRebuildValue, NPCaracValeur} from '../model/np-carac-valeur';
import {Observable} from 'rxjs/Observable';
import {NPAPIElementLinksResult} from '../model/NPAPIElementLinksResult';
import {WSParameters, WsParamsService} from './ws-params.service';
import {NPDicoCarac} from '../model/np-dico-carac';
import {Constantes} from '../const/constantes';

@Injectable({
  providedIn: 'root'
})
export class ElementRepository {

  private _urlGetElement = '/api/sdk/element/ElementsAndLinks';
  private _urlGetDescendants = '/api/sdk/element/DescendantsAndLinks';
  private _urlGetByCharTemplate = '/api/sdk/element/ElementsByCharTemplate';
  private _urlGetSearchForLink = '/api/sdk/element/GetElementsByTextForDicoCarac';

  public forTestAllResults: Map<string, NPElement>;

  constructor(private _http: HttpClient, private _paramServices: WsParamsService, private _const: Constantes = null) {
    this._initialize();
  }

  private _initialize() {

  }

  getElements(ElementIDs: string[], linksPath: string[][]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: ElementIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID
        };
        return this._http.post<Object>(this._urlGetElement, postParameters);
      }),
      tap((data) => {
        // reconstruction de la variable forTestAllResults uniquement si on n'est pas en prod
        if (this._const != null && !this._const.envProd) {
          if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              const requestResultCasted = new NPAPIElementLinksResult(data['Results']);

              this.forTestAllResults = new Map<string, NPElement>();
              requestResultCasted.Elements.forEach((elmt) => {
                this.forTestAllResults.set(elmt.ExtID, elmt);
              });
            }
          }
        }
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

    getDescendants(ElementIDs: string[], linksPath: string[][], onlyOneLevel = false): Observable<Map<string, NPElement>> {
        return this._paramServices.getParams().pipe(
            switchMap((params: WSParameters) => {
                const postParameters = {
                    ElementsExtIDs: ElementIDs,
                    Paths: linksPath,
                    ContextID: params.ContextID,
                    LangID: params.LangID,
                    OnlyOneLevel: onlyOneLevel
                };
                return this._http.post<Object>(this._urlGetDescendants, postParameters);
            }),
            map((data) => {
                if (data.hasOwnProperty('Results')) {
                    if (data['Results'] != null) {
                        return this._rebuild(data['Results']);
                    }
                }
            }),
            first(),
            shareReplay(1)
        );
    }

  getByCharTemplates(charTemplatesExtID: string, linksPath: string[][]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          CharTemplateExtID: charTemplatesExtID,
          ContextID: params.ContextID,
          Paths: linksPath,
          LangID: params.LangID
        };
        return this._http.post<Object>(this._urlGetByCharTemplate, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public searchForLink(dicoCarac: NPDicoCarac, searchText: string, element: NPElement) {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
            DicoCaracID: dicoCarac.ID,
            SearchText: searchText,
            ElementID: element.ID,
            ContextID: params.ContextID,
            LangID: params.LangID
          }
        ;
        return this._http.post<Object>(this._urlGetSearchForLink, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      map((data) => {
        const result = [];
        data.forEach(value => {
          result.push(value);
        });
        return result;
      }),
      first(),
      shareReplay(1)
    );
  }

    public searchForLinkByDicoExtID(dicoCaracID: number, searchText: string, element: NPElement) {
        return this._paramServices.getParams().pipe(
            switchMap((params: WSParameters) => {
                const postParameters = {
                        DicoCaracID: dicoCaracID,
                        SearchText: searchText,
                        ElementID: element.ID,
                        ContextID: params.ContextID,
                        LangID: params.LangID
                    }
                ;
                return this._http.post<Object>(this._urlGetSearchForLink, postParameters);
            }),
            map((data) => {
                if (data.hasOwnProperty('Results')) {
                    if (data['Results'] != null) {
                        return this._rebuild(data['Results']);
                    }
                }
            }),
            map((data) => {
                const result = [];
                data.forEach(value => {
                    result.push(value);
                });
                return {dicoCaracID: dicoCaracID, result: result};
            }),
            first(),
            shareReplay(1)
        );
    }

  public _rebuild(requestResult: any): Map<string, NPElement> {
    const requestResultCasted = new NPAPIElementLinksResult(requestResult);
    const result = new Map<string, NPElement>();
    // on recaste les éléments pour éviter des pertes de propriétés bizarres
    const recasted = new Map<string, NPElement>();
    requestResultCasted.Elements.forEach((eltData) => {
      // reconstruction propre des objets
      const element = new NPElement(eltData);
      recasted.set(element.ID.toString(), element);
    });
    requestResultCasted.Elements = recasted;

    requestResultCasted.Elements.forEach((element) => {

      // reconstruction des liens entre les éléments
      element.Values.forEach((value: NPCaracValeur) => {
        value.Element = element;
        if ((<NPCaracLien>value).LinkedElements != null) { // Si caracLien
          (<NPCaracLien>value).RebuildLinkedElements = (<NPCaracLien>value).LinkedElements
            .reduce((accumulator, val) => {
              if (requestResultCasted.Elements.has(val.ElementID.toString())) {
                const rebuildedVal = new NPCaracLienRebuildValue();
                rebuildedVal.Order = val.Order;
                rebuildedVal.Element = requestResultCasted.Elements.get(val.ElementID.toString());
                accumulator.push(rebuildedVal);
              } else if (val.hasOwnProperty('RebuildElement')) {
                // dans le cadre des API, nextPage retourne l'objet dans RebuildElement. Ca marche et ca tombe bien.
                const rebuildedVal = new NPCaracLienRebuildValue();
                rebuildedVal.Order = val.Order;
                rebuildedVal.Element = val['RebuildElement'];
                accumulator.push(rebuildedVal);
              }
              return accumulator;
            }, []);
        }
      });
      // reconstruction des parents
      if (requestResultCasted.Elements.has(element.ParentID.toString())) {
        element.Parent = requestResultCasted.Elements.get(element.ParentID.toString());
        if (element.ParentExtID == null) {
          element.ParentExtID = element.Parent.ExtID;
        }
        if (element.Parent.Children == null) {
          element.Parent.Children = [];
        }
        element.Parent.Children.push(element);
      }

    });

    requestResultCasted.Results.map((ElementID) => {
      const element = requestResultCasted.Elements.get(ElementID.toString());
      result.set(element.ExtID, requestResultCasted.Elements.get(ElementID.toString()));
    });

    return result;
  }
}
