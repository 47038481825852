import {NPElement} from '@nextpage/np-sdk-data';
import {Synoptic} from '../Model/synoptic-model';

export class SynopticHelper {

    public static generateTree(elements: NPElement[]): Synoptic[] {
        return elements && elements.length > 0 ?
        elements.map(element => new Synoptic(element)) : [];
    }
}
