import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {GenCatalogueService} from '../../sdk/data/services/gen-catalogue.service';
import {CatalogueSummary, EditionHomeItem} from '../../sdk/data/Model/models';
import {NpUser} from '../../sdk/data/Model/np-user';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CogedimConstantes, CogedimHomeEditionItems, Messages} from '../../sdk/data/constantes/cogedim-constantes';
import {filter, flatMap} from 'rxjs/operators';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {CogedimDownloadService} from '../../sdk/data/services/cogedim-download.service';
import {UploadNoticeService} from '../../sdk/data/services/upload-notice.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {Subscription} from 'rxjs';
import {SynopticWriterService} from '../../sdk/data/services/synoptic-writer.service';

@Component({
    selector: 'app-home-edition-item',
    templateUrl: './home-edition-item.component.html',
    styleUrls: ['./home-edition-item.component.scss']
})
export class HomeEditionItemComponent implements OnInit, OnChanges {

    @Input() currentUser: NpUser;
    @Input() currentCollectionID: string;
    @Input() item: EditionHomeItem;
    @Input() chosenCollection: NPListeValues;
    @Input() currentOperation: NPElement;

    private _objectToSend = new CatalogueSummary();
    public vignettePath: string;
    private _documentType: CogedimHomeEditionItems;
    private _operationId: number;
    private webElement: NPElement;
    private exportSub$: Subscription;

    constructor(
        private _genCatService: GenCatalogueService,
        private _modalService: ModalService,
        private _elementWriter: CogedimElementWriterService,
        private _ElementRepository: ElementRepositoryService,
        private _downloadService: CogedimDownloadService,
        private _noticeService: UploadNoticeService,
        private _synopticWriterService: SynopticWriterService) {
    }

    ngOnInit() {
        this._initObjectToSend();
    }

    ngOnChanges() {
        if (this.item) {
            this._documentType = this.item.itemType;
        }

        if (this._itemIs(CogedimHomeEditionItems.EXCEL_EXPORT)) {
            if (this.currentOperation) {
                this._operationId = Number(this.currentCollectionID);
            }
        }

        // ExportSummary.
        if (this.currentUser) {
            this._objectToSend.Mail = this.currentUser.Email;
            this._objectToSend.References = [this.currentCollectionID];
        }
        this._objectToSend.Title = this.chosenCollection ? this.chosenCollection.Valeur : 'Titre inconnu';
        this.vignettePath = `assets/vignettes/${this.item.vignetteName}`;
    }

    private _initObjectToSend() {
    }

    onButtonClick() {
        if (this._elementWriter.hasModifications()) {
            this._save();
        } else {
            if (this._documentType === CogedimHomeEditionItems.DOWNLOAD_NOTICE) {
                // Si Bouton "Télécharger votre Notice desciptive de vente définitive
                this._modalService.openDownloadNotice();
            } else if (this._documentType === CogedimHomeEditionItems.DESCRIPTIVE_NOTICE || this._documentType === CogedimHomeEditionItems.EXCEL_EXPORT) {
                // Notice descriptive de vente ou Export Excel
                this._openEditModal();
            } else if (this._documentType === CogedimHomeEditionItems.GENERAL_AND_OTHER_CUSTOMIZATION) {
                // Génératlités autres personnalisations
                this._downloadGeneralAndOtherCustomizations();
            } else if (this._documentType === CogedimHomeEditionItems.WITNESS_CONTROL) {
                // Témoin control
                this._constructionDialog();
            } else if (this._documentType === CogedimHomeEditionItems.SYNOPTIC) { // Synoptique
                this._generateSynoptic();
            }

        }
    }

    private _openEditModal() {
        if (this.exportSub$) {
            this.exportSub$.unsubscribe();
        }
        this.exportSub$ = this._modalService.openCatalogueEditName(this._objectToSend, this._documentType, this._operationId).afterClosed()
            .subscribe(response => {
                if (response !== 0) {
                    this._modalService.openSnackBar(response, CogedimConstantes.SNACK_CLASS);
                }
            });
    }

    private _save() {
        this._elementWriter.canSaveData()
            .pipe(
                filter(canSave => canSave),
                flatMap(() => {
                    return this._modalService.openConfirmModal(CogedimConstantes.SAVE_MODE, Messages.SAVE_QUESTION);
                }),
                filter(response => response),
                flatMap(() => {
                    this._modalService.openPreloadDialog();
                    return this._elementWriter.publishToNP();
                })
            )
            .subscribe(() => {
                this._modalService.openSnackBar(Messages.SAVE_SUCCES, CogedimConstantes.SNACK_CLASS);
                this._modalService.closePreloadDialog();
            }, err => {
                if (err === -1) { // Erreurs ! données invalides
                    this._modalService.closePreloadDialog();
                    this._modalService.openErrorModal();
                }
            });
    }

    private _itemIs(itemType: string) {
        return this.item.itemType === itemType;
    }

    private _downloadGeneralAndOtherCustomizations() {
        this._ElementRepository.getElementByExtID(CogedimConstantes.PAGE_WEB)
            .pipe(
                flatMap(webElement => {
                    this.webElement = webElement;
                    return this._noticeService.downloadFileFromServer(this.webElement,
                        CogedimDicocarcs.WEB_EDITION_GENERALITES_ET_AUTRES_PERSONNALISATIONS, '.pdf');
                })
            )
            .subscribe(response => {
                if (response && response.Success && response.Messages) {
                    this._modalService.openSnackBar(response.Messages, CogedimConstantes.SNACK_CLASS);
                }
            }, err => {
                if (err.Errors && err.Errors.length > 0) {
                    this._modalService.openSnackBar(err.Errors[0], CogedimConstantes.SNACK_CLASS);
                }
            });
    }

    private _constructionDialog() {
        this._modalService.openWitnessControlDialog(this.currentOperation, this.chosenCollection).afterClosed()
            .subscribe(response => {

            });
    }

    private _generateSynoptic() {
        if (this.currentOperation && this.chosenCollection && this.currentUser) {

            this._modalService.openSynopticPreloaderDialog(
                this.currentOperation.ID,
                this.currentUser.ID,
                Number(this.currentCollectionID),
                this.chosenCollection.Valeur);

        }
    }
}
