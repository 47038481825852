import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {
    CaracConfig,
    ElementRepository,
    ElementWriterService, ManagementRulesCheckerService,
    NPCaracLien,
    NPElement,
    UiTranslationService,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import {SummaryElement} from '../select-list-value-lien/select-list-value-lien.component';
import {MatCheckboxChange} from '@angular/material';

import {Sheet, ThemingService} from '../../services';
import jss from 'jss';

@Component({
    selector: 'lib-check-list',
    templateUrl: './check-list.component.html',
    styleUrls: ['./check-list.component.css']
})
export class CheckListComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public checkedValues: SummaryElement[] = [];

    listPossibles: SummaryElement[] = [];
    listPossiblesFiltered: SummaryElement[] = [];
    listLinked: SummaryElement[] = [];
    voe: ValueOneElementHelper = new ValueOneElementHelper();

    private _translations = new Map<string, string>();

    public isValid = true;

    public classes: Object;

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {
        this._updateListePossible('');
        if (this.value) {
            this.checkedValues = this.value.RebuildLinkedElements.map(value => {
                return {
                    elementName: this.voe.getLabel(value.Element),
                    elementID: value.Element.ID,
                    elementOrder: value.Element.getValueSearchRankLevelSearchRanking(),
                    element: value.Element
                };
            });
        }

        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });

        const override_css = this._theming.getComponentConfigStyle('CheckListComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach()
            this.classes = sheet.classes;
        }
    }

    isChecked(value: SummaryElement) {
        return this.checkedValues.some(currValue => currValue.element.ExtID === value.element.ExtID);
    }

    /**
     * Enregistre l'elément coché
     */
    private selectOption(value: SummaryElement) {
        this._elementWriter.concatValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, value.element);
        this.checkedValues.push(value);
    }

    /**
     * Initialisation de la liste des éléments possibles
     */
    private _updateListePossible(searchText: string) {
        this.listPossibles = [];
        this._elementRepository.searchForLink(this.caracConfig.DicoCarac, searchText, this.value.Element)
            .subscribe((result: NPElement[]) => {
                this.listPossibles = result.map(rel => {
                    // On ajoute l'élément à la liste s'il n'est pas lié
                    return {
                        elementName: this.voe.getLabel(rel),
                        elementID: rel.ID,
                        elementOrder: rel.getValueSearchRankLevelSearchRanking(),
                        element: rel
                    };
                });
                this._filterListPossibles();
            });
    }

    /**
     * Filtrer la liste des éléments possibles pour éviter d'afficher les éléments qui sont aussi liés
     */
    private _filterListPossibles() {
        const listIDs = this.listLinked.map((val) => val.elementID);
        this.listPossiblesFiltered = this.listPossibles.filter((val) => !listIDs.includes(val.elementID));
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

    check(event: MatCheckboxChange | Event, value: SummaryElement) {
        if (event['checked']) {
            this.selectOption(value);
        } else {
            this._uncheck(value);
        }
        this._initRequiedError();
    }

    private _uncheck(uncheckedValue: SummaryElement) {
        const elementToRemove = this.value.Element.getValueLien(this.caracConfig.DicoCarac.ExtID)
            .RebuildLinkedElements.find(d => d.Element.ID === uncheckedValue.elementID);
        if (elementToRemove) {
            this._elementWriter.deleteValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, elementToRemove.Element);
            this.checkedValues = this.checkedValues.filter(currValue => currValue.elementID !== uncheckedValue.elementID);
        }
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }
}
