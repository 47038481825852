import {Constantes, DataModelMocker} from '@nextpage/np-sdk-data';
import {Injectable} from '@angular/core';
import {CredentialsService} from '../services/auth/credentials.service';
import {DataMockerSdkdebugCogedimProd} from './data-mocker-sdkdebug-cogedim-prod';

@Injectable({providedIn: 'root'})
export class DataMockerSdkdebugCogedimfullProd extends DataModelMocker {
    constructor(private _constantes: Constantes, private _credentialsService: CredentialsService) {
        super();
        super.AddMocker(new DataMockerSdkdebugCogedimProd(_constantes, _credentialsService));
    }
}
