export const COGIDIM_ROUTES = {
  Login: {
    name: 'Login',
    route: 'login'
  },
  MainPage: {
    name: 'Main page',
    route: 'mainpage'
  },
  Operation: {
    name: 'Opération',
    route: 'operation'
  },
  Edition: {
    name: 'Edition',
    route: 'edition'
  }
};
