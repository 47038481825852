import {Injectable} from '@angular/core';
import {ValueOneElementHelper} from './value-one-element-helper';
import {ElementWriterService} from './element-writer.service';
import {NPElementRepository} from './np-element-repository';
import {first} from 'rxjs/internal/operators/first';
import {flatMap, shareReplay} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {NPElement} from '../model';

interface MediaToCreate {
    File: File;
    ParentDirectoryExtID: string;
}

@Injectable({
    providedIn: 'root'
})
export class MediaCreationService {
    private _modifListByElement: Map<string, Map<string, MediaToCreate[]>> = new Map<string, Map<string, MediaToCreate[]>>();
    private readonly _valueHelper: ValueOneElementHelper;

    constructor(private _elementWriter: ElementWriterService,
                private _elementReader: NPElementRepository) {
        this._valueHelper = new ValueOneElementHelper();
    }

    public setMedias(element: NPElement, dicoCaracExtID: string, medias: MediaToCreate[]) {
        if (!this._modifListByElement.has(element.ExtID)) {
            this._modifListByElement.set(element.ExtID, new Map<string, MediaToCreate[]>());
        }
        this._modifListByElement.get(element.ExtID).set(dicoCaracExtID, medias);
    }

    public cancelAllModifications(elementExtId: string) {
        if (elementExtId && elementExtId !== '' && this._modifListByElement.has(elementExtId)) {
            this._modifListByElement.delete(elementExtId);
        }
    }
    /**
     * Retourne tous les medias de l'élement passé en paramètre
     */
    public getAllMediasByElementExtId(elementExtId: string) {
        return this._modifListByElement.get(elementExtId);
    }

    /**
     * Retourne les fichiers relatifs à un dicocarac
     * param {string} elementExtId
     * param {string} dicoCaracExtid
     * returns {any}
     */
    public getMediaByDicoCaracExtId(elementExtId: string, dicoCaracExtid: string) {
        if (this.getAllMediasByElementExtId(elementExtId) === undefined || this.getAllMediasByElementExtId(elementExtId).get(dicoCaracExtid) === undefined) {
            return null;
        } else {
            return this.getAllMediasByElementExtId(elementExtId).get(dicoCaracExtid);
        }
    }

    public flush() {
        this._modifListByElement = new Map<string, Map<string, MediaToCreate[]>>();
    }

    public publishMedias() {

        const arrayParentExtIDs = [];
        const elementExtIDs = [];
        this._modifListByElement.forEach((caracteristiques, elementExtID) => {
            elementExtIDs.push(elementExtID);
            caracteristiques.forEach((mediasToCreate) => {
                mediasToCreate.forEach(media => arrayParentExtIDs.push(media.ParentDirectoryExtID));
            });
        });
        const elements$ = this._elementReader.getElements([...arrayParentExtIDs, ...elementExtIDs], []).pipe(
            first(),
            shareReplay(1)
        );
        return elements$.pipe(
            flatMap((elements) => {
                const arrayObservablesResult = [];
                this._modifListByElement.forEach((caracteristiques, elementExtID) => {
                    caracteristiques.forEach((mediasToCreate, caracExtID) => {
                        mediasToCreate.forEach(media => {
                            arrayObservablesResult.push(this._elementWriter.createMedia(media.File, elements.get(media.ParentDirectoryExtID).ID).pipe(
                                first(),
                                tap(newMedia => {
                                    this._elementWriter.setValueLink(elements.get(elementExtID), caracExtID, [newMedia]);
                                })
                            ));
                        });
                    });
                });
                this._modifListByElement = new Map<string, Map<string, MediaToCreate[]>>();
                return forkJoin(arrayObservablesResult);
            }),
            flatMap(result => {
                return this._elementWriter.publishToNP();
            })
        );

    }
}
