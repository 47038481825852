import {Injectable} from '@angular/core';
import {TOKEN_NAME} from './cogedim-authentication.service';
import {COGIDIM_ROUTES} from '../../constantes/routes';
import {Router} from '@angular/router';

export const HAS_BEEN_DISCONNECTED = 'XXX**----_____@@@))))))XXXX';

@Injectable({
    providedIn: 'root'
})
export class CredentialsService {
    public token: string;

    constructor(private _router: Router) {
    }

    public initToken(userToken: string) {
        localStorage.clear();
        localStorage.setItem(TOKEN_NAME, userToken);
        this.token = userToken;
    }

    getToken() {
        return localStorage.getItem(TOKEN_NAME) || this.token;
    }

    isConnected() {
        return this.getToken() && this.getToken() !== '';
    }

    logout() {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem(HAS_BEEN_DISCONNECTED, 'true');
        return this._router.navigate([`/${COGIDIM_ROUTES.Login.route}`]);
    }

    hasBeenDisconnected() {
      const value = localStorage.getItem(HAS_BEEN_DISCONNECTED);
        return value && value === 'true';
    }
}
