import {Component, OnInit} from '@angular/core';
import {Breadcrumb} from '../../sdk/data/Model/models';
import {COGIDIM_ROUTES} from '../../sdk/data/constantes/routes';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {Router} from '@angular/router';
import {CollectionService} from '../../sdk/data/services/collection.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    public breadcrumb: Breadcrumb[] = [];

    constructor(private _breadcrumbService: BreadcrumbService, private _route: Router, private _collectionService: CollectionService) {
    }

    ngOnInit() {
        this._breadcrumbService.getItems()
            .subscribe(items => {
                this.breadcrumb = items;
            });
    }

    goto(breadcrumb) {
        if (breadcrumb.route && breadcrumb.params) {
            this._breadcrumbService.setFromBreadcrumb(true);
            this._route.navigate([breadcrumb.route], {
                queryParams: {operationExtID: breadcrumb.params.operation.ExtID},
                // queryParamsHandling: 'merge'
            });
        }
    }

    gotoMainPage() {
        this._route.navigate([`/${COGIDIM_ROUTES.MainPage.route}`]);
        this._collectionService.cleanStorage(); // On efface la collection sélectionnée
    }
}
