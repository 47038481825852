import {Injectable} from '@angular/core';
import {KitMap} from '../Model/models';
import {NPElement} from '@nextpage/np-sdk-data';
import {BuildingModuleHelper} from '../helpers/building-module-helper';

@Injectable({
    providedIn: 'root'
})
export class KitService {
    /**
     * Liste des combinaisons [collections - kits]
     */
    private _kitMapFamily: Map<string, KitMap[]> = new Map<string, KitMap[]>();
    private _update: boolean;

    constructor() {
    }

    // Ajoute une collection dans le kitMap
    public addToKitMap(familyExtId: string, collection: NPElement) {
        if (!this._kitMapFamily.has(familyExtId)) {
            this._kitMapFamily.set(familyExtId, []);
        }
        if (!this.hasKitMap(familyExtId, collection.ID)) {
            this._kitMapFamily.get(familyExtId).push(new KitMap(collection));
        }
        this.setUpdate(true);
    }

    public hasKitMap(familyExtId: string, collectionId: number) {
        return this._kitMapFamily.has(familyExtId) &&
            this._kitMapFamily.get(familyExtId).some(_kitMap => _kitMap.collection.ID === collectionId);
    }

    public hasBeenUpdate(familyExtId: string) {
        return this._kitMapFamily.has(familyExtId);
        // return this._update;
    }

    getKitMap2(familyExtId: string) {
        // On conserve l'objet dans l'état initial
        const tmpKitMaps: KitMap[] = [];
        if (this._kitMapFamily.has(familyExtId)) {
            this._kitMapFamily.get(familyExtId).forEach(_currentKitMap => {
                tmpKitMaps.push(new KitMap(_currentKitMap.collection));
            });
        }
        return tmpKitMaps;
    }

    getKitMap(familyExtId: string, collectionId: number) {
        // On conserve l'objet dans l'état initial
        const tmpKitMap = this._kitMapFamily.get(familyExtId).find(_currentKitMap => _currentKitMap.collection.ID === collectionId);
        return new KitMap(tmpKitMap.collection);
    }

    public setUpdate(value: boolean) {
        this._update = value;
    }

    extractKitsFromCollection(_currentCollection: NPElement) {
        return BuildingModuleHelper.extractKitsFromCollection(_currentCollection);
    }

    getParentRequests(_kits: NPElement[]) {
        /*const _parentRequests = [];
        _kits.forEach(_kit => {
            const tmpRequest$ = this._elementRepository
                .getElements([_kit.ParentExtID])
                .pipe(
                    flatMap(parent => {
                        _kit.Parent = parent.get(_kit.ParentExtID);
                        return this._elementRepository.getElements([_kit.Parent.ParentExtID])
                            .pipe(
                                tap(response => {
                                    _kit.Parent.Parent = response.get(_kit.Parent.ParentExtID);
                                })
                            );
                    })
                );
            _parentRequests.push(tmpRequest$);
        });
        return forkJoin(_parentRequests);*/
    }
}

