import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CaracConfig, NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {FormControl} from '@angular/forms';
import {HtmlService} from '../../../sdk/graphics/services/html.service';
import {CogedimElementWriterService} from '../../../sdk/data/services/cogedim-element-writer.service';
import {BuildingModuleHelper} from '../../../sdk/data/helpers/building-module-helper';
import {CogedimConstantes} from '../../../sdk/data/constantes/cogedim-constantes';
import {EventService} from '../../../sdk/data/services/event.service';

@Component({
    selector: 'app-construction-combobox',
    templateUrl: './construction-combobox.component.html',
    styleUrls: ['./construction-combobox.component.css']
})
export class ConstructionComboboxComponent implements OnInit, OnChanges {

    @Input() element: NPElement;
    @Input() config: CaracConfig;
    @Input() readonly = false;

    public formControl = new FormControl('');
    public values: NPListeValues[] = [];
    public selectedObject: NPListeValues;

    constructor(private _htmlService: HtmlService,
                private _elementWriter: CogedimElementWriterService,
                private _eventService: EventService) {
    }

    ngOnInit() {
        this.formControl.valueChanges
            .subscribe((_selectedValue) => {
            });

        this._eventService.getEvenByKey(CogedimConstantes.VALIDATE_BUILDING_MODULE_KIT)
            .subscribe(response => {
                if (response) {
                    this.setPosedValue(response.value);
                    this.formControl.patchValue(response.value);
                }
            });
    }

    ngOnChanges(): void {
        this._initSelectedValue();
    }

    private _initSelectedValue() {
        if (this.config && this.config.DicoCarac) {
            this.values = this.config.DicoCarac.Values;
        }
        const oldValue = BuildingModuleHelper.getValueFromListCaracByValueElement(this.element, this.config);
        if (oldValue) {
            this.selectedObject = oldValue;
        } else if (this.values && this.values.length > 0) {
            // On affiche "Non" par defaut, si aucune valeur trouvée
            this.selectedObject = this.values.find(x => x.Valeur === CogedimConstantes.NOT_POSED);
        }
    }

    private setPosedValue(_selectedValue: NPListeValues) {
        this._elementWriter.setPosedValue(_selectedValue, this.element);
    }

    onChangeValue() {
        if (this.selectedObject) {
            this.setPosedValue(this.selectedObject);
        }
    }
}
