import {Observable} from 'rxjs/Observable';
import {HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {DataMocker} from './data-mocker';
import {NpApiResult} from '../../../model';


/**
 * Ce dataMocker a pour objectif de rediriger les requetes envoyées vers "sdk" vers un sdk-debug en ajoutant le token
 */
export class DataMockerSdkpcdevdebug extends DataMocker {


  /**
   * Url de base du site web=> à modifier en fonction des besoins du développeur
   * ATTENTION, il doit y avoir un / à la fin
   */
  private _baseUrl = 'https://localhost:44303/';

  /**
   * Header contenant le tocken =>à modifier en fonction des besoins du développeur
   */
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    //'ApiToken': '5pGfB8bMxo1h5cHuWd6ch1ih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPDPnECplXcndaGHKf4oWOTCg3FByjvDh7nZAFI1riPfB9w-cC8wsBcqzgZ_FKVD_7mANpDk47ZQcGneI5TEg2Spk65z-PAtFWkudwbsydJkI1'
    'ApiToken': 'fakeTokenForDebug-user:tmontanier-mdp:ailier'
  });

  //'ApiToken': 'fakeTokenForDebug-user:tmontanier-mdp:ailier'
  /*
    private _baseUrl = 'https://projet-naosdev.nextpage.fr/';
    private readonly headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiToken': '5pGfB8bMxo1h5cHuWd6ch1ih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPWetYOT2vMUBHrcA8fTIL8SNi3tFj4q1tKYxEcWqmwz6C1xLTKrSh26v_tmfR3c3fTDuXVG38Euw2lvc00oIdGz-2tDkT2JvLXLKbjgVSHFs1'
    });
    */


  /**
   * Redirige les requetes vers les urls de debug
   * Pour utiliser cette classe, il faut spécifier l'url du projet (racine) ainsi que le tocken à envoyer
   */
  mokeResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith('/filehandler') && request.url.indexOf('MapedScreen') > -1) {
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: './assets/MapedScreens.json'
      });
      return next.handle(newRequest);
    }


    // si la requête de base commence par "api/"
    if (request.url.startsWith('/api/')) {

      // on crée une nouvelle requete en changeant les options et en ajoutant la base url
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: this._baseUrl + request.url.replace('/sdk/', '/sdk-debug/'),
        headers: this.headers
      });

      return next.handle(newRequest);
    } else {
      return next.handle(request);
    }

  }

  matchUrl(request: HttpRequest<any>): boolean {
    return true;
    //return (request.Url ==this.getUrlPattern());
  }

  getData(): NpApiResult {
    return null;
  }

  getUrlPattern(): string {
    return '';
  }

}
