import {Injectable} from '@angular/core';
import {
    CaracConfig,
    DicoCarac,
    ElementWriterService,
    NPElement,
    NPElementType,
    NPListeValues,
    TypeCode
} from '@nextpage/np-sdk-data';
import {CogedimConstantes} from '../constantes/cogedim-constantes';
import {Guid} from 'guid-typescript';
import {Observable, of, throwError} from 'rxjs';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {ErrorService} from './error.service';
import {ModalService} from '../../graphics/services/modal.service';
import {map, tap} from 'rxjs/operators';
import {OperationService} from './operation.service';
import {CollectionService} from './collection.service';
import {DatePipe} from '@angular/common';
import {BuildingModuleHelper} from '../helpers/building-module-helper';

@Injectable({
    providedIn: 'root'
})
export class CogedimElementWriterService {

    private _saving: boolean;
    private _removedElements: NPElement[] = [];

    public isSaving = () => this._saving;

    constructor(private _elementWriter: ElementWriterService,
                private _modalService: ModalService,
                private _errorService: ErrorService,
                private _operationService: OperationService,
                private _collectionService: CollectionService,
                private _datePipe: DatePipe) {
    }


    public newElement(elementConfig: CaracConfig[] = [], isCollection = true): NPElement {
        const element = this._elementWriter.createElement(this._getGUID());
        element.ElementType = NPElementType.Product;
        elementConfig.forEach(cc => {
            if (cc && cc.DicoCarac) {
                if (cc.DicoCarac.TypeCode === TypeCode.LISTSPE) {
                    this.setValueListeByIDValues(element, cc.DicoCaracExtID, []);
                } else if (cc.DicoCarac.TypeCode === TypeCode.LIENPPTYPE
                    || cc.DicoCarac.TypeCode === TypeCode.LIEN || cc.DicoCarac.TypeCode === TypeCode.LIENREFLEXIF ) {
                    this._elementWriter.setValueLink(element, cc.DicoCaracExtID, []);
                } else if (cc.DicoCarac.TypeCode === TypeCode.TXT
                    || cc.DicoCarac.TypeCode === TypeCode.SYSLABELSPE
                    || cc.DicoCarac.TypeCode === TypeCode.DATETYPE
                    || cc.DicoCarac.TypeCode === TypeCode.NUMERIC
                    || cc.DicoCarac.TypeCode === TypeCode.TXTHTML_HTML
                    || cc.DicoCarac.TypeCode === TypeCode.TXTLONG
                    || cc.DicoCarac.TypeCode === TypeCode.TAB_TYPE) {
                    this._elementWriter.setValueText(element, cc.DicoCaracExtID, '');
                }
            }

            // ************************* Ce code doit être factorisé **********************************
            if (cc && cc.DicoCaracExtID === DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE && cc.Specific && cc.Specific[DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE]) {
                this._elementWriter.setValueCharTemplate(element, [cc.Specific[DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE]]);
            }

            if (cc && cc.DicoCaracExtID === DicoCarac.PRODUCT_LABEL && cc.Specific && cc.Specific[DicoCarac.PRODUCT_LABEL]) {
                this._elementWriter.setValueText(element, DicoCarac.PRODUCT_LABEL, cc.Specific[DicoCarac.PRODUCT_LABEL]);
            }

            if (cc && cc.DicoCaracExtID === DicoCarac.FAMILY_LABEL && cc.Specific && cc.Specific[DicoCarac.FAMILY_LABEL]) {
                this._elementWriter.setValueText(element, DicoCarac.FAMILY_LABEL, cc.Specific[DicoCarac.FAMILY_LABEL]);
            }

            if (cc && cc.DicoCaracExtID === DicoCarac.REFERENCE_LABEL && cc.Specific && cc.Specific[DicoCarac.REFERENCE_LABEL]) {
                this._elementWriter.setValueText(element, DicoCarac.REFERENCE_LABEL, cc.Specific[DicoCarac.REFERENCE_LABEL]);
            }
            // ********************************************** //
        });
        if (isCollection) {
            element.ParentExtID = CogedimConstantes.F_COLLECTIONS_OPERATIONS;
            this._elementWriter.setValueText(element, DicoCarac.PRODUCT_LABEL, '');
            this._elementWriter.setValueText(element, CogedimConstantes.DC_LIBELLE_COLLECTION, 'Libellé');
            this._elementWriter.setValueCharTemplate(element, [CogedimConstantes.TYPE_COLLECTIONS_OPERATIONS]);
            this._elementWriter.setValueListeByIDValues(element, CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION, [2]);
        }
        return element;
    }

    public createElement(elementConfig: CaracConfig[] = [], isCollection = true): Observable<NPElement> {
        const element = this.newElement(elementConfig, isCollection);
        return Observable.of(element);
    }

    concatValueLink(element: NPElement, dicoCaracExtID: string, elementValue: NPElement) {
        this._elementWriter.concatValueLink(element, dicoCaracExtID, elementValue);
    }

    publishToNP(setProductLabel = true) {
        if (setProductLabel) {
            this.setProductLabel(this._operationService.currentOperation());
        }
        this._saving = true;
        // S'il y a des éléments à supprimer, on met leurs status à 1;
        this._changeElementsToRemoveStatus();
        return this._elementWriter.publishToNP()
            .pipe(
                tap(() => {
                    this._saving = false;
                    this.cancelAllModifications();
                })
            );
    }

    removeValueFromLinks(element: NPElement, dicoCaracExtID: string, elementValue: NPElement, deleteFromNP = false) {
        this._elementWriter.deleteValueLink(element, dicoCaracExtID, elementValue);
        // On supprime l'objet de la liste d'élements à vérifier
        this._errorService.remove(elementValue);
        if (deleteFromNP) { // On supprime l'objet de NEXTPAGE
            this.deleteElementFromNP(elementValue);
        }
    }

    private _getGUID() {
        return Guid.create().toString();
    }

    public setValueListeByIDValues(element: NPElement, dicoCaracExtID: string, IDs: number[]) {
        this._elementWriter.setValueListeByIDValues(element, dicoCaracExtID, IDs);
    }

    public hasModifications() {
        return this._elementWriter.hasModifications();
    }

    public cancelAllModifications() {
        return this._elementWriter.cancelAllModifications();
    }

    /**
     * Retourne
     * 0 : s'il n'y a aucune modification à enregistrer
     * -1 : s'il y a des erreurs de saisie (ex: champ obligatoire non renseigner)
     * true: si tout est ok
     */
    canSaveData() {
        return !this._elementWriter.hasModifications() ?
            throwError(0) :
            this._errorService.hasErrors() ?
                throwError(-1) :
                of(true);
    }

    /**
     * Permet d'écrire le ProductLabel de toutes les collections de l'opération en cours...
     */
    setProductLabel(element: NPElement) {
        if (element) {
            element.getValueLien(CogedimDicocarcs.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION).RebuildLinkedElements.map(collection => {
                if (collection.Element.getValueTextValue(DicoCarac.PRODUCT_LABEL) === '') {
                    this._setCollectionName(collection.Element);
                }
                return collection;
            });

        }
    }

    private _setCollectionName(collection: NPElement) {
        const productLabel = `Collection ${this._collectionService.getCollectionType(collection)} de l'opération ${collection.getValueTextValue(CogedimDicocarcs.DC_LIBELLE_COLLECTION)}`;
        this._elementWriter.setValueText(collection, DicoCarac.PRODUCT_LABEL, productLabel);
    }

    setValueText(element: NPElement, dicoCaracExtId: string, value: string) {
        this._elementWriter.setValueText(element, dicoCaracExtId, value);
    }

    /**
     * Supprime un élément de nextPage
     */
    public deleteElementFromNP(element: NPElement) {
        this._elementWriter.deleteElementFromNP(element);
    }

    createMedia(file: File, folderID: number) {
        return this._elementWriter.createMedia(file, folderID);
    }

    public createMediaDirectory() {
        const guid = this._getGUID();
        const element = this._elementWriter.createElement(guid);
        element.ElementType = NPElementType.Media;
        element.ParentExtID = 'WEB_DC_NOTICES_DESCRIPTIVES_FOLDER';
        return element;
    }

    public createElementWithMedia(elementConfig: CaracConfig[] = [], isCollection = true) {
        return this.createElement(elementConfig, isCollection)
            .pipe(
                map(element => {
                    const mediaDirectory = this.createMediaDirectory();
                    this._elementWriter.setValueLink(element, 'LinkMediaDirectory', [mediaDirectory])
                    return element;
                })
            );
    }

    setValueLink(elementParent: NPElement, dicoCaracExtId: string, elementsValues: NPElement[]) {
        this._elementWriter.setValueLink(elementParent, dicoCaracExtId, elementsValues);
    }

    cancelAllModificationsByElement(elementExtId: string, elementID?: number) {
        this._elementWriter.cancelAllModificationsByElement(elementExtId, elementID);
    }

    setNoticeLabel(currentNotice: NPElement) {
        const dateValue = this.getConvertedDate(currentNotice.getValueTextValue(CogedimDicocarcs.DC_DATE_UPLOAD_NOTICE));
        const collectionValue = this._collectionService.getCollectionType(currentNotice);
        this._elementWriter.setValueText(currentNotice, DicoCarac.PRODUCT_LABEL, `${collectionValue} - ${dateValue}`);
    }

    getConvertedDate(dateValue: string) {
        return dateValue && dateValue !== '' ? this._datePipe.transform(dateValue, 'dd/MM/yyyy') : '';
    }

    setHtmlValue(element: NPElement, dicoCaracExtID: string, value: string) {
        this._elementWriter.setValueText(element, dicoCaracExtID, value);
    }

    // Met à jour la liste des modules de construction liés à la collection de l'opérattion en cours
    createBuildingModuleElementByKit(config: CaracConfig[], collectionOfOperation: NPElement, parentID: number) {
        // On récupère les module de construction existants
        let buildingModulesElements: NPElement[] = BuildingModuleHelper.getBuildingModuleElements(collectionOfOperation);
        // On crée des module de construction pour les kits qui n'en ont pas un
        const results: NPElement[] = [];
        BuildingModuleHelper.extractKitsFromCollection(collectionOfOperation).forEach(_currentKit => {
            if (!BuildingModuleHelper.contains(_currentKit, buildingModulesElements)) {
                const newElement = this.newElement(config, false);
                newElement.ParentID = parentID;
                const elementLabel = `${_currentKit.getValueTextValue(DicoCarac.PRODUCT_LABEL)} pour ${collectionOfOperation.getValueTextValue(DicoCarac.PRODUCT_LABEL)}`;
                this.setValueText(newElement, DicoCarac.PRODUCT_LABEL, elementLabel);
                this.setValueText(newElement, CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_NOM_DU_KIT, _currentKit.ExtID);
                this.setValueText(newElement, CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POSE, 'Non');
                this.setValueLink(newElement, CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION, [_currentKit]);
                results.push(newElement);
            }
        });
        // Fusionne les deux listes (modules extistants + nouveaux modules)
        buildingModulesElements = buildingModulesElements.concat(results);
        this.setValueLink(collectionOfOperation, CogedimDicocarcs.LPP_MODULE_CONSTRUCTION, buildingModulesElements);
        return buildingModulesElements;
    }

    setPosedValue(_selectedValue: NPListeValues, element: NPElement) {
        if (_selectedValue && element) {
            this.setValueListeByIDValues(element, CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POSE, [_selectedValue.ValeurID]);
            // Si on sélectionne "Oui", on vide la carac "Pourquoi?" (Si le kit a été sélectionné)
            if (_selectedValue.Valeur === CogedimConstantes.POSED) {
                this.setValueText(element, CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POURQUOI, '');
            }
        }
    }

    setPosedValues(_selectedValue: NPListeValues, elements: NPElement[]) {
        if (_selectedValue && elements) {
            elements.forEach(element => {
                this.setPosedValue(_selectedValue, element);
            });
        }
    }

    removeBuildingModuleElementByKitExtId(kitExtID: string, collection: NPElement) {
        const response = BuildingModuleHelper.removeBuildingModuleByKitExtIdFromCollection(kitExtID, collection);
        if (response && response.removedElement) {
            // On suvégarde l'élément supprimer... Il pourrait être réutilisé lors qu'on recoche le même kit.
            this.addToRemovedElement(response.removedElement);
            // On met à jour la liste des modules existants
            this.setValueLink(collection, CogedimDicocarcs.LPP_MODULE_CONSTRUCTION, response.results);
        }
    }

    private addToRemovedElement(removedElement: NPElement) {
        if (removedElement && !this._removedElements.some(x => x.ExtID === removedElement.ExtID)) {
            this._removedElements.push(removedElement);
        }
    }

    getRemoveElements() {
        return this._removedElements;
    }

    // Vérifie si un module lié au kit existait déjà
    public hasInRemovedBuildingModule(kit: NPElement) {
        let response: NPElement;
        if (kit) {
            response = this._removedElements.find(e => {
                const linked = e.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION);
                return linked && linked.RebuildLinkedElements && linked.RebuildLinkedElements.some(x => x.Element.ExtID === kit.ExtID);
            });
        }
        return response;

    }


    private _changeElementsToRemoveStatus() {
        if (this._removedElements && this._removedElements.length > 0) {
            this._removedElements.forEach(e => {
                this.deleteElementFromNP(e);
            });
            this._removedElements = [];
        }
    }
}
