import {Injectable} from '@angular/core';
import {LogoutComponent} from '../../../cogedim-spe/logout/logout.component';
import {CollectionSelectorComponent} from '../../../cogedim-spe/collection-selector/collection-selector.component';
import {PrestationTableViewComponent} from '../../../cogedim-spe/prestation-table-view/prestation-table-view.component';

@Injectable({
    providedIn: 'root'
})
export class CogedimValueComponentResolverService {

    private _componentMap = {
        CollectionSelectorComponent: CollectionSelectorComponent,
        PrestationsTableViewComponent: PrestationTableViewComponent
    };

    constructor() {
    }

    public getComponent(name: string) {
        return this._componentMap[name] ? this._componentMap[name] : undefined;
    }
}
