import {NPElementType} from './np-element';

export class AdvancedSearchDTO {
  Properties: Properties;
  Filters: Filters;
  DisplayFields: DisplayField[];
  SortFields?: any;
}

export interface DisplayField {
  ID: number;
  Name?: any;
  Unite?: any;
  AdvancedSearchID: number;
  FieldType: number;
  DicoCaracID: number;
  DicoCaracRenderType: string;
  DicoCaracTypeCode?: any;
  Order: number;
  SortAllow: boolean;
  SortEnable: boolean;
  SortDesc: boolean;
}

export interface Filters {
  ElementType: NPElementType;
  CreatorID?: number;
  CreationDate?: string;
  CreationDateOperator?: string;
  ModificationDate?: string;
  ModificationDateOperator?: string;
  WFStatusID?: number;
  WFStatusOperator?: string;
  Family?: Family;
  ProductsTypeID?: number;
  Keywords?: string;
  Chars?: Chars;
}

export interface Chars {
  LogicalOperator: string;
  Items: Item[];
}

export interface Item {
  CriteriaID: number;
  DicoCaracID: number;
  OperatorValue: string;
  Value: string;
  IsSearchUI: boolean;
  SelectedDico: SelectedDico;
  Items?: any;
}

export interface SelectedDico {
  ID: number;
  Order: number;
  Rank: number;
  ExtID: string;
  Required: boolean;
  Editable: boolean;
  Translatable: boolean;
  TypeCode: string;
  TypeCarac: string;
  TypeCodeLong: string;
  DossierDicoCaracID: number;
  LibelleDossier: string;
  OrdreDossier: number;
  Name: string;
  SecondaryName: string;
  PublicationName: string;
  Groupe: string;
  Description: string;
  CalculationFormula?: any;
  Unite: string;
  MaxLength: number;
  MaxValue?: any;
  MinValue?: any;
  PathFileSystem: string;
  ValeurListID?: any;
  ReflexiveCharID?: any;
  DicoCaracIDCopyDataFrom?: any;
  TargetCharID?: any;
  SourceCharID?: any;
  idElementLien: number;
  ElementLink?: any;
  ElType: number;
  NbUsed: number;
  Destination: string;
  Cardinality?: number;
  Value: string;
  Access: number;
  FillRateIDs: number[];
  FillRateNames?: any;
  FillRates?: any;
}

export interface Family {
  ID?: number;
  label?: string;
}

export interface Properties {
  ID?: number;
  Name: string;
  State?: string;
  CreatorID?: number;
  CreationDate?: string;
  ModifierID?: number;
  ModificationDate?: string;
  ExtID?: string;
  AdvanceSearchType: number;
}
