import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavBarService} from '../../sdk/data/services/nav-bar.service';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {OperationService} from '../../sdk/data/services/operation.service';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, OnDestroy {

    public config: CaracConfig[] = [];

    operation: NPElement;
    private subOperation: Subscription;
    private subNavBarItem: Subscription;

    constructor(private _navBarService: NavBarService,
                private _operationService: OperationService,
                private _breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this._listenSelectedMenu();
        this._getOperation();
    }

    private _getOperation() {
        this._unsubscribeOperation();
        this.subOperation = this._operationService.getCurrentOperation()
            .subscribe(operation => {
                this.operation = operation;
            });
    }

    /**
     * Récupère la config du menu sélection dans la barre de navigation
     * et change le contenu du container  en fonction celle-ci
     */
    private _listenSelectedMenu() {
        this._unsubscribeNavBarItem();
        this.subNavBarItem = this._navBarService.getCurrentItem()
            .subscribe(selectedMenuItem => {
                if (selectedMenuItem) {
                    this.config = selectedMenuItem.caracConfig
                    && selectedMenuItem.caracConfig.ConfigurationObject
                        ? selectedMenuItem.caracConfig.ConfigurationObject : [];
                    this._initBreadcrumb(selectedMenuItem.label);
                } else {
                    this.config = [];
                }
            });
    }

    private _initBreadcrumb(name: string) {
        const breadcrumb = [{name: name, route: '', params: {}}];
        this._breadcrumbService.init(breadcrumb);
    }

    private _unsubscribeNavBarItem() {
        if (this.subNavBarItem) {
            this.subNavBarItem.unsubscribe();
        }
    }

    private _unsubscribeOperation() {
        if (this.subOperation) {
            this.subOperation.unsubscribe();
        }
    }

    ngOnDestroy() {
        this._unsubscribeNavBarItem();
        this._unsubscribeOperation();
    }
}
