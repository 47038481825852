import {NgModule} from '@angular/core';
import {GraphicsModule} from './graphics/graphics.module';
import {FileDropModule} from './directive/file-drop/file-drop.module';
import {DragAndDropModule} from './directive/drag-and-drop/drag-and-drop.module';
import {UiTranslateModule} from './directive/ui-translate/ui-translate.module';
import {FileReaderService} from './services';
import {ReplaceTagModule} from './directive/replace-tag/replace-tag.module';


import jss from 'jss';
import preset from 'jss-preset-default';
import {MatNativeDateModule} from '@angular/material';

jss.setup(preset());

@NgModule({
    imports: [MatNativeDateModule],
    exports: [GraphicsModule,
        FileDropModule,
        DragAndDropModule,
        UiTranslateModule,
        ReplaceTagModule],
    providers: [FileReaderService]
})
export class NpSdkGraphicsModule {
}
