import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private _event$ = new Subject<EventItem>();

    constructor() {
    }

    setEvenByKey(key: string, value: any) {
        this._event$.next(new EventItem(key, value));
    }

    getEvenByKey(key: string) {
        return this._event$.asObservable();
    }
}

export class EventItem {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}
