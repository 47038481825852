import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {CustomFieldItem, NpUser} from '../../Model/np-user';
import {CogedimDicocarcs} from '../../constantes/cogedim-dicocarcs';
import {NPListeValues} from '@nextpage/np-sdk-data/src';

@Injectable()
export class NPUserRepository {

  private readonly _urlGetCurrentUser = '/api/sdk/user/getCurrentUser';

  private _currentUser: NpUser;
  private _currentRegionalDir: string;
  private _currentRegionalDirFields: CustomFieldItem[] = [];

  constructor(private _http: HttpClient) {
  }

  public getCurrentUser(): Observable<NpUser> {
    return this._http.get<Object>(this._urlGetCurrentUser)
      .pipe(
        map((data) => {
          if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              return <NpUser[]>data['Results'].Users;
            }
          }
        }),
        map(users => users && users[0] ? users[0] : null),
        tap(user => {
          this._currentUser = user;
          this._setCurrentRegionalDir();
        })
      );
  }

  getUserRegionalDirect() {
    return this._currentRegionalDir;
  }

  private _setCurrentRegionalDir() {
    const _customField = this._currentUser && this._currentUser.CustomFields ?
      this._currentUser.CustomFields.find(field => field.ExtID === CogedimDicocarcs.DC_DIRECTION_REGIONALE_USER) : null;
    this._currentRegionalDir = _customField && _customField.Values && _customField.Values.length > 0 ? _customField.Values[0].Value : '';

    // Récupérartion des "directions régionales" de l'utilisateur en cours
    this._currentRegionalDirFields = _customField ? _customField.Values : [];
  }

  // Comparaison des directions régionales produits avec les directions régionales de l'utilisateur
  public regionalDirEquals(productRegionalDirectionValues: NPListeValues[]): boolean {
    return this._currentRegionalDirFields
        && productRegionalDirectionValues
        && this._currentRegionalDirFields
            .some(userDirectionRegional => productRegionalDirectionValues
                .some(productRegionalDirection => productRegionalDirection.Valeur === userDirectionRegional.Value));
  }
}
