import {Injectable} from '@angular/core';
import {CaracConfig, ManagementRulesCheckerService, NPElement} from '@nextpage/np-sdk-data';
import {ErrorConfig} from '../Model/models';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    /**
     * Liste des caracs qui seront vérifiées avant l'enregistrment des données dans NextPage
     */
    private _caracsToCheck: ErrorConfig[] = [];
    private _errorsConfigs: ErrorConfig[] = [];

    constructor(private _mgCheckerService: ManagementRulesCheckerService) {
    }

    getErrors() {
        this._caracsToCheck = [];
        this._errorsConfigs.forEach(err => {
            if (!this._mgCheckerService.isValueValide(err.element, err.caracConfig)) {
                this._caracsToCheck.push(err);
            }
        });
        return this._caracsToCheck;
    }

    hasErrors() {
        this._mgCheckerService.startChecking();
        return this.getErrors().length > 0;
    }

    /**
     * caracConfig: carac à ajouter
     * element: ELement qui porte cette carac
     * data : autres données nécessaires à l'affichage de la carac en cas d'erreur (ex: element, page, bloc, rubrique...)
     */
    addCarac(caracConfig: CaracConfig, element: NPElement, data: any = {}) {
        if (!this._exist(caracConfig, element)) {
            this._errorsConfigs.push(new ErrorConfig(caracConfig, element, data));
        } else {
            this._errorsConfigs = this._errorsConfigs.map(value => {
                if (value.caracConfig.DicoCaracExtID === caracConfig.DicoCaracExtID
                    && value.element.ExtID === element.ExtID) {
                    value = new ErrorConfig(caracConfig, element, data);
                }
                return value;
            });
        }
    }

    addCaracs(caracConfigs: CaracConfig[], element: NPElement, data: any = {}) {
        caracConfigs.forEach(cc => {
            this.addCarac(cc, element, data);
        });
    }

    /**
     * Vérifie si la carac existe déjà dans le tableu (pour éviter les doublons)
     * */
    private _exist(caracConfig: CaracConfig, element: NPElement) {
        return this._errorsConfigs.some(err => err.caracConfig.DicoCaracExtID === caracConfig.DicoCaracExtID && err.element.ExtID === element.ExtID);
    }

    cleanErrors() {
        this._errorsConfigs = [];
        this._caracsToCheck = [];
    }

    remove(removedElement: NPElement) {
        this._errorsConfigs = this._errorsConfigs.filter(value => {
            return value.element.ExtID !== removedElement.ExtID;
        })
    }
}
