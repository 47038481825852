import {NgModule} from '@angular/core';

import {NPElementViewComponent} from './np-element-view/np-element-view.component';
import {ValueComponentResolver} from '../services/value-component-resolver';
import {NPValueModule} from './np-value/np-value.module';
import {RouterModule} from '@angular/router';
import {NPVideoModule} from './np-video/np-video.module';
import {NpMediasSelectionComponent} from './np-medias-selection/np-medias-selection.component';
import {FileReaderService} from '../services/file-reader.service';
import {ContentStateControllerService} from '../services/content-state-controller.service';
import {FileDropModule} from '../directive/file-drop/file-drop.module';
import {SharedModule} from '../shared/shared.module';
import {FileReaderWebapiService} from '../services/file-reader-webapi.service';
import {NpCarouselViewComponent} from './np-carousel-view/np-carousel-view.component';
import {NpCarouselComponent} from './np-carousel/np-carousel.component';
import {LinkCaracWriteComponent} from './link-carac-write-1/link-carac-write.component';
import {SelectListValueComponent} from './select-list-value/select-list-value.component';
import {Title2Component} from './title-2/title-2.component';
import {VisualMediaComponent} from './visual-media/visual-media.component';
import {SelectListValueLienComponent} from './select-list-value-lien/select-list-value-lien.component';
import {NpDatepickerComponent} from './np-datepicker/np-datepicker.component';
import {NpSimpleImageViewComponent} from './np-simple-image-view/np-simple-image-view.component';
import {CheckListComponent} from './check-list/check-list.component';
import {CheckListImageComponent} from './check-list-image/check-list-image.component';
import {NpNumberComponent} from './np-number/np-number.component';
import {LinkStyleArrayComponent} from './link-style-array/link-style-array.component';
import {LinkStyleArray2Component} from './link-style-array-2/link-style-array2.component';
import {LinkStyleArraySearchComponent} from './link-style-array/link-style-array-search/link-style-array-search.component';
import {DateAdapter} from '@angular/material';
import {FrenchDateAdapter} from './np-datepicker/FrenchDateAdapter/french-date-adapter';
import {DialogWarningMessageComponent} from './dialog-warning-message/dialog-warning-message.component';
import {NpTreeviewComponent} from './np-treeview/np-treeview.component';
import {LinkRouterComponent} from './link-router/link-router.component';
import {NpTreeviewItemComponent} from './np-treeview-item/np-treeview-item.component';
import {NpContentBlockComponent} from './np-content-block/np-content-block.component';
import {BiocoopVersionComponent} from './biocoop-version/biocoop-version.component';
import {CrossTableComponent} from './cross-table/cross-table.component';
import {NpRepeaterComponent} from './np-repeater/np-repeater.component';
import {NpRepeaterItemComponent} from './np-repeater-item/np-repeater-item.component';
import {NpComboboxComponent} from './np-combobox/np-combobox.component';
import { NpMediaWriterComponent } from './np-media-writer/np-media-writer.component';

@NgModule({
    imports: [
        SharedModule,
        NPValueModule,
        RouterModule,
        FileDropModule
    ],
    exports: [
        NPElementViewComponent,
        NPValueModule,
        NPVideoModule,
        NpCarouselViewComponent,
        NpCarouselComponent,
        LinkCaracWriteComponent,
        SelectListValueComponent,
        SelectListValueLienComponent,
        Title2Component,
        NpSimpleImageViewComponent,
        CheckListComponent,
        CheckListImageComponent,
        NpNumberComponent,
        LinkStyleArrayComponent,
        NpTreeviewComponent,
        NpRepeaterComponent,
        LinkRouterComponent,
        BiocoopVersionComponent,
        CrossTableComponent,
        NpComboboxComponent,
        NpDatepickerComponent
    ],
    declarations: [
        NPElementViewComponent,
        NpMediasSelectionComponent,
        NpCarouselViewComponent,
        NpCarouselComponent,
        LinkCaracWriteComponent,
        SelectListValueComponent,
        Title2Component,
        VisualMediaComponent,
        SelectListValueLienComponent,
        NpSimpleImageViewComponent,
        CheckListComponent,
        NpDatepickerComponent,
        CheckListImageComponent,
        NpNumberComponent,
        LinkStyleArrayComponent,
        LinkStyleArray2Component,
        LinkStyleArraySearchComponent,
        DialogWarningMessageComponent,
        NpTreeviewComponent,
        NpRepeaterComponent,
        LinkRouterComponent,
        NpTreeviewItemComponent,
        NpRepeaterItemComponent,
        NpContentBlockComponent,
        BiocoopVersionComponent,
        CrossTableComponent,
        NpComboboxComponent,
        NpMediaWriterComponent
    ],
    entryComponents: [
        NpMediasSelectionComponent,
        SelectListValueComponent,
        Title2Component,
        SelectListValueLienComponent,
        VisualMediaComponent,
        NpSimpleImageViewComponent,
        CheckListComponent,
        VisualMediaComponent,
        CheckListImageComponent,
        LinkCaracWriteComponent,
        NpDatepickerComponent,
        NpNumberComponent,
        LinkStyleArrayComponent,
        LinkStyleArray2Component,
        DialogWarningMessageComponent,
        NpTreeviewComponent,
        NpRepeaterComponent,
        LinkRouterComponent,
        BiocoopVersionComponent,
        CrossTableComponent,
        NpComboboxComponent,
        NpMediaWriterComponent
    ],
    providers: [
        ValueComponentResolver,
        FileReaderService,
        FileReaderWebapiService,
        ContentStateControllerService,
        {provide: DateAdapter, useClass: FrenchDateAdapter}
    ]
})
export class GraphicsModule {
}
