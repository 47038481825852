import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExportExcelSummary, ItemCaracLie} from '../../Model/models';
import {throwError} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {CaracConfigRepositoryService} from '../carac-config-repository.service';
import {CaracConfig} from '@nextpage/np-sdk-data';
import {EXPORT_OBJECT} from '../../constantes/cogedim-constantes';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
    private _objectToSend: any;
    private _url = '/api/sdk/exportdata/add';
    private _errMessage = `Une erreur s'est produite lors de la génération du fichier de l'export`;
    private _ExcelExportConfigBlockName = 'ExcelExportConfig';
    private _characteristicsBlockName = 'Characteristics';
    private _relatedCharacteristicsBlockName = 'RelatedCharacteristics';

    constructor(private _http: HttpClient,
                private _CaracConfigService: CaracConfigRepositoryService) {

    }

    sendObject(data: ExportExcelSummary) {
        if (data) {
            this._objectToSend = Object.assign({}, EXPORT_OBJECT);
            this._objectToSend.Name = `WEB_${data.fileName}`;
            this._objectToSend.Config.OutFileName = `WEB_${data.fileName}`;
            this._objectToSend.Config.Produits = [data.operationId]; // en fait, il s'agit de la collection (et non opération). Modifier suite au changement de modèle de donnée dans NP
            this._objectToSend.Config.User.Email = data.userEMail;
            /*this._objectToSend.Config.CaracLie = EXPORT_LINKED_CARACS;
            this._objectToSend.Config.DicoCaracteristiques = EXPORT_CARACS;*/

            // Récupération des caracIDs pour initialiser la config de l'export
            return this._CaracConfigService.getConfigsByKeyName(this._ExcelExportConfigBlockName)
                .pipe(
                    flatMap(configs => {
                        this._objectToSend.Config.DicoCaracteristiques = this._getCaracIDs(configs, this._characteristicsBlockName);
                        this._objectToSend.Config.CaracLie = this._getCaracIDs(configs, this._relatedCharacteristicsBlockName)
                            .map((x, index) => new ItemCaracLie(x, index + 1));
                        return this._sendToNP();
                    })
                );
        } else {
            return throwError(this._errMessage);
        }
    }

    private _sendToNP() {
        return this._http.post(this._url, this._objectToSend)
            .pipe(
                catchError(() => throwError(this._errMessage)),
                flatMap((response) => {
                    return response ? of(response) : throwError(this._errMessage);
                })
            );
    }

    private _getCaracIDs(configs: CaracConfig[], blockInfo: string) {
        return configs && blockInfo && blockInfo.trim() !== '' ? configs
            .filter(item => item.BlocInfo === blockInfo && item.DicoCarac)
            .map(caracConfig => caracConfig.DicoCarac.ID) : [];
    }
}


