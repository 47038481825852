import {EventEmitter, Injectable} from '@angular/core';
import {AuthenticationService} from '@nextpage/np-sdk-data';
import {CredentialsService} from './credentials.service';
import {map} from 'rxjs/operators';

export const TOKEN_NAME = 'XXXXXXX@@@@@@èèèèè8888888888__##########"_@';

@Injectable({
    providedIn: 'root'
})
export class CogedimAuthenticationService {

    private _subOverlay$: EventEmitter<boolean> = new EventEmitter();

    constructor(private _credentialsService: CredentialsService,
                private _authService: AuthenticationService) {
        this._subOverlay$.next(false);
    }

    login(username: string, password: string) {
        return this._authService.login(username, password)
            .pipe(
                map((userToken: string) => {
                    this.saveUserToken(userToken);
                    return true;
                })
            );
    }

    logout() {
        return this._credentialsService.logout();
    }

    isConnected() {
        return this._credentialsService.isConnected();
    }

    public initPassword(userMail: string, langID = 2) {
        return this._authService.initPassword(userMail, langID);
    }

    public saveUserToken(userToken) {
        this._credentialsService.initToken(userToken);
    }

    public hasBeenDisconnected() {
      return this._credentialsService.hasBeenDisconnected();
    }
}
