import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ErrorConfig} from '../../../data/Model/models';
import {ErrorService} from '../../../data/services/error.service';
import {CogedimDicocarcs} from '../../../data/constantes/cogedim-dicocarcs';
import {DicoCarac} from '@nextpage/np-sdk-data';

@Component({
    selector: 'app-dialog-errors',
    templateUrl: './dialog-errors.component.html',
    styleUrls: ['./dialog-errors.component.css']
})
export class DialogErrorsComponent implements OnInit {

    public errors: ErrorConfig[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogErrorsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _errorService: ErrorService) {
    }

    ngOnInit() {
        this.errors = this._errorService.getErrors();
        this.errors.map(er => {
            er.label = er.element.getValueTextValue(DicoCarac.PRODUCT_LABEL) !== '' ?
                er.element.getValueTextValue(DicoCarac.PRODUCT_LABEL) :
                er.element.getValueTextValue(CogedimDicocarcs.DC_LIBELLE_COLLECTION);
            return er;
        });
    }

}
