import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit {
    @Input() element: NPElement;
    @Input() dicoCaracs: CaracConfig[] = [];

    constructor(private _ElementRepository: ElementRepositoryService,
        private CaracConfigRepository: CaracConfigRepositoryService) {
    }

    ngOnInit() {
        // this.CaracConfigRepository.getConfigs()
        //     .subscribe(data => {
        //         this.dicoCaracs = data.get('ContenusWeb');
        //         console.log('data', data);
        //         console.log('dicocarac', this.dicoCaracs);
        //     });
        //
        // this._ElementRepository.getElementByExtID('page-web')
        //     .subscribe(data => {
        //         this.element = data;
        //         console.log('element', this.element);
        //         console.log('dataElement', data);
        //     });


    }

    public getValue(ext) {
        // console.log('getValue', this.element.getValueText(ext));
        // return this.element ? this.element.getValueText(ext):'';
    }
}
