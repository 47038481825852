import {CaracConfig, NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {BuildingElementWrapper} from '../Model/models';
import {CogedimConstantes} from '../constantes/cogedim-constantes';

export class BuildingModuleHelper {

    // Vérifi si un kit a déjà été renseigné dans le module de construction
    public static contains(kitToCheck: NPElement, exixtingBuildingModuleElements: NPElement[]) {
        if (kitToCheck && exixtingBuildingModuleElements && exixtingBuildingModuleElements.length > 0) {
            return exixtingBuildingModuleElements.some(_currentElement => {
                const rebuildLinkedElements = _currentElement.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION);
                if (rebuildLinkedElements && rebuildLinkedElements.RebuildLinkedElements && rebuildLinkedElements.RebuildLinkedElements.length > 0) {
                    return rebuildLinkedElements.RebuildLinkedElements.some(x => x.Element.ExtID === kitToCheck.ExtID);
                }
            });
        }
        return false;
    }

    public static extractKitsFromCollection(_currentCollection: NPElement) {
        let _kits: NPElement[] = [];
        if (_currentCollection) {
            const _valueLinks = _currentCollection.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS);
            if (_valueLinks && _valueLinks.RebuildLinkedElements) {
                _kits = _valueLinks.RebuildLinkedElements.map(v => v.Element);
            }
        }
        return _kits;
    }

    public static getBuildingModuleElements(collectionOfOperation: NPElement): NPElement[] {
        // On récupère les module de construction existants
        let buildingModulesElements: NPElement[] = [];
        const buildingModulesValuesLinks = collectionOfOperation.getValueLien(CogedimDicocarcs.LPP_MODULE_CONSTRUCTION);
        if (buildingModulesValuesLinks && buildingModulesValuesLinks.RebuildLinkedElements && buildingModulesValuesLinks.RebuildLinkedElements.length > 0) {
            buildingModulesElements = buildingModulesValuesLinks.RebuildLinkedElements.map(e => e.Element);
        }
        return buildingModulesElements;
    }

    public static getBuildingModuleElementsFormatted(collectionOfOperation: NPElement, posedConfig: CaracConfig): BuildingElementWrapper[] {
        const results: BuildingElementWrapper[] = [];
        // On récupère les module de construction existants
        const buildingModulesElements = BuildingModuleHelper.getBuildingModuleElements(collectionOfOperation);
        buildingModulesElements.forEach(_currentElement => {
            const values = _currentElement.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION);
            if (values && values.RebuildLinkedElements && values.RebuildLinkedElements.length > 0) {
                results.push(new BuildingElementWrapper(values.RebuildLinkedElements[0].Element, _currentElement, posedConfig));
            }
        });
        return results;
    }

    static isNotPosed(element: NPElement, caracConfig: CaracConfig) {
        let response = true;
        if (element && caracConfig && caracConfig.DicoCarac) {
            const valuesFromElement = element.getValueListe(CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POSE);
            const possibleValues = caracConfig.DicoCarac.Values;
            if (possibleValues && valuesFromElement.Values && valuesFromElement.Values.length > 0) {
                response = valuesFromElement.Values.some(x => possibleValues
                    .some(y => y.ValeurID === x.ValueID) && possibleValues.some(y => y.Valeur === CogedimConstantes.NOT_POSED));
            }
        }
        return response;
    }

    public static getValueFromListCaracByValueID(valueID: number, caracConfig: CaracConfig) {
        if (valueID && caracConfig && caracConfig.DicoCarac) {
            return caracConfig.DicoCarac.Values.find(v => v.ValeurID === valueID);
        }
        return null;
    }

    public static getValueFromListCaracByValueElement(element: NPElement, caracConfig: CaracConfig) {
        let response: NPListeValues;
        if (element && caracConfig && caracConfig.DicoCarac) {
            const listValue = element.getValueListe(caracConfig.DicoCaracExtID);
            if (listValue && listValue.Values && listValue.Values.length > 0) {
                response = caracConfig.DicoCarac.Values.find(v => v.ValeurID === listValue.Values[0].ValueID);
            }
        }
        return response;
    }

    public static removeBuildingModuleByKitExtIdFromCollection(kitExtId: string, collection: NPElement) {
        let results: NPElement[] = [];
        let elementToRemove: NPElement;
        if (collection && kitExtId) {
            // On récupère les modules de construction existants
            const existingElements = BuildingModuleHelper.getBuildingModuleElements(collection);
            if (existingElements && existingElements.length > 0) {
                // On recherche le module lié au kit décoché.
                const linkedModule = existingElements.find(e => {
                    const linked = e.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION);
                    return linked && linked.RebuildLinkedElements && linked.RebuildLinkedElements.some(x => x.Element.ExtID === kitExtId);
                });
                // On enlève le module lié au kit décoché.
                if (linkedModule) {
                    elementToRemove = linkedModule;
                    results = existingElements.filter(x => x.ExtID !== elementToRemove.ExtID);
                } else {
                    results = existingElements;
                }
            }
        }
        return {results: results, removedElement: elementToRemove};
    }
}
