import {NpApiResult} from '../../model/np-api-result'
import {DataMocker} from '../../utils/Data/Mocking/data-mocker';

export class NpModelNaos2DicoCarac extends DataMocker {
  /**
   * Le pattern d'url pour les dicoCaracs
   */
  getUrlPattern(): string {
    return '/api/sdk/dicocarac/GetAll/1';
  }

  /**
   * Retourne les objets dicoCarac
   */
  getData(): NpApiResult {
    // le code est sensé être récupéré par la console chrome, onglet netWork
    let data: NpApiResult =
      {
        "Results": {
          "Caracs": [{
            "Rank": 0,
            "ID": 3,
            "ExtID": "DC_MEDIA_METADATA_LastModified",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "DateLastModified",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 47,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 4,
            "ExtID": "DC_MEDIA_METADATA_Size",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Size",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 46,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 5,
            "ExtID": "DC_MEDIA_METADATA_SYSTEM_Subject",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Subject",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 45,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 6,
            "ExtID": "DC_MEDIA_METADATA_SYSTEM_Title",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Title",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 44,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 7,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Artist",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Artist",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 8,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Copyright",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Copyright",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 9,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Image_Description",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Image Description",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Make",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Make",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 11,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Model",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Model",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 12,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Rating",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Rating",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 13,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Windows_XP_Author",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Windows XP Author",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 14,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Windows_XP_Comment",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Windows XP Comment",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 15,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Windows_XP_Keywords",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Windows XP Keywords",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 16,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Windows_XP_Subject",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Windows XP Subject",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 17,
            "ExtID": "DC_MEDIA_METADATA_Exif_IFD0.Windows_XP_Title",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Windows XP Title",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 18,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Contrast",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Contrast",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 20,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 19,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Date/Time_Digitized",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Date/Time Digitized",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 15,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 20,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Date/Time_Original",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Date/Time Original",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 14,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 21,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Exposure_Program",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Exposure Program",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 22,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Flash",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Flash",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 17,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 23,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Focal_Length",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Focal Length",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 19,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 24,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.ISO_Speed_Rating",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "ISO Speed Rating",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 13,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 25,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Saturation",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Saturation",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 21,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 26,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.Sharpness",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Sharpness",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 22,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 27,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.White_Balance",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "White Balance",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 16,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 28,
            "ExtID": "DC_MEDIA_METADATA_Exif_SubIFD.White_Balance_Mode",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "White Balance Mode",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 18,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 29,
            "ExtID": "DC_MEDIA_METADATA_File.File_Modified_Date",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "File Modified Date",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 43,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 30,
            "ExtID": "DC_MEDIA_METADATA_File.File_Name",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "File Name",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 42,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 31,
            "ExtID": "DC_MEDIA_METADATA_File.File_Size",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "File Size",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 41,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 32,
            "ExtID": "DC_MEDIA_METADATA_JFIF.Resolution_Units",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Resolution Units",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 32,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 33,
            "ExtID": "DC_MEDIA_METADATA_JFIF.Thumbnail_Height_Pixels",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Thumbnail Height Pixels",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 36,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 34,
            "ExtID": "DC_MEDIA_METADATA_JFIF.Thumbnail_Width_Pixels",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Thumbnail Width Pixels",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 35,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 35,
            "ExtID": "DC_MEDIA_METADATA_JFIF.Version",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Version",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 31,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 36,
            "ExtID": "DC_MEDIA_METADATA_JFIF.X_Resolution",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "X Resolution",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 33,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 37,
            "ExtID": "DC_MEDIA_METADATA_JFIF.Y_Resolution",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Y Resolution",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 34,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 38,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Components_1",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Components 1",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 28,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 39,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Components_2",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Components 2",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 29,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 40,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Components_3",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Components 3",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 30,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 41,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Compression_Type",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Compression Type",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 23,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 42,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Data_Precision",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Data Precision",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 24,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 43,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Image_Height",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Image Height",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 25,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 44,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Image_Width",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Image Width",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 26,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 45,
            "ExtID": "DC_MEDIA_METADATA_JPEG.Number_of_Components",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Number of Components",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 27,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 46,
            "ExtID": "DC_MEDIA_METADATA_XMP.Create_Date",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Create Date",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 38,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 47,
            "ExtID": "DC_MEDIA_METADATA_XMP.Rating",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Rating",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 39,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 48,
            "ExtID": "DC_MEDIA_METADATA_XMP.Subject",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "Subject",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 40,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 49,
            "ExtID": "DC_MEDIA_METADATA_XMP.XMP_Value_Count",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 2,
            "LibelleDossier": "DAM",
            "Name": "XMP Value Count",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 37,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 51,
            "ExtID": "DC_ALLERGÈNE_DE_LA_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Allergène de la MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 52,
            "ExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 10,
            "LibelleDossier": "Ingrédient",
            "Name": "Argumentaire ingrédients Naos",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 53,
            "ExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Argumentaire technique substances polémiques",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 54,
            "ExtID": "DC_ARGUMENTAIRES_TECHNIQUES_ROV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 6,
            "LibelleDossier": "Ecran R.O.V dédié ?",
            "Name": "Argumentaires techniques R.O.V",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 55,
            "ExtID": "DC_BENEFICES_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Bénéfices produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 56,
            "ExtID": "DC_BREVET_DEPUIS_FGC-BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Brevet depuis FGC-Brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ReflexiveCharExtID": "DC_FGC-BREVET_DEPUIS_BREVET",
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 111,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 57,
            "ExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Brevet depuis Ing-MP facultatif",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 136,
            "ReflexiveCharExtID": "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 58,
            "ExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Brevet depuis Ing-MP obligatoire",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 138,
            "ReflexiveCharExtID": "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 59,
            "ExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Brevets et technologies (Ing-FGC)",
            "SecondaryName": "Brevets et technologies (Ing-FGC)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 60,
            "ExtID": "DC_CATEGORIE_CHIMIQUE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Catégorie chimique",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 61,
            "ExtID": "DC_CATEGORIE_SAP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Catégorie SAP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 62,
            "ExtID": "DC_CERTIFICATION_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Certification MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 63,
            "ExtID": "DC_CHAMPS_POUR_NOM_DE_PRODUIT_EX__CANADA_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 15,
            "LibelleDossier": "traduction",
            "Name": "Champs pour nom de produit ex : canada ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 64,
            "ExtID": "DC_CIBLE__INDICATIONS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Cible / indications",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 65,
            "ExtID": "DC_CIBLE__SUJET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Cible / sujet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 66,
            "ExtID": "DC_CIBLE__UTILISATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Cible / utilisation",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 67,
            "ExtID": "DC_CODE_AC_1",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Code AC 1",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 68,
            "ExtID": "DC_CODE_EAN13",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Code EAN13",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 69,
            "ExtID": "DC_CODE_ESSAI",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Code essai",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 70,
            "ExtID": "DC_CODE_FORMULE_COMPLET_AVEC_VERSIONING",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Code formule (complet avec versioning)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 71,
            "ExtID": "DC_CODE_FORMULE_SANS_VERSIONING",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Code formule (sans versioning)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 72,
            "ExtID": "DC_CODE_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Code MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 73,
            "ExtID": "DC_CODE_SKU",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Code SKU",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 74,
            "ExtID": "DC_CODE_VRAC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Code Vrac",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 75,
            "ExtID": "DC_COMMENTAIRE_ROV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 6,
            "LibelleDossier": "Ecran R.O.V dédié ?",
            "Name": "Commentaire R.O.V",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 76,
            "ExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Commentaire sur le choix de l'origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 77,
            "ExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Commentaire sur le choix du rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 13,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 78,
            "ExtID": "DC_COMPOSES_DE_DEPART",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Composés de départ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 14,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 79,
            "ExtID": "DC_CONSTITUANTS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Constituants",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 15,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 80,
            "ExtID": "DC_COPYWRITING_BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 81,
            "ExtID": "DC_COPYWRITING_DETAIL_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Copywriting détail produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 82,
            "ExtID": "DC_COPYWRITING_DETAIL_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting détail rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 83,
            "ExtID": "DC_COPYWRITING_DISCLAIMER_LISTE_INCI",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 7,
            "LibelleDossier": "FGC / copywriting",
            "Name": "Copywriting disclaimer liste INCI",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 84,
            "ExtID": "DC_COPYWRITING_DISCLAIMER_ORIGINE_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 7,
            "LibelleDossier": "FGC / copywriting",
            "Name": "Copywriting disclaimer origine ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 85,
            "ExtID": "DC_COPYWRITING_NATURE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting nature",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 86,
            "ExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting nature détaillée",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 87,
            "ExtID": "DC_COPYWRITING_NOM_DU_BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting nom du brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 88,
            "ExtID": "DC_COPYWRITING_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Copywriting Origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 89,
            "ExtID": "DC_COPYWRITING_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 7,
            "LibelleDossier": "FGC / copywriting",
            "Name": "Copywriting produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 90,
            "ExtID": "DC_COPYWRITING_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 91,
            "ExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Copywriting statut biodermique ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 92,
            "ExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Copywriting statut biodermique (oui/non)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 16,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 93,
            "ExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Copywriting substance sensible",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 94,
            "ExtID": "DC_COULEUR__ROV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 6,
            "LibelleDossier": "Ecran R.O.V dédié ?",
            "Name": "Couleur  R.O.V",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 95,
            "ExtID": "DC_DATE_PEREMPTION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "DATE",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Date péremption",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 96,
            "ExtID": "DC_DEFINITION_GLOSSAIRE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 9,
            "LibelleDossier": "Fiche pdt Std Glossaire",
            "Name": "Definition glossaire",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 97,
            "ExtID": "DC_DESIGNATION_BREVET__TECHNOLOGIE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Désignation BREVET / TECHNOLOGIE",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 13,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 98,
            "ExtID": "DC_DESIGNATION_COMMERCIALE_SANS_LA_CONTENANCE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 8,
            "LibelleDossier": "FGC / Marketing",
            "Name": "Désignation commerciale (sans la contenance)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 99,
            "ExtID": "DC_DETAIL_NATURE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Détail Nature",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 17,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 100,
            "ExtID": "DC_DETAIL_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Détail Origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 18,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 101,
            "ExtID": "DC_DETAIL_ORIGINE_GEO",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Détail origine Géo",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 19,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 102,
            "ExtID": "DC_DETAIL_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 13,
            "LibelleDossier": "qualification ? Ou tous ? Car étapes de validation puis transmission au copywriting",
            "Name": "Détail Rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 103,
            "ExtID": "DC_EXCEPTION_COULEUR_ROV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 6,
            "LibelleDossier": "Ecran R.O.V dédié ?",
            "Name": "Exception couleur R.O.V",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 104,
            "ExtID": "DC_FAMILLE_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Famille ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 14,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 105,
            "ExtID": "DC_FGC_DEPUIS_FGC-BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis FGC-Brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 112,
            "ReflexiveCharExtID": "DC_FGC-BREVET_DEPUIS_FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 106,
            "ExtID": "DC_FGC_DEPUIS_FORMULE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis Formule",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 115,
            "ReflexiveCharExtID": "DC_FORMULE_DEPUIS_FGC_REV",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 107,
            "ExtID": "DC_FGC_DEPUIS_ING-FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis Ing-FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 122,
            "ReflexiveCharExtID": "DC_ING-FGC_DEPUIS_FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 108,
            "ExtID": "DC_FGC_DEPUIS_PF",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis Produit fini",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 109,
            "ExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis Rôle ordonné",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 186,
            "ReflexiveCharExtID": "DC_ROLE_ORDONNE_DEPUIS_FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 110,
            "ExtID": "DC_FGC_DEPUIS_SOUS-FAMILLE",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC depuis Sous-Famille",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 190,
            "ReflexiveCharExtID": "DC_SOUS-FAMILLE_DEPUIS_FGC",
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 111,
            "ExtID": "DC_FGC-BREVET_DEPUIS_BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC-Brevet depuis Brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 9,
            "ElementLinkExtID": "F_DS_FGC-Brevet",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 112,
            "ExtID": "DC_FGC-BREVET_DEPUIS_FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "FGC-Brevet depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 9,
            "ElementLinkExtID": "F_DS_FGC-Brevet",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "TargetCharExtID": "DC_BREVET_DEPUIS_FGC-BREVET",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 113,
            "ExtID": "DC_FONCTION_COSING",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Fonction Cosing",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 20,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 114,
            "ExtID": "DC_FONCTION_DIP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Fonction DIP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 21,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 115,
            "ExtID": "DC_FORMULE_DEPUIS_FGC_REV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Formule depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 116,
            "ExtID": "DC_FORMULE_DEPUIS_MP-FLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Formule depuis MP-FLE",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 13,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 151,
            "ReflexiveCharExtID": "DC_MP-FLE_DEPUIS_FORMULE",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 117,
            "ExtID": "DC_GAMME",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 14,
            "LibelleDossier": "tous",
            "Name": "Gamme",
            "SecondaryName": "Gamme",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 1,
            "MinValue": null,
            "MaxValue": null,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "1;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 118,
            "ExtID": "DC_GAMME__SAP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 14,
            "LibelleDossier": "tous",
            "Name": "Gamme  SAP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 119,
            "ExtID": "DC_GLOSSAIRE_CONCERNE_INGREDIENT__FGC_ORIGINE_BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 9,
            "LibelleDossier": "Fiche pdt Std Glossaire",
            "Name": "Glossaire concerne (Ingrédient / FGC, Origine, Brevet)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Ingrédient/FGC;Origine;Brevet",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Ingrédient/FGC"}, {
              "ValeurID": 2,
              "Ordre": 2,
              "Valeur": "Origine"
            }, {"ValeurID": 3, "Ordre": 3, "Valeur": "Brevet"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 120,
            "ExtID": "DC_INDICE_DE_MODIFICATION_SAP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Indice de modification SAP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 15,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 121,
            "ExtID": "DC_ING-FGC_DEPUIS_BREVET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-FGC depuis Brevet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 14,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 59,
            "ReflexiveCharExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 122,
            "ExtID": "DC_ING-FGC_DEPUIS_FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-FGC depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 15,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 7,
            "ElementLinkExtID": "F_DS_Ing-FGC",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 123,
            "ExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-FGC depuis Ingrédient",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 16,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 7,
            "ElementLinkExtID": "F_DS_Ing-FGC",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 124,
            "ExtID": "DC_ING-FGC_DEPUIS_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-FGC depuis Origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 17,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 162,
            "ReflexiveCharExtID": "DC_ORIGINE_GENCODE_ING-FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 126,
            "ExtID": "DC_ING-FGC_DEPUIS_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-FGC depuis Rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 18,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 184,
            "ReflexiveCharExtID": "DC_ROLE_GENCODE_ING-FGC",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 127,
            "ExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-MP depuis Ingrédient",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 19,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 4,
            "ElementLinkExtID": "F_DS_Ing-MP",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 128,
            "ExtID": "DC_ING-MP_DEPUIS_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-MP depuis MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 20,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 4,
            "ElementLinkExtID": "F_DS_Ing-MP",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 129,
            "ExtID": "DC_ING-MP_DEPUIS_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-MP depuis Origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 21,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 163,
            "ReflexiveCharExtID": "DC_ORIGINE_GENCODE_ING",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 131,
            "ExtID": "DC_ING-MP_DEPUIS_ROLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ing-MP depuis Rôle",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 22,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 185,
            "ReflexiveCharExtID": "DC_ROLE_GENCODE_ING-MP",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 132,
            "ExtID": "DC_INGREDIENT_ALLERGISANT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Ingrédient allergisant",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Oui;Non",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Oui"}, {"ValeurID": 2, "Ordre": 2, "Valeur": "Non"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 133,
            "ExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ingrédient depuis Ing-FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 23,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 123,
            "ReflexiveCharExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 134,
            "ExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ingrédient depuis Ing-MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 24,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 127,
            "ReflexiveCharExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 135,
            "ExtID": "DC_INGREDIENT_DEPUIS_ROV",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Ingrédient depuis ROV",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 25,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 187,
            "ReflexiveCharExtID": "DC_ROV_DEPUIS_INGREDIENT",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 136,
            "ExtID": "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Ingrédient facultatif de la technologie brevetée (Lien data model)",
            "SecondaryName": "Ingrédient facultatif de la technologie brevetée (Lien data model)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 16,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 137,
            "ExtID": "DC_INGREDIENT_INCI_CONCATENATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Ingrédient INCI (concaténation)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 22,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 138,
            "ExtID": "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Ingrédient obligatoire de la technologie brevetée (Lien Data model)",
            "SecondaryName": "Ingrédient obligatoire de la technologie brevetée (Lien Data model)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 17,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 139,
            "ExtID": "DC_LIEU_DE_RECOLTE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 14,
            "LibelleDossier": "tous",
            "Name": "Lieu de récolte",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 140,
            "ExtID": "DC_LISTE_INGREDIENTS_INCI",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "liste ingrédients INCI",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 23,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 141,
            "ExtID": "DC_LISTE_INGREDIENTS_SITE_DE_MARQUES",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Liste ingrédients Site de marques",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 24,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 142,
            "ExtID": "DC_MARQUE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 14,
            "LibelleDossier": "tous",
            "Name": "Marque",
            "SecondaryName": "Marque",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "1;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 143,
            "ExtID": "DC_MARQUE__GAMME",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Marque / Gamme",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 18,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 144,
            "ExtID": "DC_MENTIONS_LEGALES_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Mentions légales origine",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 145,
            "ExtID": "DC_MODE_DACTION_BIOLOGIQUE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Mode d'action biologique",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 25,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 146,
            "ExtID": "DC_MP_DEPUIS_ING-MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "MP depuis Ing-MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 26,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 128,
            "ReflexiveCharExtID": "DC_ING-MP_DEPUIS_MP",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 147,
            "ExtID": "DC_MP_DEPUIS_MP-FLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "MP depuis MP-FLE",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 27,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 152,
            "ReflexiveCharExtID": "DC_MP-FLE_DEPUIS_MP",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 149,
            "ExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "MP entrant dans une formule active",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 48,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Oui;Non",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Oui"}, {"ValeurID": 2, "Ordre": 2, "Valeur": "Non"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 150,
            "ExtID": "DC_MP_MULTISOURCEE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "MP Multisourcée",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 49,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Oui;Non",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Oui"}, {"ValeurID": 2, "Ordre": 2, "Valeur": "Non"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 151,
            "ExtID": "DC_MP-FLE_DEPUIS_FORMULE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "MP-FLE depuis Formule",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 28,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 6,
            "ElementLinkExtID": "F_DS_MP-FLE",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 152,
            "ExtID": "DC_MP-FLE_DEPUIS_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "MP-FLE depuis MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 29,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 6,
            "ElementLinkExtID": "F_DS_MP-FLE",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 153,
            "ExtID": "DC_NATURE_CATEGORIE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Nature catégorie",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 26,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 154,
            "ExtID": "DC_NATURE_GENCODE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 13,
            "LibelleDossier": "qualification ? Ou tous ? Car étapes de validation puis transmission au copywriting",
            "Name": "Nature Gencode",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 155,
            "ExtID": "DC_NATURE_SOUS_CATEGORIE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Nature sous catégorie",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 27,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 156,
            "ExtID": "DC_NOM_DU_PROJET",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Nom du projet",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 28,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 157,
            "ExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 14,
            "LibelleDossier": "tous",
            "Name": "Nom INCI de chaque ingrédient de la MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 158,
            "ExtID": "DC_NOM_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Nom Produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 19,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 159,
            "ExtID": "DC_NOMBRE_DINGREDIENTS_COPTIS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Nombre d'Ingrédients COPTIS",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 29,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 160,
            "ExtID": "DC_NUMERO_DE_LOT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Numéro de lot",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 20,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 161,
            "ExtID": "DC_ORDRE_DAPPARITION_DES_INGREDIENTS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Ordre d'apparition des ingrédients",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 162,
            "ExtID": "DC_ORIGINE_GENCODE_ING-FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Origine Gencode (Ing-FGC)",
            "SecondaryName": "Origine Gencode (Ing-FGC)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 30,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 163,
            "ExtID": "DC_ORIGINE_GENCODE_ING",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Origine Gencode (Ing-MP)",
            "SecondaryName": "Origine Gencode (Ing-MP)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 30,
            "MinValue": null,
            "MaxValue": null,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 167,
            "ExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Origines déclarées par le FRS",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 31,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 168,
            "ExtID": "DC_PLANTE__NOM_FRANÇAIS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Plante / Nom français",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 32,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 169,
            "ExtID": "DC_PLANTE__NOM_LATIN",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Plante / Nom latin",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 33,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 170,
            "ExtID": "DC_PLANTE__NOM_PLANTE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Plante / Nom plante",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 34,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 173,
            "ExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Plante / Type de végétal",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 35,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 176,
            "ExtID": "DC_POURCENTAGE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Pourcentage",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "%",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 36,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 177,
            "ExtID": "DC_POURCENTAGE_INGREDIENT_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Pourcentage ingrédient MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 178,
            "ExtID": "DC_POURCENTAGE_MP_-_FLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Pourcentage MP - FLE",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 179,
            "ExtID": "DC_PREUVE_DE_L’ALLEGATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 7,
            "LibelleDossier": "FGC / copywriting",
            "Name": "Preuve de l’allégation",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 180,
            "ExtID": "DC_PROCEDE_DE_FABRICATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Procédé de fabrication",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 37,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 181,
            "ExtID": "DC_PF_DEPUIS_FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Produit fini depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 31,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 108,
            "ReflexiveCharExtID": "DC_FGC_DEPUIS_PF",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 182,
            "ExtID": "DC_QUESTIONS_CONSOMMATEURS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Questions consommateurs",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 183,
            "ExtID": "DC_REPONSES_CONSOMMATEURS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Réponses consommateurs",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 184,
            "ExtID": "DC_ROLE_GENCODE_ING-FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Rôle Gencode (Ing-FGC)",
            "SecondaryName": "Rôle Gencode (Ing-FGC)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 38,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 185,
            "ExtID": "DC_ROLE_GENCODE_ING-MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Rôle Gencode (Ing-MP)",
            "SecondaryName": "Rôle Gencode (Ing-MP)",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 39,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 186,
            "ExtID": "DC_ROLE_ORDONNE_DEPUIS_FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Rôle ordonné depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 32,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 187,
            "ExtID": "DC_ROV_DEPUIS_INGREDIENT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "STRUCTUREDDATA",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "ROV depuis Ingrédient",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 33,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": 8,
            "ElementLinkExtID": "F_DS_ROV",
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 188,
            "ExtID": "DC_SOURCE_PREUVE_DU_COPYWRITING",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 7,
            "LibelleDossier": "FGC / copywriting",
            "Name": "Source preuve du copywriting",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 189,
            "ExtID": "DC_SOUS_FAMILLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Sous famille",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 21,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 190,
            "ExtID": "DC_SOUS-FAMILLE_DEPUIS_FGC",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Sous-Famille depuis FGC",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 34,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "1;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 191,
            "ExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Statut biodermique ingrédient",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 22,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 192,
            "ExtID": "DC_STATUT_DE_L’ARTICLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Statut de l’article",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 23,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 193,
            "ExtID": "DC_STATUT_DE_LA_FORMULE_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Statut de la formule ",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 24,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 194,
            "ExtID": "DC_STATUT_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Statut MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 40,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 195,
            "ExtID": "DC_SUBSTANCE_ACTIVE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Substance active",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 50,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Oui;Non",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Oui"}, {"ValeurID": 2, "Ordre": 2, "Valeur": "Non"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 196,
            "ExtID": "DC_TAG",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 9,
            "LibelleDossier": "Fiche pdt Std Glossaire",
            "Name": "Tag",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 197,
            "ExtID": "DC_TEST_DE_TOLERANCE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "test de tolérance",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 41,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 198,
            "ExtID": "DC_TEST_DEFFICACITE_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "test d'efficacité produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 42,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 199,
            "ExtID": "DC_TITRE_GLOSSAIRE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 9,
            "LibelleDossier": "Fiche pdt Std Glossaire",
            "Name": "Titre Glossaire",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 200,
            "ExtID": "DC_TYPE_DE_CERTIFICATION_MP",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "type de certification MP",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 43,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 201,
            "ExtID": "DC_TYPE_DE_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Type de produit",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 202,
            "ExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Type de produit / MP = fonction technique interne",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 44,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 203,
            "ExtID": "DC_TYPE_DE_TECHNOLOGIE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "Type de technologie",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 25,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 204,
            "ExtID": "DC_URL_SITE_MARQUE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "URL site marque",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 26,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 205,
            "ExtID": "DC_VEGETAL_CONCATENATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Végétal (concaténation)",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 45,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 206,
            "ExtID": "DC_ZONE_DE_COMMERCIALISATION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 8,
            "LibelleDossier": "FGC / Marketing",
            "Name": "Zone de commercialisation",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 207,
            "ExtID": "DC_BAT__BAG",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 4,
            "LibelleDossier": "BDD",
            "Name": "BAT / BAG",
            "SecondaryName": "BAT / BAG",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 27,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 208,
            "ExtID": "DC_IMAGE_TYPE_PRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Image type produit",
            "SecondaryName": "Image type produit",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 209,
            "ExtID": "DC_PHOTOS",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 16,
            "LibelleDossier": "Ingrédient, Brevet, PF",
            "Name": "Photos",
            "SecondaryName": "Photos",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 210,
            "ExtID": "DC_PICTOS_ORIGINE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "Picto(s) origine",
            "SecondaryName": "Picto(s) origine",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 211,
            "ExtID": "DC_PICTOS_SOUS-FAMILLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Picto(s) sous-famille",
            "SecondaryName": "Picto(s) sous-famille",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 212,
            "ExtID": "DC_QR_CODE_",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 5,
            "LibelleDossier": "copywriting",
            "Name": "QR code ",
            "SecondaryName": "QR code ",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 213,
            "ExtID": "DC_VIDEO",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 16,
            "LibelleDossier": "Ingrédient, Brevet, PF",
            "Name": "Vidéo",
            "SecondaryName": "Vidéo",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 2,
            "MinValue": null,
            "MaxValue": null,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "202;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 214,
            "ExtID": "FamilyFillRate_GLOBAL",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Taux de complétude Global",
            "SecondaryName": "Global",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": "result = this.FillRate(1);",
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 215,
            "ExtID": "ProductFillRate_GLOBAL",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Taux de complétude Global",
            "SecondaryName": "Global",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": "result = this.FillRate(1);",
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 216,
            "ExtID": "ReferenceFillRate_GLOBAL",
            "Access": 0,
            "TypeCarac": "reference",
            "ElType": 3,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Taux de complétude Global",
            "SecondaryName": "Global",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": "result = this.FillRate(1);",
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 217,
            "ExtID": "ChannelNodeFillRate_GLOBAL",
            "Access": 0,
            "TypeCarac": "noeudcanal",
            "ElType": 103,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Taux de complétude Global",
            "SecondaryName": "Global",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": "result = this.FillRate(1);",
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 218,
            "ExtID": "MediaFillRate_GLOBAL",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "NUM",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Taux de complétude Global",
            "SecondaryName": "Global",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": "result = this.FillRate(1);",
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 219,
            "ExtID": "DC_ORIGINE_GEOGRAPHIQUE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "CHOIX",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Origine géographique",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 46,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Africa;America;Asia;America;Europe;Oceania",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 220,
            "ExtID": "DC_PLANTE__PARTIES_UTILISEES",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 12,
            "LibelleDossier": "qualification",
            "Name": "Plante / Parties utilisées",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "1",
            "Order": 47,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 221,
            "ExtID": "DC_Famille_Role_Description",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Description",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 223,
            "ExtID": "DC_INGREDIENT_PRIORITAIRE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 3,
            "LibelleDossier": "0",
            "Name": "Ingrédient priotaire",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "1",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 224,
            "ExtID": "DC_PF_DEPUIS_FLE",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENPP",
            "LangCode": "fr",
            "DossierDicoCaracID": 11,
            "LibelleDossier": "LIEN",
            "Name": "Produit fini depuis Formule",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 35,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "1",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 225,
            "ExtID": "DC_FLE_DEPUIS_PF",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Formule depuis Produit Fini",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 224,
            "ReflexiveCharExtID": "DC_PF_DEPUIS_FLE",
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 226,
            "ExtID": "DC_JSON_COMPOSITION",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "Json composition",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 227,
            "ExtID": "DC_FGC_INGREDIENTCONFORMEEMBALAGEPRODUIT",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "CHOIX",
            "LangCode": "fr",
            "DossierDicoCaracID": 1,
            "LibelleDossier": "Defaut",
            "Name": "ingrédient conforme ?",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 9,
            "MinValue": null,
            "MaxValue": null,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": "",
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000001,
            "ExtID": "##FamilyLabel",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "FamilyLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 1,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000002,
            "ExtID": "##ProductLabel",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "ProductLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 2,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000003,
            "ExtID": "##ReferenceLabel",
            "Access": 0,
            "TypeCarac": "reference",
            "ElType": 3,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "ReferenceLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "0",
            "Order": 3,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "1"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000004,
            "ExtID": "BranchingFamilyLinkSrcElement",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] Element source",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 14,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "1",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": 1
          }, {
            "Rank": 0,
            "ID": 10000005,
            "ExtID": "BranchingFamilyReflexifLinkSrcElement",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] elements liés",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 17,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 10000004,
            "ReflexiveCharExtID": "BranchingFamilyLinkSrcElement",
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000006,
            "ExtID": "BranchingPrefixExtID",
            "Access": 0,
            "TypeCarac": "famille",
            "ElType": 1,
            "TypeCode": "TXT",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] Prefix ExtID",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 20,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000007,
            "ExtID": "BranchingProductLinkSrcElement",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] Element source",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 15,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "2",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": 1
          }, {
            "Rank": 0,
            "ID": 10000008,
            "ExtID": "BranchingProductReflexifLinkSrcElement",
            "Access": 0,
            "TypeCarac": "produit",
            "ElType": 2,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] elements liés",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 18,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 10000007,
            "ReflexiveCharExtID": "BranchingProductLinkSrcElement",
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000009,
            "ExtID": "BranchingReferenceLinkSrcElement",
            "Access": 0,
            "TypeCarac": "reference",
            "ElType": 3,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] Element source",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 16,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "3",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": 1
          }, {
            "Rank": 0,
            "ID": 10000010,
            "ExtID": "BranchingReferenceReflexifLinkSrcElement",
            "Access": 0,
            "TypeCarac": "reference",
            "ElType": 3,
            "TypeCode": "LIENREFLEXIF",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "[Branche] elements liés",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 19,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": 10000010,
            "ReflexiveCharExtID": "BranchingReferenceReflexifLinkSrcElement",
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000050,
            "ExtID": "##NotificationLabel",
            "Access": 0,
            "TypeCarac": "notification",
            "ElType": 50,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "NotificationLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 5,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000051,
            "ExtID": "NotificationLevel",
            "Access": 0,
            "TypeCarac": "notification",
            "ElType": 50,
            "TypeCode": "LISTE",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "NotificationLevel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 6,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "Information;Avertissement;Critique;Refus",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [{"ValeurID": 1, "Ordre": 1, "Valeur": "Information"}, {
              "ValeurID": 2,
              "Ordre": 2,
              "Valeur": "Avertissement"
            }, {"ValeurID": 3, "Ordre": 3, "Valeur": "Critique"}, {"ValeurID": 4, "Ordre": 4, "Valeur": "Refus"}],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000052,
            "ExtID": "NotificationLevelComment",
            "Access": 0,
            "TypeCarac": "notification",
            "ElType": 50,
            "TypeCode": "TXTLONG",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "NotificationLevelComment",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 7,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000101,
            "ExtID": "##ChannelDirectoryLabel",
            "Access": 0,
            "TypeCarac": "dossiercanal",
            "ElType": 101,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "ChannelDirectoryLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 4,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000102,
            "ExtID": "##ChannelLabel",
            "Access": 0,
            "TypeCarac": "canal",
            "ElType": 102,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "ChannelLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 8,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000103,
            "ExtID": "##ChannelNodeLabel",
            "Access": 0,
            "TypeCarac": "noeudcanal",
            "ElType": 103,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "ChannelNodeLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "1",
            "Order": 9,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000111,
            "ExtID": "##LinkNodeFPR",
            "Access": 0,
            "TypeCarac": "noeudcanal",
            "ElType": 103,
            "TypeCode": "LIEN",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "LinkNodeFPR",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "1",
            "Translatable": "0",
            "Order": 10,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": "1;2;",
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": 1
          }, {
            "Rank": 0,
            "ID": 10000201,
            "ExtID": "##MediaDirectoryLabel",
            "Access": 0,
            "TypeCarac": "dossiermedia",
            "ElType": 201,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "MediaDirectoryLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "0",
            "Order": 11,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000202,
            "ExtID": "##MediaLabel",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "SYSLABEL",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "MediaLabel",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "1",
            "Editable": "1",
            "Translatable": "0",
            "Order": 12,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }, {
            "Rank": 0,
            "ID": 10000211,
            "ExtID": "##MediaFile",
            "Access": 0,
            "TypeCarac": "media",
            "ElType": 202,
            "TypeCode": "FICH",
            "LangCode": "fr",
            "DossierDicoCaracID": 10000000,
            "LibelleDossier": "SYSTEM",
            "Name": "MediaFile",
            "SecondaryName": "",
            "PublicationName": "",
            "Unite": "",
            "Required": "0",
            "Editable": "0",
            "Translatable": "0",
            "Order": 13,
            "MinValue": 0,
            "MaxValue": 0,
            "MaxLength": 0,
            "Value": "",
            "Description": "",
            "Destination": null,
            "CalculationFormula": null,
            "FillRates": [{"ExtID": "FRT_GLOBAL", "Selected": "0"}],
            "Values": [],
            "ElementLinkID": null,
            "ElementLinkExtID": null,
            "ReflexiveCharID": null,
            "ReflexiveCharExtID": null,
            "PathFileSystem": null,
            "Cardinality": null
          }]
        }, "Warnings": [], "Errors": []
      }
    ;
    return data;
    //return super.BuildAPIResult(data);
  }


}
