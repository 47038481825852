import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {NpApiResult} from '../model/np-api-result';
import {Observable} from 'rxjs/Observable';

interface Translation {
  Key: string;
  Value: string;
}

@Injectable({
  providedIn: 'root',
})
export class UiTranslationService {

  private _translation: { Key: string, Value: string }[] = [];
  private _translationMap: Map<string, string> = new Map<string, string>();

  private _urlGet = '/api/sdk/uitranslation/GetCurrentTranslation/';
  private _defaultUiLangCode = 'fr';
  private _uiLangCode: string = this._defaultUiLangCode;
  private _allowLangCode: string[] = ['fr', 'en'];
  private _data$;

  constructor(private _http: HttpClient) {
    this.initData();
  }

  public test() {
    const toto = new Observable<string>();
    toto.pipe(
      map(s => {
        return s;
      }
    ));
  }

  /**
   * Méthode de définition de la nouvelle langue.
   * Elle réinitialise par conséquent la liste/map de traductions
   */
  public setLangCode(lgCode: string) {
    if (this._allowLangCode.indexOf(lgCode) === -1) {
      lgCode = this._defaultUiLangCode;
    }
    this._uiLangCode = lgCode;
    this.initData();
  }

  private initData() {
    this._data$ = this._http.get(this._urlGet).pipe(
      map((data: NpApiResult) => {
        if (data.Results != null) {
          return this.jsonAssocArrayToMap(data.Results);
        }
        return null;
      }),
      shareReplay(1)
    );
  }

  /**
   * Méthode de transformation du resultat de la requete en Map
   */
  private jsonAssocArrayToMap(requestResult: Translation[]): Map<string, string> {
    const result = new Map<string, string>();
    requestResult.forEach(el => result.set(el.Key, el.Value));

    return result;
  }

  /**
   *
   */
  public getAll(): Observable<Map<string, string>> {
    return this._data$;
  }

  /**
   * Méthode utilisé par la directive pour récupérer une traduction
   * @dynamic
   */
  public translate(key: string) {
    return this.getAll().pipe(
      map((data: Map<string, string>) => {
      return data.get(key);
    }));
  }

  /**
   * Méthode utilisée dans le code typescript pour récupérer une traduction
   */
  public translateKeys(keys: string[]) {
    return this._data$.pipe(
      map((data: NpApiResult) => {
      this._translation = data.Results;
      const trad = this._translation
        .filter((x) => keys.indexOf(x.Key) > -1)
        .reduce((accumulator, traduction) => {
          accumulator[traduction.Key] = traduction.Value;
          return accumulator;
        }, {});
      return keys.reduce((accumulator, key) => {
        accumulator[key] = (trad[key] != null) ? trad[key] : '[' + key + ']';
        return accumulator;
      }, {});
    }));
  }
}
