export enum DicoCaracExtID {
  DC_BREVET_DEPUIS_FGC_BREVET = 'DC_BREVET_DEPUIS_FGC-BREVET',
  DC_BREVET_DEPUIS_ING_FGC = 'DC_BREVET_DEPUIS_ING_FGC',
  DC_BREVET_DEPUIS_ING_MP_OBLIGATOIRE = 'DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE',
  DC_BREVETS_ET_TECHNOLOGIES_ING_FGC = 'DC_BREVETS_ET_TECHNOLOGIES_ING-FGC',
  //DC_BREVETS_ET_TECHNOLOGIES_ING_FGC = 'DC_BREVETS_ET_TECHNOLOGIES_ING-FGC',
  DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE = 'DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE',
  DC_COPYWRITING_BREVET = 'DC_COPYWRITING_BREVET',
  DC_COPYWRITING_NOM_DU_BREVET = 'DC_COPYWRITING_NOM_DU_BREVET',
  FGC_BREVET = 'DC_FGC-BREVET',
  DC_FGC_BREVET_DEPUIS_BREVET = 'DC_FGC-BREVET_DEPUIS_BREVET',
  DC_FGC_BREVET_DEPUIS_FGC = 'DC_FGC-BREVET_DEPUIS_FGC',
  DC_FGC_DEPUIS_ING_FGC = 'DC_FGC_DEPUIS_ING-FGC',
  DC_FGC_DEPUIS_FGC_BREVET = 'DC_FGC_DEPUIS_FGC-BREVET',
  DC_FGC_DEPUIS_FORMULE = 'DC_FGC_DEPUIS_FORMULE',
  DC_FGC_DEPUIS_PF = 'DC_FGC_DEPUIS_PF',
  DC_FGC_INGREDIENTCONFORMEEMBALAGEPRODUIT = 'DC_FGC_INGREDIENTCONFORMEEMBALAGEPRODUIT',
  DC_FGC_REV_DEPUIS_FORMULE = 'DC_FGC_REV_DEPUIS_FORMULE',
  DC_FORMULE_DEPUIS_FGC = 'DC_FORMULE_DEPUIS_FGC',
  DC_FORMULE_DEPUIS_FGC_REV = 'DC_FORMULE_DEPUIS_FGC_REV',
  DC_GAMME_COMMERCIALE = 'DC_GAMME_COMMERCIALE',
  DC_ING_FGC_DEPUIS_BREVET = 'DC_ING-FGC_DEPUIS_BREVET',
  DC_ING_FGC_DEPUIS_FGC = 'DC_ING-FGC_DEPUIS_FGC',
  DC_ING_FGC_DEPUIS_INGREDIENT = 'DC_ING-FGC_DEPUIS_INGREDIENT',
  DC_ING_FGC_DEPUIS_ROLE = 'DC_ING-FGC_DEPUIS_ROLE',
  DC_ING_FGC_EXCLUDELEXPORT = 'DC_ING-FGC_EXCLUDELEXPORT',
  DC_ING_MP_DEPUIS_INGREDIENT = 'DC_ING-MP_DEPUIS_INGREDIENT',
  DC_ING_MP_DEPUIS_MP = 'DC_ING-MP_DEPUIS_MP',
  DC_INGREDIENT_DEPUIS_ING_FGC = 'DC_INGREDIENT_DEPUIS_ING-FGC',
  DC_INGREDIENT_DEPUIS_ING_MP = 'DC_INGREDIENT_DEPUIS_ING-MP',
  DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL = 'DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL',
  DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL = 'DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL',
  DC_INGREDIENT_PRIORITAIRE = 'DC_INGREDIENT_PRIORITAIRE',
  DC_MP_DEPUIS_ING_MP = 'DC_MP_DEPUIS_ING-MP',
  DC_MP_DEPUIS_MP_FLE = 'DC_MP_DEPUIS_MP-FLE',
  DC_MP_FLE_DEPUIS_FORMULE = 'DC_MP-FLE_DEPUIS_FORMULE',
  DC_PF_DEPUIS_FGC = 'DC_PF_DEPUIS_FGC',
  DC_NOTIFICATIONS = 'DC_NOTIFICATIONS',
  DC_NOTIFICATIONS_ELTS = 'DC_NOTIFICATIONS_ELTS',
  DC_NOTIF_COMMENTAIRE = 'DC_NOTIF_COMMENTAIRE',
  DC_ORIGINE_GENCODE_ING = 'DC_ORIGINE_GENCODE_ING',
  DC_PICTOS_ORIGINE = 'DC_PICTOS_ORIGINE',
  DC_PHOTOS = 'DC_PHOTOS',
  DC_PHOTOS_PACKAGING = 'DC_PHOTOS_PACKAGING',
  DC_POURCENTAGE = 'DC_POURCENTAGE',
  DC_RIGHT_CREATEFGC = 'DC_RIGHT_CREATEFGC',
  DC_ROLE_DEPUIS_INGREDIENT = 'DC_ROLE_DEPUIS_INGREDIENT',
  DC_ROLE_GENCODE_ING_FGC = 'DC_ROLE_GENCODE_ING-FGC',
  DC_ROLE_GENCODE_ING_MP = 'DC_ROLE_GENCODE_ING-MP',
  DC_SUBSTANCE_ACTIVE = 'DC_SUBSTANCE_ACTIVE',
  DC_VIDEO = 'DC_VIDEO',
  DTO_SYSTEM_STATUS = 'DTOSystemStatus',
  DTO_SYSTEM_CHAR_TEMPLATE = 'DTOSystemCharTemplate',
  FAMILY_LABEL = '##FamilyLabel',
  MEDIADIRECTORY_LABEL = '##MediaDirectoryLabel',
  MEDIA_FILE = '##MediaFile',
  MEDIA_LABEL = '##MediaLabel',
  PRODUCT_LABEL = '##ProductLabel',
  NOTIFICATION_LABEL = '##NotificationLabel',
  REFERENCE_LABEL = '##ReferenceLabel'
}

export enum CharTemplateExtID {
  BREVET = 'TP_Brevet',
  ROLE = 'TP_Role',
  FGC_BREVET = 'TP_FGC-Brevet',
  NOTIFICATION_REFUS = 'TP_NOTIFICATION_REFUS',
  ORIGINE = 'TP_Origine'

}

export enum Status {
  VALIDATE = 'Brevet Validé'
}
