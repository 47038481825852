export enum SynopticConstants {

}
export enum SynopticMessages {
    BAD_PARAMETERS = 'Bad query params. Please, enter valid params.',
    GENERATING_SUCCESS = 'Votre synoptique a été générée avec succès.',
    GENERATING_ERROR = 'Erreur lors de la génération du synoptique.',
    DOWNLOADING = 'Génération du synoptique en cours',
    SYNOPTIC_DOWNLOARD = 'Téléchargement du synoptique',
}
