import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CaracConfig, NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {CogedimBlockHelper} from '../../sdk/data/helpers/cogedim-block-helper';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';

@Component({
    selector: 'app-collection-selector',
    templateUrl: './collection-selector.component.html',
    styleUrls: ['./collection-selector.component.scss']
})
export class CollectionSelectorComponent implements OnInit, OnChanges {

    @Input() config: CaracConfig;
    @Input() element: NPElement;

    currElement: NPElement;
    hidden = true;
    private newObjectConfig: CaracConfig[] = [];
    private _selectedCollection: NPListeValues;
    public collections: NPElement[] = [];

    constructor(private _collectionService: CollectionService,
                private _caracConfigRepository: CaracConfigRepositoryService,
                private _elementWriter: CogedimElementWriterService) {
    }

    ngOnInit() {
        this._initData();
    }

    private _updateCollections() {
        const tmpCollection = this.element.getValueLien(CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION);
        this.collections = tmpCollection && tmpCollection.RebuildLinkedElements ? tmpCollection.RebuildLinkedElements.map(rElement => rElement.Element) : [];
        this._collectionService.sendCollections(this.collections);
    }

    private _initData() {
        // On récupère les collections de l'opération en cours...
        this._updateCollections();

        // Configuration si nouvelle collection à créer
        this._caracConfigRepository.getConfigsByKeyName(CogedimConstantes.NewCollectionConfig)
            .subscribe(data => {
                this.newObjectConfig = data;
            });

        this.currElement = this.element;
    }

    ngOnChanges() {

    }

    getCISV(caracConfig: CaracConfig): CaracIdStateValue {
        return CogedimBlockHelper.getValue(caracConfig, this.element);
    }

    public addObject() {
        if (this._selectedCollection) {
            this.hidden = true;
            this._elementWriter.createElement(this.newObjectConfig)
                .subscribe(newCollection => {
                    this._elementWriter.setValueListeByIDValues(newCollection, CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION, [this._selectedCollection.ValeurID]);
                    this._elementWriter.concatValueLink(this.element, CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION, newCollection);
                    this._updateCollections();
                });
        } else {
            this.hidden = false;
        }
        this._autoHidden();
    }

    onSelected(Selected: NPListeValues) {
        this._selectedCollection = Selected;
    }

    private _autoHidden() {
        if (!this.hidden) {
            setTimeout(() => {
                this.hidden = true;
            }, 3000);
        }
    }
}
