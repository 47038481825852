import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {FileReaderService} from '@nextpage/np-sdk-graphics';
import {ImageOverlayComponent} from '../../sdk/graphics/components/image-overlay/image-overlay.component';
import {MatDialog} from '@angular/material';
import {ImageObject} from '../../sdk/data/Model/models';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {timer} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Component({
    selector: 'app-graphic-image-view-v2',
    templateUrl: './graphic-image-view.component.html',
    styleUrls: ['./graphic-image-view.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GraphicImageViewComponent implements OnInit {
    @Input() config: CaracConfig;
    @Input() element: NPElement;
    slideConfig = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: false,
        draggable: true
    };
    photosUrl: ImageObject[] = [];

    constructor(private _fileReader: FileReaderService,
                public _dialog: MatDialog) {
    }

    ngOnInit() {
        /**
         * Permet de rafraîchir l'affichage des images
         */
        timer(1000, 1000)
            .pipe(
                filter(value => value < 4),
                tap(() => {
                    this._initData();
                })
            )
            .subscribe(() => {
            });
    }

    private _initData() {
        this.photosUrl = [];
        if (this.config && this.element && this.element.getValueLien(this.config.DicoCaracExtID)) {
            this.element.getValueLien(this.config.DicoCaracExtID).RebuildLinkedElements.forEach(link => {
                const filePath = this._fileReader.toUrlThumbImage(link.Element);
                const filePathExploded = filePath.replace(new RegExp(/\\/i, 'g'), '/').split('/');
                const fileName = filePathExploded[filePathExploded.length - 1];
                this.photosUrl.push({
                    src: filePath,
                    thumbSrc: this._fileReader.toUrlThumbImage(link.Element),
                    originalSrc: this._fileReader.toUrlMedia(link.Element),
                    alt: fileName
                });
            });
        }
    }

    openImage(image: ImageObject) {
        this._dialog.open(ImageOverlayComponent, {
            data: {image: image, legend: this.element.getValueTextValue(CogedimDicocarcs.DC_MARQUE_AUTRES)}
        });
    }
}
