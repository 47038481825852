import {Injectable} from '@angular/core';
import html2pdf from 'html2pdf.js';
import {from} from 'rxjs';

// DOCUMENT ONLINE : https://www.npmjs.com/package/html2pdf.js/v/0.9.0
@Injectable({
    providedIn: 'root'
})
export class PdfService {

    constructor() {
    }

    public downloadAsPDF(documentID: string, outputFileName: string) {
        const _htmlElement = document.getElementById(documentID);
        const _options = {
            margin: [1, 1, 1, 1],
            filename: `${outputFileName}.pdf`,
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 20, useCORS: true, allowTaint: true},
            jsPDF: {unit: 'in', orientation: 'l'},
            pagebreak: {mode: 'avoid-all', before: '#page2el'}
        };
        return from(html2pdf().from(_htmlElement).set(_options).save());
    }
}
