import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {NPElement} from '@nextpage/np-sdk-data';
import {COGIDIM_ROUTES} from '../../sdk/data/constantes/routes';
import {OperationService} from '../../sdk/data/services/operation.service';
import {Router} from '@angular/router';
import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {ResultItemSummary} from '../../sdk/data/Model/models';

@Component({
    selector: 'app-result-item',
    templateUrl: './result-item.component.html',
    styleUrls: ['./result-item.component.scss']
})
export class ResultItemComponent implements OnInit, OnChanges {

    @Input() operation: NPElement;

    public resultItemSummary: ResultItemSummary;

    constructor(private _operationService: OperationService,
                private _route: Router,
                private _ElementRepository: ElementRepositoryService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.resultItemSummary = new ResultItemSummary(this.operation);
    }

    openOperation() {
        /**
         * Etant donné que l'opération issue de la recherche ne contient pas de links
         * Nous exécutons une requête pour obtenir une opération avec tous les liens nécessaires
         */
        if (this.operation) {
            this._ElementRepository.getElementByExtID(this.operation.ExtID)
                .subscribe(operation => {
                    // Met à jour l'opération en cours en écransant l'ancienne
                    this._operationService.setCurrentOperation(operation);
                    return this._route.navigate([`${COGIDIM_ROUTES.Operation.route}`], {
                        queryParams: {operationExtID: this.operation.ExtID || ''},
                        queryParamsHandling: 'merge'
                    });
                });
        }
        return;
    }
}
