import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FileReaderService} from '@nextpage/np-sdk-graphics';
import {throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {DownloadHelper} from '../helpers/download-helpet';
import {Synoptic} from '../Model/synoptic-model';
import {ElementRepositoryService} from './element-repository.service';
import {FILE_DATA} from '../constantes/testFile';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class SynopticDownloadService {

  constructor(private _elementRepository: ElementRepositoryService,
              private _fileReader: FileReaderService,
              private _http: HttpClient) {
  }


  public download(synoptic: Synoptic) {
    if (synoptic.childToDownload && synoptic.childToDownload && synoptic.fileName) {
      const synopticUrl = this._fileReader.toUrlMedia(synoptic.childToDownload);
      return this._http.get(synopticUrl, {responseType: 'blob'})
          .pipe(
              map(file => {
                DownloadHelper.saveFileAsPdf(file, synoptic.fileName);
                return 'Success!';
              })
          );
    }
    return throwError({
          Messages: 'Error: No media was found. please check that the folder contains a media whose extID starts with WithoutPrice.',
          Success: false,
          Response: ''
        }
    );
  }

  public getTestData() {
    return of(FILE_DATA);
  }
}
