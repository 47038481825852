import {Component, forwardRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {
    AdvancedSearchDTO,
    AdvSearchArgs,
    DicoCarac,
    DicocaracRepository,
    ElementRepository,
    ElementWriterService, NPDicoCarac,
    NPElement, NPElementType,
    SearchRepository, UiTranslationService, WsParamsService
} from '@nextpage/np-sdk-data';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FileReaderService, Sheet, ThemingService} from '../../services';
import {debounceTime, flatMap, map, tap} from 'rxjs/operators';
import jss from 'jss';


/*  * @dynamic  */
@Component({
    selector: 'lib-np-medias-selection',
    templateUrl: './np-medias-selection.component.html',
    styleUrls: ['./np-medias-selection.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpMediasSelectionComponent implements OnInit {
    get currentSubscription(): Subscription {
        return this._currentSubscription;
    }

    set currentSubscription(value: Subscription) {
        if (this._currentSubscription !== undefined) {
            this._currentSubscription.unsubscribe();
        }
        this._currentSubscription = value;
    }

    private _extIDMedia = '';
    private _extIDRootMediaDirectory = '##RootDirectoryMedia';
    public medias: any[] = [];
    public mediasDirectory: any[] = [];
    public previousDirectory: NPElement;
    public currentDirectory: NPElement;
    public parentDirectories: NPElement[] = [];
    public searchText = '';
    public searchTextControl = new FormControl();
    private _formCtrlSub: Subscription;
    public dropzoneHovered = false;
    public messageEnvoi = 'Envoyer';
    public messageChargement = false;
    public filesMap = new Map<string, File>();
    public filesList: File[] = [];
    public messageEmpty = false;
    public directoryName: string;

    public classes: Object;

    private _currentSubscription: Subscription;
    private _translations = new Map<string, string>();

    private static _getToMedia(fileReader: FileReaderService) {
        return function toMedia(element: NPElement) {
            return {
                src: fileReader.toUrlMedia(element),
                thumb: fileReader.toUrlThumbImage(element),
                alt: element.Values.get(DicoCarac.MEDIA_LABEL)['Value'],
                media: element
            };
        };
    }

    private static _toMediaDirectory(element: NPElement) {
        return {
            rootDirectory: element,
            name: element.Values.get(DicoCarac.MEDIADIRECTORY_LABEL)['Value'],
        };
    }

    constructor(private _dicoCaracReader: DicocaracRepository,
                private _elementReader: ElementRepository,
                private _elementWriter: ElementWriterService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _fileReader: FileReaderService,
                private _dialogRef: MatDialogRef<NpMediasSelectionComponent>,
                private _searchRepo: SearchRepository,
                private _wsParam: WsParamsService,
                private _translateSrv: UiTranslationService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit(): void {
        this.messageChargement = false;
        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('NpMediasSelectionComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }
        if (this._formCtrlSub != null) {
            this._formCtrlSub.unsubscribe();
        }
        this._formCtrlSub = this.searchTextControl.valueChanges.pipe(
            debounceTime(300)
        )
            .subscribe((newValue: string) => {
                this.searchText = newValue;
                this._setSearchState();
            });

        let _extIDMedia$ = Observable.of(this._extIDRootMediaDirectory);
        if (this.data['dicoCaracExtID'] != null) {
            _extIDMedia$ = this._dicoCaracReader.getDicoCarac(this.data['dicoCaracExtID']).pipe(
                map((photoCarac: NPDicoCarac) => {
                    if (photoCarac != null && photoCarac.ElementLinkExtID != null) {
                        return photoCarac.ElementLinkExtID;
                    } else {
                        return this._extIDRootMediaDirectory;
                    }
                })
            );
        }

        _extIDMedia$.pipe(
            tap((extIDMedia: string) => {
                this._extIDMedia = extIDMedia;
                this._elementReader.getElements([this._extIDMedia], [[]]).subscribe(element => {
                    this.currentDirectory = element.get(this._extIDMedia);
                });
            }),
            flatMap((extIDMedia: string) => {
                return this._elementReader.getDescendants([extIDMedia], [[]], true);
            })
        )
            .subscribe((elements: Map<string, NPElement>) => {
                this.setState(elements.get(this._extIDMedia));
            });
    }

    public selectMedia(selectedMedia) {
        this._dialogRef.close(selectedMedia.media);
    }
    public closeSelectMedia() {
        this._dialogRef.close();
    }
    public setState(rootDirectory: NPElement) {
        this.searchText = '';
        this.messageChargement = false;
        this.currentDirectory = rootDirectory;
        const parentsCount = this.parentDirectories.length;

        if (parentsCount === 0) {
            this.previousDirectory = null;
        } else {
            this.previousDirectory = this.parentDirectories[parentsCount - 1];
        }

        this.medias = this._getChildrenByType(rootDirectory, NPElementType.Media)
            .map(NpMediasSelectionComponent._getToMedia(this._fileReader));

        this.mediasDirectory = this._getChildrenByType(rootDirectory, NPElementType.MediaDirectory)
            .map(NpMediasSelectionComponent._toMediaDirectory);

        return false;
    }

    private _getChildrenByType(directory: NPElement, type: NPElementType): NPElement[] {
        if (!directory.Children) {
            this.messageChargement = false;
            this.messageEmpty = true;
            this.directoryName = directory.getValue('##MediaDirectoryLabel')['Value'];
        } else {
            this.messageEmpty = false;
        }
        return directory.Children
            .filter((element: NPElement) => {
                return element.ElementType === type;
            });
    }

    private _setSearchState() {
        if (this.searchText.length === 0) {
            this.setState(this.currentDirectory);
        } else {
            this.mediasDirectory = [];
            this.medias = [];
            this.messageChargement = true;
            // this.mediasDirectory = [];
            const conf = new AdvSearchArgs();
            conf.PageSize = 100;
            conf.Config = new AdvancedSearchDTO();

            conf.ContexteID = this._wsParam.getSyncParams().LangID;
            conf.LangID = this._wsParam.getSyncParams().ContextID;

            conf.Config.Filters = {
                ElementType: NPElementType.Media,
                Keywords: this.searchText,
                Family: {
                    ID: this.currentDirectory.ID
                }

            };
            conf.Config.Properties = {
                AdvanceSearchType: 0,
                Name: ''
            };


            this.currentSubscription = this._searchRepo.Search(conf)
                .pipe(
                    tap(() => this.messageChargement = false)
                )
                .subscribe(d => {
                    this.mediasDirectory = [];
                    this.medias = d.elements.map(NpMediasSelectionComponent._getToMedia(this._fileReader));

                });
            return this.currentSubscription;
            // const reducer:  (accumulator: any[],element: NPElement) => any[] = NpMediasSelectionComponent._getSearchAndFlattened(this.searchText);
            // this.mediasDirectory = [];
            // this.medias = this.currentDirectory.Children.reduce(reducer, [])
            //   .map(NpMediasSelectionComponent._getToMedia(this._fileReader));
        }
    }


    /**
     * Gestion du survol sur la zone
     */
    handleHover($event: boolean) {
        this.dropzoneHovered = $event;
    }

    /**
     * Gestion upload fichier depuis la zone
     */
    handleDrop(file: File) {

        // Ajout du fichier dans la liste s'il n'est pas déjà présent
        if (!this.filesMap.has(file.name)) {
            this.filesMap.set(file.name, file);
            this.filesList.push(file);
        }
    }

    public changeDirectory(rootDirectory: NPElement) {
        this.messageChargement = true;
        let parentsCount = this.parentDirectories.length;
        if (parentsCount === 0 || this.parentDirectories[parentsCount - 1].ExtID !== rootDirectory.ExtID) {
            // On est en train de descendre
            this.parentDirectories.push(this.currentDirectory);
        } else {
            // On est en train de monter
            this.parentDirectories.pop();
        }

        parentsCount = this.parentDirectories.length;

        this.currentSubscription = this._getDirectoryContent(rootDirectory).subscribe((content: Map<string, NPElement>) => {
            const parent = parentsCount > 0 ? this.parentDirectories[parentsCount - 1] : null;
            const element = content.get(rootDirectory.ExtID);
            if (parent != null) {
                element.Parent = parent;
                element.ParentExtID = parent.ExtID;
                element.ParentID = parent.ID;
            }
            this.setState(element);
        });
    }

    /**
     * Wrapper pour la récupération des Descendants
     */
    private _getDirectoryContent(directory: NPElement): Observable<Map<string, NPElement>> {
        return this._elementReader.getDescendants([directory.ExtID], [[]], true);
    }

    /**
     * Upload de l'ensemble des fichiers dans la liste
     */
    uploadAllFiles(event: Event) {
        if (event.target['className'] !== 'upload-trigger active') {
            return;
        }

        this.messageEnvoi = '_ScreenEnvoienCours';
        this.filesList.forEach(file => {
            this.uploadFile(file.name);
        });
    }

    /**
     * Suppression d'un fichier de la liste
     */
    removeFile(fileName: string) {
        this.filesMap.delete(fileName);
        this.filesList.splice(this.filesList.indexOf(this.filesMap.get(fileName)), 1);
    }

    /**
     * Upload d'un fichier
     * @param key
     */
    uploadFile(key) {
        this._elementWriter.createMedia(this.filesMap.get(key), this.currentDirectory.ID)
            .subscribe((newMedia: NPElement) => {
                if (newMedia != null) {
                    this.removeFile(key);
                    if (this.filesList.length === 0) {
                        this._dialogRef.close(newMedia);
                    }
                }
            });
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }
}
