import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, flatMap, map, shareReplay} from 'rxjs/operators';

import {Observable} from 'rxjs/Observable';


import {Constantes} from '../const/constantes';
import {DicocaracRepository} from './dicocarac-repository';
import {CaracConfig, NPDicoCarac} from '../model';


@Injectable({
    providedIn: 'root',
})
export class CaracConfigRepository {

    readonly _url = `/filehandler.ashx?t=src&ft=workingfile&cd=inline&p=Screen\\Configuration\\${this._constantes.screenConfig}`;
    private readonly _urlSearchConfig = '/filehandler.ashx?t=src&ft=workingfile&cd=inline&p=Screen\\Configuration\\ConfigSearch.json';
    private readonly _dicoConfigs$;
    private readonly _searchConfig$;

    constructor(private _http: HttpClient, private _dicoCaracRepository: DicocaracRepository,
                private _constantes: Constantes) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this._searchConfig$ = _http.get(this._url, httpOptions).pipe(
            map(result => {
                return result['CreateFGC'];
            }),
            first(),
            shareReplay(1)
        );
        this._dicoConfigs$ = _dicoCaracRepository.getAll().pipe(
            flatMap((dicoCaracs: NPDicoCarac[]) => {
                return _http.get(this._url, httpOptions).pipe(
                    map((object) => {
                        const tmp = new Map<string, CaracConfig[]>();
                        // Pour chaque vue/page on ajoute un enregistrement dans la map
                        for (const property in object) {
                            if (object.hasOwnProperty(property)) {
                                const Config1Ecran = object[property];
                                // reconstruction des données facultatives => la configuration doit toujours ^être formée de la même manière pour éviter les if ailleurs
                                for (const Config1Item of Config1Ecran) {
                                    // par défaut DicoCaracExtIDLabel  = DicoCaracExtID, sauf si DicoCaracExtIDLabel  est renseigné
                                    if (Config1Item.DicoCaracExtIDLabel == null || Config1Item.DicoCaracExtIDLabel == '') {
                                        // écrasement de l'info qui permet d'obtenir l'ExtID
                                        Config1Item.DicoCaracExtIDLabel = Config1Item.DicoCaracExtID;
                                    }
                                    Config1Item.DicoCarac = dicoCaracs.filter(dicoCarac => dicoCarac.ExtID === Config1Item.DicoCaracExtID)[0];
                                    Config1Item.Label = '$$' + Config1Item.DicoCaracExtIDLabel;
                                    Config1Item.Tooltip = 'Unknown characteristic';
                                    const currentDicoCarac = dicoCaracs.filter(dicoCarac => dicoCarac.ExtID === Config1Item.DicoCaracExtIDLabel)[0];
                                    if (currentDicoCarac != undefined) {
                                        // Config1Item.DicoCarac = currentDicoCarac;
                                        Config1Item.Label = currentDicoCarac.Name;
                                        Config1Item.Tooltip = currentDicoCarac.Description;
                                    } else {
                                        // console.log('Pas de DicoCarac pour ', Config1Item.DicoCaracExtIDLabel);
                                    }
                                }
                                tmp.set(property, object[property]);
                            }
                        }
                        return tmp;
                    })
                );
            }),
            first(),
            shareReplay(1)
        );
    }

    /**
     * Retourne un observable sur l'ensemble des listes de config
     */
    getConfigs(): Observable<Map<string, CaracConfig[]>> {
        return this._dicoConfigs$;
    }

    /**
     * Retourne un observable sur la liste de config souhaitée
     */
    getConfig(key: string): Observable<CaracConfig[]> {
        return this._dicoConfigs$.pipe(
            map((data: Map<string, CaracConfig>) => {
                return data.get(key);
            }))
            ;
    }

    public getSearchConfig() {
        return this._searchConfig$;
    }
}
