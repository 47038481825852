import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {CogedimLogoComponent} from './cogedim-logo/cogedim-logo.component';
import {ResultItemComponent} from './result-item/result-item.component';
import {SharedModule} from '../sdk/shared/shared.module';
import {ResultsViewComponent} from './results-view/results-view.component';
import {RouterModule} from '@angular/router';
import {ActionButtonComponent} from './action-button/action-button.component';
import {LogoutComponent} from './logout/logout.component';
import {ContainerComponent} from './container/container.component';
import {BlockComponent} from './block/block.component';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {NavBarItemComponent} from './nav-bar-item/nav-bar-item.component';
import {NpSdkGraphicsModule} from '@nextpage/np-sdk-graphics';
import {CogedimValueViewComponent} from './cogedim-value-view/cogedim-value-view.component';
import {PrestationTableViewComponent} from './prestation-table-view/prestation-table-view.component';
import {CollectionSelectorComponent} from './collection-selector/collection-selector.component';
import {CogedimComboboxComponent} from './cogedim-combobox/cogedim-combobox.component';
import {ContainerEditionComponent} from './container-edition/container-edition.component';
import {OperationTemplateComponent} from './operation-template/operation-template.component';
import {BackToOperationBtnComponent} from './back-to-operation-btn/back-to-operation-btn.component';
import {PrestationSelectorComponent} from './prestation-selector/prestation-selector.component';
import {KitViewComponent} from './kit-view/kit-view.component';
import {MatDialogModule} from '@angular/material/dialog';
import {GraphicImageViewComponent} from './graphic-image-view/graphic-image-view.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {SlickModule} from 'ngx-slick';
import {CogedimSdkGraphicsModule} from '../sdk/graphics/cogedim-sdk-graphics.module';
import {HomeEditionComponent} from './home-edition/home-edition.component';
import {HomeEditionItemComponent} from './home-edition-item/home-edition-item.component';
import {DialogDownloadNoticeComponent} from './dialog-download-notice/dialog-download-notice.component';
import {UploadNoticeItemComponent} from './upload-notice-item/upload-notice-item.component';
import {ExistingNoticesPreviewComponent} from './existing-notices-preview/existing-notices-preview.component';
import {DialogWitnessControlComponent} from './dialog-witness-control/dialog-witness-control.component';
import {ConstructionComboboxComponent} from './dialog-witness-control/construction-combobox/construction-combobox.component';
import {ConstructionInputFieldComponent} from './dialog-witness-control/construction-input-field/construction-input-field.component';
import {DialogSynopticComponent} from './dialog-synoptic/dialog-synoptic.component';
import {DialogSynopticPreloaderComponent} from './dialog-synoptic-preloader/dialog-synoptic-preloader.component';

@NgModule({
    declarations: [
        CogedimLogoComponent,
        ResultItemComponent,
        ResultsViewComponent,
        ActionButtonComponent,
        LogoutComponent,
        ContainerComponent,
        BlockComponent,
        NavBarComponent,
        NavBarItemComponent,
        CogedimValueViewComponent,
        PrestationTableViewComponent,
        CollectionSelectorComponent,
        CogedimComboboxComponent,
        ContainerEditionComponent,
        OperationTemplateComponent,
        BackToOperationBtnComponent,
        PrestationSelectorComponent,
        KitViewComponent,
        GraphicImageViewComponent,
        BreadcrumbComponent,
        HomeEditionComponent,
        HomeEditionItemComponent,
        DialogDownloadNoticeComponent,
        UploadNoticeItemComponent,
        ExistingNoticesPreviewComponent,
        DialogWitnessControlComponent,
        ConstructionComboboxComponent,
        ConstructionInputFieldComponent,
        DialogSynopticComponent,
        DialogSynopticPreloaderComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        NpSdkGraphicsModule,
        MatDialogModule,
        SlickModule.forRoot(),
        CogedimSdkGraphicsModule,
    ],
    exports: [
        CogedimLogoComponent,
        ResultItemComponent,
        ResultsViewComponent,
        ActionButtonComponent,
        LogoutComponent,
        ContainerComponent,
        BlockComponent,
        NavBarComponent,
        NavBarItemComponent,
        CogedimValueViewComponent,
        CollectionSelectorComponent,
        PrestationTableViewComponent,
        CogedimComboboxComponent,
        ContainerEditionComponent,
        OperationTemplateComponent,
        BackToOperationBtnComponent,
        PrestationSelectorComponent,
        KitViewComponent,
        GraphicImageViewComponent,
        BreadcrumbComponent,
        GraphicImageViewComponent,
        SlickModule,
        HomeEditionComponent,
        HomeEditionItemComponent,
        DialogDownloadNoticeComponent,
        UploadNoticeItemComponent,
        ExistingNoticesPreviewComponent,
        DialogWitnessControlComponent,
        DialogSynopticComponent,
        DialogSynopticPreloaderComponent
    ],
    entryComponents: [
        CogedimLogoComponent,
        LogoutComponent,
        CogedimValueViewComponent,
        PrestationTableViewComponent,
        CollectionSelectorComponent,
        GraphicImageViewComponent,
        DialogDownloadNoticeComponent,
        DialogWitnessControlComponent,
        DialogSynopticComponent,
        DialogSynopticPreloaderComponent
    ],
    providers: [DatePipe]
})
export class CogedimSpeModule {
}
