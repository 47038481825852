import {Constantes, DataMocker, WsParamsService} from '@nextpage/np-sdk-data';
import {ThemingService} from '@nextpage/np-sdk-graphics';
import {WsCogedimParamsService} from '../app/sdk/data/services/ws-cogedim-params.service';
import {TOKEN_NAME} from '../app/sdk/data/services/auth/cogedim-authentication.service';
import {ComponentGraphicsStyleConfig} from '../assets/conf/component-graphics-style-config';
import {DataMockerSdkdebugCogedimfullProd} from '../app/sdk/data/Mocking/data-mocker-sdkdebug-cogedimfull-prod';

export const environment = {
    production: true,
    dataMocker: [
        {provide: ThemingService, useClass: ComponentGraphicsStyleConfig},
        {provide: WsParamsService, useClass: WsCogedimParamsService},
        {provide: DataMocker, useClass: DataMockerSdkdebugCogedimfullProd},
        {
            provide: Constantes, useValue: {
                baseUrl: 'https://altarea.nextpage.fr',
                screenConfig: 'CogedimScreen.json',
                token: localStorage.getItem(TOKEN_NAME),
                primaryColor: '#53226B'
            }
        }
    ],
    researchFamilyID: 12998
};
