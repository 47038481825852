import {Injectable} from '@angular/core';
import {BuildingElementWrapper, TableRowItem, TableRowPDF} from '../Model/models';
import {NPElement} from '@nextpage/np-sdk-data';

import {saveAs} from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';

@Injectable({
    providedIn: 'root'
})
export class CogedimFileWriterService {

    private _wordModelPath = '/assets/workingFiles/tag-example.docx';

    constructor() {
    }

    static loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    private _convertElementsToJSON(elements: NPElement[], tableElements: TableRowItem[], documentTitle: string = '', buildingModuleElements: BuildingElementWrapper[]) {

        const values = buildingModuleElements.map(buildingModuleElement => {
            return new TableRowPDF(buildingModuleElement.kit, tableElements, buildingModuleElement);
        });

        // const values = elements.map(_kit => {
        //     return new TableRowPDF(_kit, tableElements);
        // });

        const _keys = Object.getOwnPropertyNames(values[0]);
        const valuesJson = values.map(v => {
            return _keys.reduce((acc, currentValue) => {
                acc[currentValue] = v[currentValue];
                return acc;
            }, {});
        });
        const contentJson = {elements: valuesJson, Title: documentTitle};
        return JSON.parse(JSON.stringify(contentJson));
    }

    generateWordDocument(elements: NPElement[], tableElements: TableRowItem[], documentTitle: string = '', buildingModuleElements: BuildingElementWrapper[]) {
        CogedimFileWriterService.loadFile(this._wordModelPath, (error, content) => {
            if (error) {
                throw error;
            }
            const jsonContent = this._convertElementsToJSON(elements, tableElements, documentTitle, buildingModuleElements);
            const zip = new PizZip(content);
            const wordDocument = new Docxtemplater().loadZip(zip);
            wordDocument.setData(jsonContent);
            try {
                wordDocument.render();
            } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                if (error.properties && error.properties.errors instanceof Array) {
                    const errorMessages = error.properties.errors
                        .map(function (errors) {
                            return errors.properties.explanation;
                        }).join('\n');
                    console.log('errorMessages', errorMessages);
                    // errorMessages is a humanly readable message looking like this :
                    // 'The tag beginning with "foobar" is unopened'
                }
                throw error;
            }
            const _fileToDownload = wordDocument.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }); // Output the document using Data-URI
            saveAs(_fileToDownload, 'output.docx');
        });

    }

}
