import {Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import jss from 'jss';
import {Sheet, ThemingService} from '../../services';
import {
    Access,
    CaracConfig,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracStd
} from '@nextpage/np-sdk-data';
import {DateAdapter} from '@angular/material';

@Component({
    selector: 'lib-np-datepicker',
    templateUrl: './np-datepicker.component.html',
    styleUrls: ['./np-datepicker.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpDatepickerComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;

    @ViewChild('dateField') someInput: ElementRef;

    public classes: Object;
    public dateToPass: any;
    public writeAccess: boolean;
    public isValid = true;
    public invalidDate = false;
    /**
     * Si on veut cacher l"icone du calendrier à coté de la zone de saisie
     * il faut ajouter le champ "hideDatePickerToggle" : true dans la propriété "Specific" dans le JSON
     */
    public hideDatePickerToggle = () => this.caracConfig && this.caracConfig.Specific && this.caracConfig.Specific && this.caracConfig.Specific.hideDatePickerToggle;

    /**
     * Retourne Faux si la taille de l'année saisie dépasse 4 caractères
     * strDate: Date en string et en français
     */
    private static _hasValidYear(strDate: string) {
        const tmpStrDate = strDate.split('/');
        return tmpStrDate && tmpStrDate[2] && tmpStrDate[2].length === 4;
    }

    constructor(private _elementWriter: ElementWriterService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                private _adapter: DateAdapter<any>,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService
    ) {
    }

    /**
     * Permet de convertir la date(format englais yyyy/mm/dd) en français (jj/mm/aaaa) et inversement
     * stringDate: date en englais (yyyy/mm/dd) ou en français
     */
    public static dateParser(StringDate: string) {
        if (!StringDate || StringDate === '') {
            return '';
        }
        const tmpStrDate = StringDate.split('/');
        return `${tmpStrDate[2]}/${tmpStrDate[1]}/${tmpStrDate[0]}`;
    }

    ngOnInit() {
        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('NpDatepickerComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }

        this._adapter.setLocale('fr');
        if (this.value) {
            this.dateToPass = new Date(this.value.Value);
        }

        this.writeAccess = this.caracInfo.authorization === Access.GESTIONNAIRE
            || this.caracInfo.authorization === Access.REDACTEUR;

        this._ManagementRulesChecker.isChecking()
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    public onChange(newValue) {
        const dataValue = this.someInput.nativeElement['value'];
        const englishDate = NpDatepickerComponent.dateParser(new Date(newValue).toLocaleDateString());
        this.invalidDate = false;
        // Si le champ est vide
        if (dataValue === '') {
            // console.log('dataValue: ', dataValue);
            this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, '');
        } else if (englishDate && NpDatepickerComponent._hasValidYear(dataValue)) {
            this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, englishDate);
            this.isValid = true;
            this.invalidDate = false;
            return;
        } else {
            this.invalidDate = true;
        }
        this._initRequiedError();
    }

    /**
     * Interdit la saisie des caractères alphabétiques et spéciaux (sauf "/")
     * event: évenement contenant les infos du caractère entré.
     */
    numberAndNumpadDivideOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        return !(charCode > 31 && (charCode < 47 || charCode > 57));
    }

    /**
     * Retourne la dat( sous forme de chaîne de caractères) dans le cas d'affichage en mode lecture seule
     */
    public getStringDate() {
        return this.value ? NpDatepickerComponent.dateParser(this.value.Value) : '';
    }
}
