import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NavBarService} from '../../sdk/data/services/nav-bar.service';
import {SummaryNavBar} from '../../sdk/data/Model/models';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';

@Component({
    selector: 'app-nav-bar-item',
    templateUrl: './nav-bar-item.component.html',
    styleUrls: ['./nav-bar-item.component.scss']
})
export class NavBarItemComponent implements OnInit {

    @Input() item: SummaryNavBar;

    public selected = new EventEmitter<string>(null);
    public name = '';

    constructor(private _navBarService: NavBarService) {
    }

    ngOnInit() {
        this.name = this.item ? this.item.label : 'Inconnu';
    }

    onClick() {
        // On émet le menu séléctionné
        this._navBarService.setItem(this.item);
        /**
         * Bidouillage !!!!!!!!!!!!!!!!!!!!!! Permet de mettre à jour le DOM(ex: page "Prestation et édition" => champ Select)
         * On attendant la bonne solution
         */
        if (this.item.extId === CogedimConstantes.NAV_BAR_ITEM_PRESTATIONS_EDITION) {
            setTimeout(() => {
                this._navBarService.setItem(this.item);
            }, 0);
        }
    }
}
