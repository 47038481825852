import {Component, OnInit} from '@angular/core';
import {CaracConfigRepositoryService} from './sdk/data/services/carac-config-repository.service';
import {CogedimDicocarcs} from './sdk/data/constantes/cogedim-dicocarcs';
import {OperationService} from './sdk/data/services/operation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public title = 'app-cogedim';
  private _regionalDirOpKey = 'DirectionRegionalOperation';

  constructor(private _configRepository: CaracConfigRepositoryService,
              private _operationService: OperationService) {

    }

    ngOnInit() {
      this._configRepository.getConfig(this._regionalDirOpKey)
        .subscribe(data => {
          const _data = data ? data.find(cc => cc.DicoCaracExtID === CogedimDicocarcs.DC_DIRECTION_REGIONALE_OPERATION) : undefined;
          this._operationService.setRegionalDir(_data && _data.DicoCarac ? _data.DicoCarac.Values : []);

          const _dataProducts = data ? data.find(cc => cc.DicoCaracExtID === CogedimDicocarcs.DC_DIRECTION_REGIONALE_PRODUITS) : undefined;
          this._operationService.setRegionalDirProducts(_data && _dataProducts.DicoCarac ? _dataProducts.DicoCarac.Values : []);
        });
    }
}
