import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {CogedimBlockHelper} from '../../sdk/data/helpers/cogedim-block-helper';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {ErrorService} from '../../sdk/data/services/error.service';
import {OperationService} from '../../sdk/data/services/operation.service';

@Component({
    selector: 'app-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() blockConfig: CaracConfig;
    @Input() operation: NPElement;

    name: string;
    configsRight: CaracConfig[] = [];
    configsLeft: CaracConfig[] = [];

    constructor(private _errorService: ErrorService,
                private _operationService: OperationService) {
    }

    ngOnInit() {
        this._operationService.getCurrentOperation()
            .subscribe(currOperation => {
                // Mise à jour de l'opération en cours(car celle fournie par l'API de recherche ne contient pas toutes propriétés)
                this.operation = currOperation;
            });
    }

    ngOnChanges() {
        this.name = this.blockConfig ? this.blockConfig.Label : 'Inconnu';
        this.configsRight = this.hasData() ? this.getConfig(CogedimConstantes.RIGHT_COLUMN) : [];
        this.configsLeft = this.hasData() ? this.getConfig(CogedimConstantes.LEFT_COLUMN) : [];
        if (this.blockConfig && this.blockConfig.ConfigurationObject && this.operation) {
            this._errorService.addCaracs(this.blockConfig.ConfigurationObject, this.operation, {name: this.name});
        }
    }

    getCISV(caracConfig: CaracConfig): CaracIdStateValue {
        return CogedimBlockHelper.getValue(caracConfig, this.operation);
    }

    public getConfig(columnValue?: string) {
        return columnValue ?
            this.blockConfig.ConfigurationObject.filter(config => config.Specific && config.Specific[CogedimConstantes.COLUMN] === columnValue)
            : this.blockConfig.ConfigurationObject;
    }

    hasData() {
        return this.blockConfig && this.blockConfig.ConfigurationObject;
    }

    isFull() {
        return this.blockConfig &&
            this.blockConfig.Specific && this.blockConfig.Specific[CogedimConstantes.DISPLAY_FIELD] &&
            this.blockConfig.Specific[CogedimConstantes.DISPLAY_FIELD] === CogedimConstantes.DISPLAY_FULL;
    }

    ngAfterViewInit() {
        // if (this.blockConfig && this.blockConfig.ConfigurationObject && this.operation) {
        //     debugger
        //     this._errorService.addCaracs(this.blockConfig.ConfigurationObject, this.operation);
        // }
    }
}
