import {NgModule} from '@angular/core';

import {NpSdkDataModule} from '@nextpage/np-sdk-data';
import {ElementRepositoryService} from './services/element-repository.service';
import {SharedModule} from '../shared/shared.module';
import {NPUserRepository} from './services/auth/user-respository';
import {DatePipe} from '@angular/common';

@NgModule({
declarations: [

],
imports: [
    NpSdkDataModule,
    SharedModule
],
providers: [ElementRepositoryService, NPUserRepository, DatePipe]
})
export class SdkDataModule {
}
