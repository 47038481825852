import {Injectable} from '@angular/core';
import {CaracConfigRepositoryService} from './carac-config-repository.service';
import {ElementRepositoryService} from './element-repository.service';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {FileReaderService} from '@nextpage/np-sdk-graphics';
import {Constantes, DicoCarac, MediaCreationService, NPElement} from '@nextpage/np-sdk-data';
import {UploadNotice} from '../Model/models';
import {CollectionService} from './collection.service';
import {HttpClient} from '@angular/common/http';
import {of, throwError} from 'rxjs';
import {catchError, flatMap, map, tap} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {OperationService} from './operation.service';

const message = 'Votre fichier a été téléchargé avec succès !';

@Injectable({
    providedIn: 'root'
})
export class UploadNoticeService {

    constructor(private _configRepository: CaracConfigRepositoryService,
                private _elementRepository: ElementRepositoryService,
                private _fileReaderService: FileReaderService,
                private _collectionService: CollectionService,
                private _http: HttpClient,
                private constantes: Constantes,
                private _mediaService: MediaCreationService,
                private _operationService: OperationService) {
        this._urlFileHandlerMedia = this.constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${this.constantes.token}&cd=inline&t=original&ft=undefined&p=`;
    }

    private _urlFileHandlerMedia: string;
    private _currentOperation: NPElement;
    private _noFileAvailableMessage = 'Aucun fichier disponible';

    static _saveFile(data: any, fileExtension: string, fileName = 'DownloadedNotice') {
        const blob = new Blob([data], {type: 'application/vnd.ms.docx'});
        const file = new File([blob], fileName + fileExtension, {type: 'application/vnd.ms.docx'});
        saveAs(file);
    }

    static _saveFileAsPdf(data: any, fileExtension: string, fileName = 'DownloadedNotice') {

        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        // const blob = new Blob([data.byteArray], {type: 'application/pdf'});
        const file2 = new File([data], fileName + fileExtension, {type: 'application/pdf'});
        saveAs(file2);
    }

    getExistingNotices() {
        return this._elementRepository.getDescendants(CogedimDicocarcs.F_NOTICE_DESCRIPTIVE_DEFINITIVES,
            [[CogedimDicocarcs.NOTICE_MEDIA_FILE], [CogedimDicocarcs.DC_WEB_OPERATION_DE_NOTICE]], true)
            .pipe(
                flatMap(existingNotices => {
                    return this.initCurrentOperation()
                        .pipe(
                            map(() => {
                                if (existingNotices) {
                                    return existingNotices.filter(existingNotice => this._isOperationNotice(existingNotice));
                                }
                                return [];
                            })
                        );
                })
            );
    }

    rebuildNoticesFromElements(existingNotices: NPElement[]) {
        return existingNotices ? existingNotices.map(currentElement => {
            const tmpUploadNotice = new UploadNotice(currentElement);
            tmpUploadNotice.collection = this.getCollectionName(tmpUploadNotice.element);
            return tmpUploadNotice;
        }) : [];
    }


    addNotice(noticeElements: UploadNotice[], element: NPElement) {
        if (element && noticeElements) {
            element.ParentExtID = CogedimDicocarcs.F_NOTICE_DESCRIPTIVE_DEFINITIVES;
            noticeElements.push(new UploadNotice(element));
        }
    }

    getCollectionName(element: NPElement) {
        if (element) {
            const collectionElement = this._collectionService.getCollectionValue(element, CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION);
            return collectionElement ? collectionElement.Valeur : 'Type de collection inconnue';
        } else {
            return 'Type de collection inconnue';
        }
    }

    private _getFileName(parentElement: NPElement, dicoCaracExtId: string) {
        const fileElement = parentElement.getValueLien(dicoCaracExtId);
        if (fileElement && fileElement.RebuildLinkedElements && fileElement.RebuildLinkedElements.length > 0) {
            return  fileElement.RebuildLinkedElements[0].Element.getValueTextValue(DicoCarac.MEDIA_LABEL);
        }
        return 'Sans-nom';
    }
    downloadFileFromServer(parentElement: NPElement, dicoCaracExtId: string, fileExtension: string) {
        const mediaUrl = this.getFileUrl(parentElement, dicoCaracExtId);
        const fileName = this._getFileName(parentElement, dicoCaracExtId);
        return mediaUrl ?
            this._fetchFile(mediaUrl, fileExtension, fileName)
            : throwError({Errors: [this._noFileAvailableMessage], Success: false});
    }

    private _fetchFile(mediaUrl: string, fileExtension: string, fileName = 'DownloadedNotice') {
        return this._http.get(mediaUrl, {responseType: 'blob'})
            .pipe(
                catchError(err => of(err)),
                tap(response => {
                    UploadNoticeService._saveFile(response, fileExtension, fileName);
                }),
                map(() => {
                    return {Messages: message, Success: true};
                })
            );
    }

    // Non fonctionnel, à revoir!
    public downloadPdf(_formData: FormData) {
        return this._http.post('https://localhost:44347/api/sdk/GeneratePDF', _formData)
            .pipe(
                catchError(err => of(err)),
                tap(response => {
                    UploadNoticeService._saveFileAsPdf(response, '.pdf', 'fileName');
                }),
                map(() => {
                    return {Messages: message, Success: true};
                })
            );

        /* return this._http.get('/api/Import/Product/GeneratePDF', {responseType: 'blob'})
            .pipe(
                catchError(err => of(err)),
                tap(response => {
                    UploadNoticeService._saveFile(response, '.pdf', 'fileName');
                }),
                map(() => {
                    return {Messages: message, Success: true};
                })
            ); */
    }
    
    getFileUrl(parentElement: NPElement, dicoCaracExtId: string) {
        if (parentElement && dicoCaracExtId && dicoCaracExtId !== '') {
            const mediaLink = parentElement.getValueLien(dicoCaracExtId);
            if (mediaLink && mediaLink.RebuildLinkedElements
                && mediaLink.RebuildLinkedElements.length > 0
                && mediaLink.RebuildLinkedElements[0].Element) {
                const mediaUlr = mediaLink.RebuildLinkedElements[0].Element.getValueTextValue(DicoCarac.MEDIA_FILE);
                return mediaUlr && mediaUlr !== '' ? this._urlFileHandlerMedia + mediaUlr : null;
            }
        }
        return null;
    }

    downloadFileFromLocal(elementExtId: string, dicoCaracExtId: string) {
        if (elementExtId && elementExtId !== '' && dicoCaracExtId && dicoCaracExtId !== '') {
            const values = this._mediaService.getMediaByDicoCaracExtId(elementExtId, dicoCaracExtId);
            if (values && values.length > 0 && values[0].File) {
                UploadNoticeService._saveFile(values[0].File, values[0].File.name);
                return of({Messages: message, Success: true});
            } else {
                return throwError({Errors: [this._noFileAvailableMessage], Success: false});
            }
        } else {
            return throwError({Errors: ['Caracatéristique ou Produit incorrect'], Success: false});
        }
    }

    private _isOperationNotice(notice: NPElement) {
        const tmpValueLink = notice.getValueLien(CogedimDicocarcs.DC_WEB_OPERATION_DE_NOTICE);
        if (this._currentOperation
            && tmpValueLink
            && tmpValueLink.RebuildLinkedElements
            && tmpValueLink.RebuildLinkedElements.length > 0) {
            return tmpValueLink.RebuildLinkedElements.some(e => e.Element.ExtID === this._currentOperation.ExtID);
        }
        return false;
    }

    getCurrentOperation() {
        return this._currentOperation;
    }

    initCurrentOperation() {
        return this._operationService.getCurrentOperation()
            .pipe(
                tap(_currentOperation => {
                    this._currentOperation = _currentOperation;
                })
            );
    }
}

