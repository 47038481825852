import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WSParameters, WsParamsService} from './ws-params.service';
import {first, flatMap, map, shareReplay} from 'rxjs/operators';
import {NpApiResult} from '../model/np-api-result';
import {NpStatusAcces} from '../model/np-status-acces';


@Injectable({
  providedIn: 'root',
})
export class WorkflowRepository {

  private _urlGetStatusAccess = '/api/sdk/workflow/GetAllWithUserRights/';

  private _statusAcces$;

  // TODO  IMPLEMENTS
  constructor(private _http: HttpClient, private _wsParamsService: WsParamsService) {
    this._initialize();
  }


  private _initialize() {
    // initialisation des dicoCarac => la requete ne sera appelé que lorsqu'on s'en sert
    // on injecte les paramètres (langue) dans les paramètres url
    this._statusAcces$ =
      this._wsParamsService.getParams().pipe(
        flatMap((params) => {
          return this._http.get<NpApiResult>(this.getURL(params));
        }),
        map((data: NpApiResult) => {
          // TODO: throw error
          if (data.Results != null && data.Results.hasOwnProperty('Workflows')) {
            return this._rebuildStatusAccess(data.Results['Workflows']);
          }
          return null;
        }),
        first(),
        shareReplay(1)
      );
  }

  /**
   * Reconstruit une Map à partir d'une liste de résultat
   */
  private _rebuildStatusAccess(requestResult: any): Map<string, NpStatusAcces> {
    const result = new Map<string, NpStatusAcces>();
    const requestResultCasted = requestResult as NpStatusAcces[];
    requestResultCasted.map((val) => {
      result.set(val.Name, val);
    });
    return result;
  }

  private getURL(params: WSParameters) {
    return this._urlGetStatusAccess;
  }

  public getStatusAccess() {
    return this._statusAcces$;
  }

  /**
   * Retourne le statut par son ExtID
   */
  public getStatusAccessByExtID(statusExtID) {
    return this.getStatusAccess().pipe(
      map((allStatusMap: Map<string, NpStatusAcces>) => {
        if (allStatusMap.has(statusExtID)) {
          return allStatusMap.get(statusExtID);
        } else {
          return null;
        }
      })
    );
  }


}
