import {Injectable} from '@angular/core';
import {CaracConfig, ElementRepository, NPElement} from '@nextpage/np-sdk-data';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {first, flatMap, map, shareReplay, tap} from 'rxjs/operators';
import {CaracConfigRepositoryService} from './carac-config-repository.service';
import {CogedimConstantes} from '../constantes/cogedim-constantes';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {KitService} from './kit.service';
import {BuildingElementWrapper, KitMap} from '../Model/models';
import {OperationService} from './operation.service';

@Injectable()
export class ElementRepositoryService {

    private static _element$: Observable<NPElement>;
    private _currentElement: NPElement;

    constructor(private _elementRepository: ElementRepository,
                private _route: ActivatedRoute,
                private _caracConfigRepository: CaracConfigRepositoryService,
                private _kitService: KitService,
                private _operationService: OperationService) {
        this._initialize();
    }

    public getElement() {
        return ElementRepositoryService._element$;
    }

    private _initialize() {
        if (ElementRepositoryService._element$ == null) {
            ElementRepositoryService._element$ = this._loadParamAndData();
        }
    }

    private _loadParamAndData() {
        let _extIDElement = '';
        return this._route.queryParams.pipe(
            tap(params => {
                const tmpExtID = params['ElementExtID'];
                _extIDElement = tmpExtID && tmpExtID !== '' ? tmpExtID : '';
            }),
            flatMap(param => {
                return this._caracConfigRepository.getConfig('LoadData')
                    .pipe(
                        flatMap(configToParse => {
                            const config = <CaracConfig[]>configToParse;
                            return this._elementRepository.getElements([_extIDElement], config[0].LinksToLoad);
                        }),
                        map((data: Map<string, NPElement>) => {
                            if (data.has(_extIDElement)) {
                                return data.get(_extIDElement);
                            } else {
                                return null;
                            }
                        }),
                        first(),
                        shareReplay(1)
                    );
            }));
    }

    public getElements(ElementExtIDs: string[] = []) {
        return this._caracConfigRepository.getConfig('LoadData')
            .pipe(
                flatMap(configToParse => {
                    const config = <CaracConfig[]>configToParse;
                    return this._elementRepository.getElements(ElementExtIDs, config[0].LinksToLoad);
                }),
                first(),
                shareReplay(1)
            );
    }

    public getElementByExtID(ElementExtID: string): Observable<NPElement> {
        return this.getElements([ElementExtID])
            .pipe(
                map(data => data ? data.get(ElementExtID) : null)
            );
    }

    public compareElements(firstElement: NPElement, secondElement: NPElement) {
        return firstElement.Ordre < secondElement.Ordre ? -1 :
            firstElement.Ordre > secondElement.Ordre ? 1 : 0;
    }

    public getDescendants(ElementIDs: string, linksPath: string[][], onlyOneLevel = false): Observable<NPElement[]> {
        return this._elementRepository.getDescendants([ElementIDs], linksPath, onlyOneLevel).pipe(
            map(data => {
                if (data.has(ElementIDs)) {
                    return data.get(ElementIDs).Children;
                }
                return [];
            }),
            shareReplay(1)
        );
    }

    public getDescendantsOfKits(ElementIDs: string, linksPath: string[][], onlyOneLevel = false): Observable<NPElement[]> {
        return this.getDescendants(ElementIDs, linksPath, onlyOneLevel).pipe(
            map(data => {
                if (data) {
                    data.sort(this.compareElements);
                    data.forEach(element => {
                        if (element && element.Children) {
                            element.Children.sort(this.compareElements);
                        }
                    });
                    return data;
                }
                return [];
            }),
            shareReplay(1)
        );
    }

    public getDescendantsKits(familyExtID: string, linksPath: string[][], onlyOneLevel = false, directionRegional?: number, collection?: number) {
        return this._elementRepository.getDescendants([familyExtID], linksPath, onlyOneLevel).pipe(
            map(data => {
                if (data.has(familyExtID)) {
                    this._currentElement = data.get(familyExtID);
                    // Seules les familles présentes dans NextPage qui possèdent la caractéristique « DC_WEBVISIBILITY_Family » égale à « Oui » s’afficheront
                    this._currentElement.Children = this._currentElement
                        .Children.filter(subfamily => this.listContains(subfamily, CogedimDicocarcs.DC_WEBVISIBILITY_Family, 1));
                    // On enregistre les kits les réutiliser. Cela évitera de faire plusieurs requêtes
                    this._currentElement.Children.forEach(_element => {
                        this._kitService.addToKitMap(familyExtID, _element);
                    });
                    const tmpKitMap = this._kitService.getKitMap2(familyExtID);
                    return this.getFilteredKits2(tmpKitMap, directionRegional, collection);
                }
                return null;
            }),
            shareReplay(1)
        );
    }

    public getFilteredKits2(kitMaps: KitMap[], directionRegional?: number, collection?: number) {
        kitMaps.forEach(kitMap => {
            kitMap.kits = kitMap.kits.filter(_currentKit => {
                return this.listContains(_currentKit, CogedimConstantes.DC_COLLECTION, collection)
                    && this.listContains(_currentKit, CogedimConstantes.DC_ACTIF_INACTIF, 1)
                    && this._operationService.hasRegionalDirProducts(_currentKit);
            });
        });
        return kitMaps;
    }

    public getFilteredKits3(elements: NPElement[], directionRegional: number, collection: number) {
        return elements.filter(_currentKit => {
            return this.listContains(_currentKit, CogedimConstantes.DC_COLLECTION, collection)
                && this.listContains(_currentKit, CogedimConstantes.DC_ACTIF_INACTIF, 1)
                && this._operationService.hasRegionalDirProducts(_currentKit);
        });
    }

    public getFilteredKits(elements: NPElement[], directionRegional?: number, collection?: number) {
        const tmpMap: Map<string, NPElement[]> = new Map<string, NPElement[]>();
        elements.forEach(elementN1 => {
            if (!tmpMap.has(elementN1.ExtID)) {
                let tmpValues = Object.assign([], elementN1.Children);
                tmpValues = tmpValues.filter(
                    kit => {
                        return this.listContains(kit, CogedimConstantes.DC_COLLECTION, collection)
                            && this.listContains(kit, CogedimConstantes.DC_ACTIF_INACTIF, 1)
                            && this.listContains(kit, CogedimConstantes.DC_DIRECTION_REGIONALE_PRODUITS, directionRegional);
                    });
                tmpMap.set(elementN1.ExtID, tmpValues);
            }
        });
        return tmpMap;
    }

    listContains(element: NPElement, dicoCaracExtID: string, valueID: number) {
        const links = element.getValueListe(dicoCaracExtID);
        return links && links.Values && links.Values.some(link => link.ValueID === valueID);
    }

    // supprime les modules de construction dont les kits n'ont pas été sélectionnés dans la collection de l'opération en cours
    getBuildingModuleElements(collectionOfOperation: NPElement, kits: NPElement[]) {
        const newBuildingModuleElements: BuildingElementWrapper[] = [];
        if (collectionOfOperation && collectionOfOperation.getValueLien(CogedimDicocarcs.LPP_MODULE_CONSTRUCTION) && kits) {
            const linkValues = collectionOfOperation.getValueLien(CogedimDicocarcs.LPP_MODULE_CONSTRUCTION);
            if (linkValues && linkValues.RebuildLinkedElements) {
                linkValues.RebuildLinkedElements.forEach(currentRebuildBuildingModule => {
                    // On cherche le kit lié au module de construction en cours
                    const rebuildLinkedElements = currentRebuildBuildingModule.Element.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_MODULE_CONSTRUCTION);
                    if (rebuildLinkedElements && rebuildLinkedElements.RebuildLinkedElements && rebuildLinkedElements.RebuildLinkedElements.length > 0) {
                        const linkedKit = kits.find(k => rebuildLinkedElements.RebuildLinkedElements.some(x => x.Element.ExtID === k.ExtID));
                        if (linkedKit) {
                            // newBuildingModuleElements.push(new BuildingElementWrapper(linkedKit, currentRebuildBuildingModule.Element));
                        }
                    }
                });
            }
        }
        return newBuildingModuleElements;
    }

}
