export class FilterCriteria {
  public Field: string;
  public Operator: NpwFilterOperator = NpwFilterOperator.Equal;
  public Value: string;
  constructor(param) {
    if (param != null) {
      if (param.hasOwnProperty('Field')) { this.Field = param['Field']; }
      if (param.hasOwnProperty('Operator')) { this.Operator = param['Operator']; }
      if (param.hasOwnProperty('Value')) { this.Value = param['Value']; }
    }
  }
}

export class NpwFilter {
    public FieldName: string;
    public NpFilterValues: NpwFilterValues[];
}

export class NpwFilterValues {
    public operator: NpwFilterOperator;
    public value: string;
}

export enum NpwFilterOperator {
    Equal = '=',
    Superior = '>',
    Inferior = '<',
    StartWith = 'start'
}
